import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, Subject, switchMap} from "rxjs";
import {environment} from "../../../environments/environment";
import {ConsentService} from "./consent.service";
import {debounceTime, delay, distinctUntilChanged} from "rxjs/operators";
import {EVENT_ACTION, EVENT_CATEGORY} from "../../../types/gtm-options";
import {AnalyticsService} from "./analytics.service";

@Injectable({
  providedIn: 'root',
})
export class FireplaceAdService {


  public get firePlaceAdShown(): BehaviorSubject<boolean | null> { return this._firePlaceAdShown }
  private _firePlaceAdShown: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean|null>(null);

  private displayConditionChanged: Subject<void> = new Subject<void>();

  private spaceForDisplay = window.innerWidth >= 1440;
  private consentGiven = false;
  private shouldBeShownOnPage = true;

  public hideFirePlaceAd(): void {
    this.shouldBeShownOnPage = false;
    this.displayConditionChanged.next();
  }
  public showFirePlaceAd(): void {
    this.shouldBeShownOnPage = true;
    this.displayConditionChanged.next();
  }
  public constructor(
    private consentService: ConsentService,
    private analyticsService: AnalyticsService,
  ) {

    this.firePlaceAdShown.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
    ).subscribe({
      next: shown => {
        if (shown) {
          this.analyticsService.trackEvent(
            EVENT_CATEGORY.IMPRESSION,
            EVENT_ACTION.GOTHAER_FIREPLACE_DISPLAY_IMPRESSION,
            'Gothaer Fireplace Display on Home',
            1,
            false,
          );
        }
      }
    })

    this.displayConditionChanged.subscribe({
      next: () => {

        this._firePlaceAdShown.next(
          environment.gothaer_fireplace_ad_enabled &&
          this.shouldBeShownOnPage &&
          this.spaceForDisplay &&
          this.consentGiven
        )
      }
    })

    fromEvent(window, 'resize').pipe(
      debounceTime(75),
    ).subscribe({
      next: () => {
        this.spaceForDisplay = window.innerWidth >= 1440;
        this.displayConditionChanged.next();
      }
    })

    this.consentService.consentsChanged$.pipe(
      delay(1),
      switchMap(() => this.consentService.consentGiven(environment.consentIds.matomo))
    ).subscribe({
      next: consentGiven => {
        this.consentGiven = consentGiven;
        this.displayConditionChanged.next();
      }
    })


    this.displayConditionChanged.next();


  }
}
