<div class="vh-100 d-flex flex-column">

  <label class="visually-hidden" id="sidebarLabel">{{ 'sidebar.aria_label' | translate}}</label>
  <div class="offcanvas-header py-2 ps-1">
    <app-logo class="d-flex ms-1" [isHandballNet]="isHandballNet"></app-logo>

    <button type="button"
            class="btn btn-close"
            (click)="offcanvas.dismiss()"
            role="button"
            [attr.aria-description]="'sidebar.aria_close_btn' | translate">

    </button>
  </div>
  <div class="offcanvas-header p-0 ">
    <nav
      class="navbar w-100 border-bottom border-top border-1 border-super-dark navbar-expand py-0 bg-dark navbar-dark">
      <button class="d-block  btn btn-link btn-dark  px-4 border-1 rounded-0  py-3" (click)="offcanvas.dismiss()">
        <i class="fa fs-4 fa-bars"></i>
      </button>
      <app-autocomplete-input [large]="false" class="w-100 mx-2"></app-autocomplete-input>
    </nav>
  </div>


  <div class="offcanvas-body p-2 pb-5">

    <ul class="nav flex-column">
      <li *ngIf="shownMenu !== MENU.TOP_PROFILES && !isHandballNet">
        <button class="nav-link w-100 d-flex justify-content-between align-items-center" (click)="openTopProfiles()">
          {{'sidebar.top_events_leagues' | translate}}

          <i class=" fa fa-chevron-right"></i>
        </button>
      </li>
      <li *ngIf="shownMenu === MENU.TOP_PROFILES">
        <button class="nav-link mb-2" (click)="openTop()">
          <i class=" fa fa-chevron-left me-2"></i> {{'sidebar.top_events_leagues' | translate}}
        </button>
      </li>
      <li
        *ngIf="shownMenu === MENU.TOP_PROFILES && (topProfilesService.loading$|async) && ((topProfilesService.total$|async) ?? 0)  == 0">
        <button class="nav-link mb-2 text-center w-100">
          <i class="fa fa-circle-o-notch fa-spin fa-2x text-muted"></i>
        </button>
      </li>

      <ng-container *ngIf="shownMenu === MENU.TOP_PROFILES">
        <app-content-grid
          [itemTemplate]="menuItemProfile"
          [items]="topProfilesService.items$"
          [total]="topProfilesService.total$"
          (load)="topProfilesService.load($event)"
          [loading]="topProfilesService.loading$"
          [showLoadMore]="topProfilesService.hasMore$"
          [showLoadMoreAsButton]="false"
          [infinityLoading]="false"
          [gridGap]="0"
          [paddingBottom]="false"
          [rowItemsXXl]="1"
          [rowItemsXl]="1"
          [rowItemsLg]="1"
          [rowItemsMd]="1"
          [rowItemsSm]="1"
          [rowItemsXs]="1"
          [desiredRows]="6"
          [desiredRowsXs]="6"
          [loadMoreIncrementsBy]="6"
        >
        </app-content-grid>
      </ng-container>


      <li *ngIf="shownMenu !== MENU.PROFILE && shownMenu !== MENU.SPORT_TYPE_TAG && shownMenu !== MENU.ALL_SPORT_TYPES">
        <button class="nav-link w-100 d-flex justify-content-between align-items-center" (click)="openAllSportTypes()">
          {{'sidebar.sport_types' | translate}}
          <i class=" fa fa-chevron-right"></i>
        </button>
      </li>


      <li *ngIf="shownMenu === MENU.PROFILE">
        <button class="nav-link mb-2" (click)="openParentProfileMenu(profile)">
          <i class=" fa fa-chevron-left me-2"></i> {{profile?.name}}
        </button>
      </li>
      <li
        *ngIf="shownMenu === MENU.PROFILE && (profilesForProfilesService.loading$|async) && ((profilesForProfilesService.total$|async) ?? 0)  == 0">
        <button class="nav-link mb-2 text-center w-100">
          <i class="fa fa-circle-o-notch fa-spin fa-2x text-muted"></i>
        </button>
      </li>
      <ng-container *ngIf="shownMenu === MENU.PROFILE">
        <app-content-grid
          [itemTemplate]="menuItemProfile"
          [items]="profilesForProfilesService.items$"
          [total]="profilesForProfilesService.total$"
          (load)="profilesForProfilesService.load($event)"
          [loading]="profilesForProfilesService.loading$"
          [showLoadMore]="profilesForProfilesService.hasMore$"
          [showLoadMoreAsButton]="false"
          [infinityLoading]="true"
          [gridGap]="0"
          [paddingBottom]="false"
          [rowItemsXXl]="1"
          [rowItemsXl]="1"
          [rowItemsLg]="1"
          [rowItemsMd]="1"
          [rowItemsSm]="1"
          [rowItemsXs]="1"
          [desiredRows]="20"
          [desiredRowsXs]="20"
          [loadMoreIncrementsBy]="20"
        >
        </app-content-grid>
      </ng-container>


      <li *ngIf="shownMenu === MENU.SPORT_TYPE_TAG">
        <button class="nav-link mb-2" (click)="openAllSportTypes()">
          <i class=" fa fa-chevron-left me-2"></i>{{ sportTypeTag?.name}}
        </button>
      </li>
      <li
        *ngIf="shownMenu === MENU.SPORT_TYPE_TAG && (profilesForSportTypeTagService.loading$|async) && ((profilesForSportTypeTagService.total$|async) ?? 0)  == 0">
        <button class="nav-link mb-2 text-center w-100">
          <i class="fa fa-circle-o-notch fa-spin fa-2x text-muted"></i>
        </button>
      </li>
      <ng-container *ngIf="shownMenu === MENU.SPORT_TYPE_TAG">
        <app-content-grid
          [itemTemplate]="menuItemProfile"
          [items]="profilesForSportTypeTagService.items$"
          [total]="profilesForSportTypeTagService.total$"
          (load)="profilesForSportTypeTagService.load($event)"
          [loading]="profilesForSportTypeTagService.loading$"
          [showLoadMore]="profilesForSportTypeTagService.hasMore$"
          [showLoadMoreAsButton]="false"
          [infinityLoading]="true"
          [gridGap]="0"
          [paddingBottom]="false"
          [rowItemsXXl]="1"
          [rowItemsXl]="1"
          [rowItemsLg]="1"
          [rowItemsMd]="1"
          [rowItemsSm]="1"
          [rowItemsXs]="1"
          [desiredRows]="20"
          [desiredRowsXs]="20"
          [loadMoreIncrementsBy]="20"
        >
        </app-content-grid>
      </ng-container>


      <li *ngIf="shownMenu === MENU.ALL_SPORT_TYPES">
        <button class="nav-link mb-2" (click)="openTop()">
          <i class=" fa fa-chevron-left me-2"></i>{{'sidebar.sport_types' | translate}}
        </button>
      </li>
      <li
        *ngIf="shownMenu === MENU.ALL_SPORT_TYPES && (allSportTypesService.loading$|async) && ((allSportTypesService.total$|async) ?? 0)  == 0">
        <button class="nav-link mb-2 text-center w-100">
          <i class="fa fa-circle-o-notch fa-spin fa-2x text-muted"></i>
        </button>
      </li>
      <ng-container *ngIf="shownMenu === MENU.ALL_SPORT_TYPES">
        <app-content-grid
          [itemTemplate]="sportTypes"
          [items]="allSportTypesService.items$"
          [total]="allSportTypesService.total$"
          (load)="allSportTypesService.load($event)"
          [loading]="allSportTypesService.loading$"
          [showLoadMore]="allSportTypesService.hasMore$"
          [showLoadMoreAsButton]="false"
          [infinityLoading]="true"
          [gridGap]="0"
          [paddingBottom]="false"
          [rowItemsXXl]="1"
          [rowItemsXl]="1"
          [rowItemsLg]="1"
          [rowItemsMd]="1"
          [rowItemsSm]="1"
          [rowItemsXs]="1"
          [desiredRows]="20"
          [desiredRowsXs]="20"
          [loadMoreIncrementsBy]="20"
        >
        </app-content-grid>
      </ng-container>

      <ng-container *ngIf="isHandballNet">
        <!--Handball.net Menu-->
        <hr>
        <h3>Links zu Handball.net</h3>
        <ul class="nav flex-column mb-3">
          <li class="nav-item my-1">
            <a target="_blank" rel="nofollow noopener noreferrer"
               href="https://www.handball.net/?utm_source=sportdeutschland.tv&utm_medium=link-seitenleiste&utm_campaign=startseite"
               class="btn btn-handballnet text-white w-100 text-start">
              handball.net
            </a>
          </li>
          <li class="nav-item my-1">
            <a target="_blank" rel="nofollow  noopener noreferrer"
               href="https://www.handball.net/ligen?utm_source=sportdeutschland.tv&utm_medium=link-seitenleiste&utm_campaign=ligen"
               class="btn btn-handballnet text-white w-100 text-start">
              Ligen</a>
          </li>
          <li class="nav-item my-1">
            <a target="_blank" rel="nofollow noopener noreferrer"
               href="https://www.handball.net/wettbewerbe?utm_source=sportdeutschland.tv&utm_medium=link-seitenleiste&utm_campaign=wettbewerbe"
               class="btn btn-handballnet text-white w-100 text-start">
              Wettbewerbe</a>
          </li>
          <li class="nav-item my-1">
            <a target="_blank" rel="nofollow noopener noreferrer"
               href="https://www.handball.net/vereine?utm_source=sportdeutschland.tv&utm_medium=link-seitenleiste&utm_campaign=vereine"
               class="btn btn-handballnet text-white w-100 text-start">
              Vereine</a>
          </li>
          <li class="nav-item my-1">
            <a target="_blank" rel="nofollow noopener noreferrer"
               href="https://www.handball.net/verbaende?utm_source=sportdeutschland.tv&utm_medium=link-seitenleiste&utm_campaign=verbaende"
               class="btn btn-handballnet text-white w-100 text-start">
              Verbände</a>
          </li>
        </ul>

        <hr>


        <div class="d-block" style="height: 100px">
          <app-logo [showGothaer]="false"></app-logo>
        </div>

      </ng-container>


      <li>
        <a class="nav-link " routerLinkActive="text-primary fw-bold"
           routerLink="/livestreams"
        >
          {{'header.live_long' | translate}}
          <sup><span class="badge bg-danger" *ngIf="numberOfCurrentLivestreams && numberOfCurrentLivestreams >  0">
             {{(numberOfCurrentLivestreams > 1000 ? '1000+' : numberOfCurrentLivestreams |number)}}
            </span></sup>
        </a>
      </li>

      <li>
        <a class="nav-link text-nowrap" routerLinkActive="text-primary fw-bold"
           routerLink="/newsfeed"
        >
          {{'header.my_content' | translate}}
        </a>
      </li>

      <li *ngIf="loggedIn && currentUserProfile && currentUserProfile?.type != PROFILE_TYPE.VIEWER">
        <a class="nav-link text-nowrap" routerLinkActive="text-primary fw-bold"

           routerLink="/{{currentUserProfile.slug}}">
          {{'sidebar.my_profile' | translate}}
        </a>
      </li>
    </ul>


    <hr>

    <ng-container>
      <div *ngFor="let promoCard of ('sidebar.promo_cards' | translate)" class="card border-0 my-2 bg-gray-dark">
        <div class="card-body ">
          <h3 class="mb-1"><sup><span class="badge bg-primary text-black">{{ promoCard.label }}</span></sup> {{ promoCard.title }} </h3>
          <p class="mb-0 text-muted small" [innerHTML]="promoCard.description"></p>
        </div>
      </div>
    </ng-container>

<hr>
<ul class="nav flex-column">
  <li>
    <a
      rel="nofollow"
      routerLink="/datenschutz"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap"
    >
      {{'footer.datenschutz' |translate}}
    </a>
  </li>
  <li>
    <a
      rel="nofollow"
      (click)="showCMP()"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap pointer"
    >
      {{'footer.cookie_settings' |translate}}
    </a>
  </li>

  <li>
    <a
      rel="nofollow"
      routerLink="/cookiehinweise"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap pointer"
    >
      {{'footer.cookie_notice' |translate}}
    </a>
  </li>
  <li>
    <a
      rel="nofollow"
      routerLink="/agb"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap"
    >
      {{'footer.agb' |translate}}
    </a>
  </li>
  <li>
    <a
      rel="nofollow"
      routerLink="/faq"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap"
    >
      {{'footer.faq' |translate}}
    </a>
  </li>
  <li>
    <a
      rel="nofollow"
      routerLink="/impressum"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap"
    >
      {{'footer.imprint' |translate}}
    </a>
  </li>
  <li>
    <a
      rel="nofollow"
      routerLink="/vertrag-kuendigen"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap"
    >
      {{'footer.cancel_contract' |translate}}
    </a>
  </li>
  <li class="mb-5">
    <a
      rel="nofollow"
      href="https://dosbnewmedia.de/karriere"
      target="_blank"
      class="ps-3 sdtv-hover-text-primary-underline small text-muted text-decoration-none text-nowrap"
    >
      {{'footer.jobs' |translate}}
    </a>
  </li>

</ul>


</div>


</div>


<ng-template #sportTypes let-item let-index="index">
  <li
    class="d-flex px-2 py-1 sdtv-hover-bg-gray-800 rounded border-bottom border-1 ms-4 me-2 border-dark-subtle justify-content-between">
    <a routerLink="/videos/{{item.slug}}" class="sdtv-hover-text-primary text-decoration-none w-100">{{ item.name}}</a>
    <a role="navigation" class="text-decoration-none pointer px-2" (click)="openSportTypeTag(item)"><i
      class=" fa fa-chevron-right"></i></a>
  </li>
</ng-template>

<ng-template #menuItemProfile let-item let-index="index">
  <li
    class="d-flex ps-3 pe-2  py-1 sdtv-hover-bg-gray-800 rounded border-bottom border-1 me-2 border-dark-subtle justify-content-between">
      <span class="d-flex">
          <a class="ms-2" [routerLink]="['/' + item.slug]">
            <img
              loading="lazy"
              class="sdtv-profile-image sdtv-profile-image-big"
              imageError
              [alt]="item.name"
              [src]="item |profileImageForId: {size: PROFILE_IMAGE_SIZE.XL_300_300}"
            />
          </a>
          <div class="ms-3" style="min-width: 0">
            <div class="d-flex ">

              <a class="sdtv-hover-text-primary text-decoration-none" [routerLink]="'/' + item.slug"
                 routerLinkActive="text-primary fw-bold"
              >
                {{ item.name }}
              </a>
              <sup *ngIf="currentlyLiveService.profileIsLive(item)| async"><span class="badge bg-danger ">LIVE</span></sup>
            </div>


            <a
              *ngIf="item.sport_type"

              class="badge bg-black fw-normal sdtv-hover-text-primary-underline text-decoration-none text-truncate mt-1"
              [routerLink]="'/videos/' + item.sport_type.slug"

            >
              {{ item.sport_type.name }}
            </a>


          </div>
        </span>

    <a role="navigation" class="text-decoration-none pointer align-self-center px-2"

       *ngIf="
         item.type === PROFILE_TYPE.ASSOCIATION && item.association_has_members
||       item.type === PROFILE_TYPE.CLUB && item.club_has_members
||       item.type === PROFILE_TYPE.LEAGUE && item.league_has_members
"
       (click)="showMenuForProfileId(item.id)"><i class=" fa fa-chevron-right"></i></a>
  </li>
</ng-template>
