<ul class="list-group">
  <li
    *ngFor="let device of devices; let i = index;"
    class="list-group-item"
    [class.bg-black]="device.current_session"
  >
    <div class="row">
      <div class="col-12 col-md-8">
        <i [class]="device.device_icon"></i> {{ device.device_name }} <strong *ngIf="device.current_session"
                                                                              class="text">({{ 'too_many_devices.device.this_browser' | translate }}
        )</strong>
        <br/>


        <small class="text-muted">
          <ng-container *ngIf=" device.city || device.country">
            {{ device.city }},  {{ device.country }}
            <br/>
          </ng-container>

          {{ 'too_many_devices.device.created' | translate }}
          {{
            device.created_timestamp  | dfnsFormatDistanceToNowPure: {
              addSuffix: true,
              includeSeconds: true,
              locale: (localeService.dfnsLocale|async) ?? undefined
            }
          }}
          <br/>
          <ng-container *ngIf="device.last_used_timestamp">

            {{ 'too_many_devices.device.last_used' | translate }} {{
              device.last_used_timestamp  | dfnsFormatDistanceToNowPure: {
                addSuffix: true,
                includeSeconds: true,
                locale: (localeService.dfnsLocale|async) ?? undefined
              }
            }}
          </ng-container>
        </small>

      </div>
      <div class="col-12 col-md-4 align-content-center my-2">

        <button class="btn btn-danger btn-sm"
                *ngIf="!(reason === TOO_MANY_DEVICES_REASON.CODE_BASED_LOGIN && device.current_session)"
                (click)="deleteDevice.emit(device)">
          {{ 'too_many_devices.device.delete_button' | translate }}
        </button>
      </div>
    </div>
  </li>

</ul>
