import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'deviceCategory',
})
export class DeviceCategoryPipe implements PipeTransform {
  public transform(value: string): string {
    switch (value) {
      case 'Smartphone':
        return 'Mobil';
      case 'Desktop':
        return 'Desktop';
      case 'Phablet':
        return 'Phablet';
      case 'Tablet':
        return 'Tablet';
      case 'Tv':
        return 'Smart-TV';
      case 'Unknown':
        return 'Unbekannt';
      case 'Console':
        return 'Konsole';
      case 'Portable media player':
        return 'Portabler Media-Player';
      case 'Car browser':
        return 'Auto';
      case 'Feature phone':
        return 'Mobil';
      case 'Camera':
        return 'Kamera';
      case 'Peripheral':
        return 'Peripherie-Geräte';
      case 'Smart display':
        return 'Smart Display';
      case 'Smart speaker':
        return 'Smart Speaker';
      case 'Wearable':
        return 'Wearable';
    }
    return value;
  }
}
