import {Pipe, PipeTransform} from '@angular/core';
import {ProfileReducedResource} from '../../entities/Profile.entity';
import {ImageCdnService} from '../../shared/services/image-cdn.service';
import {PROFILE_IMAGE_SIZE} from "../../../enums/PROFILE_IMAGE_SIZE";

@Pipe({
  name: 'profileImageForId',
})
export class ProfileImageForIdPipe implements PipeTransform {
  public transform(
    value: ProfileReducedResource | undefined,
    args: { size: PROFILE_IMAGE_SIZE; imageError?: string | undefined } = {
      size: PROFILE_IMAGE_SIZE.XL_300_300,
      imageError: '/assets/placeholders/profil.svg',
    },
  ): string {
    if (!value || !value.image_id) {
      return args.imageError ?? '/assets/placeholders/profil.svg';
    }
    return this.imageCdnService.getImageFqdn(value.image_id, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {
  }
}
