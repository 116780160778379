import {Injectable} from '@angular/core';

import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject} from 'rxjs';
import {Tag} from '../../../../entities/Tag.entity';
import {ProfileReducedResource} from '../../../../entities/Profile.entity';

@Injectable()
export class InputSearchService {
  public tagSearchResult$: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  public profileSearchResult$: BehaviorSubject<ProfileReducedResource[]> = new BehaviorSubject<ProfileReducedResource[]>([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public searchTags(query: string): void {
    this.log.warn('not implemented', query);
    this.loading$.next(true);
  }

  public searchProfiles(query: string): void {
    this.log.warn('not implemented', query);
    this.loading$.next(true);
  }

  public constructor(protected log: NGXLogger) {}
}
