import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getAllHeimspielResultProfileIdsPipe',
})
export class GetAllHeimspielResultProfileIdsPipe implements PipeTransform {
  public transform(value: {iframeLink: string, profileIds: string[]}[]): string[] {
    const result: string[] = [];
    value.forEach(item => result.push(...item.profileIds));
    return result;
  }
}
