export enum PLAYER_STATE {
  LOADING = 'LOADING',
  COUNTDOWN = 'COUNTDOWN',
  SUCCESS = 'SUCCESS',
  UNAUTHORIZED = 'UNAUTHORIZED',

  INVALID_PARAMS = 'INVALID_PARAMS',
  ASSET_NOT_AVAILABLE = 'ASSET_NOT_AVAILABLE',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  LIVESTREAM_NOT_AVAILABLE = 'LIVESTREAM_NOT_AVAILABLE',
  WITHOUT_SUCCESSFUL_PURCHASE = 'WITHOUT_SUCCESSFUL_PURCHASE',
  CONTENT_CURRENTLY_NOT_PURCHASED = 'CONTENT_CURRENTLY_NOT_PURCHASED',
  CONTENT_NOT_ALLOWED_FOR_REGION = 'CONTENT_NOT_ALLOWED_FOR_REGION',
  TOO_MANY_CONCURRENT_ACCESS_SESSIONS = 'TOO_MANY_CONCURRENT_ACCESS_SESSIONS',
  TOKEN_INVALID = 'TOKEN_INVALID',
  AD_BLOCKER_ENABLED = 'AD_BLOCKER_ENABLED',

  MISSING_VIDEO_SOURCE = 'MISSING_VIDEO_SOURCE',
  MEDIA_ERROR = 'MEDIA_ERROR',

  /** PlayerErrorEnum defines type of playerError */
  PLAYER_ERROR = 'PLAYER_ERROR',
  API_ERROR = 'API_ERROR',
}
