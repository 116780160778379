import {Pipe, PipeTransform} from '@angular/core';
import {AssetFromSearchIndexResource, AssetReducedResource} from '../../entities/asset.entity';
import {ImageCdnService} from '../../shared/services/image-cdn.service';
import {ASSET_IMAGE_SIZE} from "../../../enums/ASSET_IMAGE_SIZE";


@Pipe({
  name: 'assetImage',
})
export class AssetImageSizePipe implements PipeTransform {
  public transform(
    value: AssetReducedResource | AssetFromSearchIndexResource | undefined,
    args: { size: ASSET_IMAGE_SIZE; imageError?: string } = {
      size: ASSET_IMAGE_SIZE.L_960_540,
      imageError: '/assets/placeholders/video_placeholder.svg',
    },
  ): string {

    if (!value) {
      return args.imageError || '/assets/placeholders/video_placeholder.svg';
    }

    let imageId;

    if ("image_url" in value) {
      imageId = value?.image_url;
    }
    if ("image_id" in value && !imageId) {
      imageId = value?.image_id;
    }

    if (!imageId || imageId.startsWith('http')) {
      return args.imageError || '/assets/placeholders/video_placeholder.svg';
    }

    return this.imageCdnService.getImageFqdn(imageId, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {}
}
