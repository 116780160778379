import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService} from './authentication.service';
import {Observable, of} from 'rxjs';
import {ProfileReducedResource} from '../../entities/Profile.entity';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {HttpCacheService} from './api/http-cache.service';
import {BaseResource} from '../../entities/BaseResource.entity';
import {Tag, TagReducedResource} from '../../entities/Tag.entity';
import {AssetReducedResource} from '../../entities/asset.entity';

@Injectable()
export class FollowingService {
  public $followingChanged: EventEmitter<{ resource: BaseResource; followed: boolean }> = new EventEmitter<{
    resource: BaseResource;
    followed: boolean;

  }>();

  public $likeChanged: EventEmitter<{ resource: BaseResource; liked: boolean }> = new EventEmitter<{
    resource: BaseResource;
    liked: boolean;
  }>();

  private followedIds?: string[];
  private likedIds?: string[];

  private networkRequest?: Observable<{ followed: string[]; liked: string[] }>;

  public followed(entity: BaseResource): Observable<boolean> {
    return this.getFollowedIds().pipe(
      map(r => {
        return r.includes(entity.id);
      }),
    );
  }


  public liked(entity: BaseResource): Observable<boolean> {
    return this.getLikedIds().pipe(
      map(r => {
        return r.includes(entity.id);
      }),
    );
  }

  public followProfile(profile: ProfileReducedResource): Observable<void> {
    this.$followingChanged.next({ resource: profile, followed: true });
    return this.http.put<void>(`/personal/following/profiles/${profile.id}`, {
      profile: profile.id,
    });
  }

  public unfollowProfile(profile: ProfileReducedResource): Observable<void> {
    this.$followingChanged.next({ resource: profile, followed: false });

    return this.http.delete<void>(`/personal/following/profiles/${profile.id}`).pipe(
      tap(() => {
        this.httpCache.deleteCache();
      }),
    );
  }

  public followTag(tag: TagReducedResource): Observable<Tag> {
    this.$followingChanged.next({ resource: tag, followed: true});
    return this.http.put<Tag>(`/personal/following/tags/${tag.id}`, {
      tag: tag.id,
    });
  }

  public unfollowTag(tag: TagReducedResource): Observable<Tag> {
    this.$followingChanged.next({ resource: tag, followed: false });
    return this.http.delete<Tag>(`/personal/following/tags/${tag.id}`).pipe(
      tap(() => {
        this.httpCache.deleteCache();
      }),
    );
  }



  public like(asset: AssetReducedResource): Observable<{ id: string; liked: boolean }> {
    this.$likeChanged.next({ resource: asset, liked: true });
    return this.http.put<{ id: string; liked: boolean }>('/personal/liked-assets/' + asset.id, {});
  }

  public unlike(asset: AssetReducedResource): Observable<{ id: string; liked: boolean }> {
    this.$likeChanged.next({ resource: asset, liked: false });
    return this.http.delete<{ id: string; liked: boolean }>('/personal/liked-assets/' + asset.id, {});
  }

  public getFollowedIds(): Observable<string[]> {
    if (this.auth.loggedIn$.getValue() === false) {
      return of([]);
    }
    if (this.followedIds) {
      return of(this.followedIds);
    }

    if (!this.networkRequest) {
      this.networkRequest = this.getFromServer();
    }

    return this.networkRequest.pipe(
      map(r => {
        return r.followed;
      }),
    );
  }

  public getLikedIds(): Observable<string[]> {
    if (this.auth.loggedIn$.getValue() === false) {
      return of([]);
    }
    if (this.likedIds) {
      return of(this.likedIds);
    }

    if (!this.networkRequest) {
      this.networkRequest = this.getFromServer();
    }

    return this.networkRequest.pipe(
      map(r => {
        return r.liked;
      }),
    );
  }

  private getFromServer(): Observable<{ followed: string[]; liked: string[] }> {
    if (!this.auth.loggedIn$.getValue()) {
      return of({ followed: [], liked: [] });
    }
    return this.http.get<{ followed: string[];liked: string[] }>('/personal/following-ids').pipe(
      tap(r => {
        this.followedIds = r.followed;
      }),
      catchError(e => {
        this.log.error('[Following] Could not load following data', e.message);
        return of({ followed: [],  liked: [] });
      }),

      shareReplay(),
    );
  }
  public constructor(
    private log: NGXLogger,
    private http: HttpClient,
    private httpCache: HttpCacheService,
    private auth: AuthenticationService,
  ) {
    this.$followingChanged.subscribe(r => {
      if (!this.followedIds) {
        this.followedIds = [];
      }

      if (r.followed) {
        this.followedIds.push(r.resource.id);
      } else {
        this.followedIds = this.followedIds.filter(id => id !== r.resource.id);
      }
    });

    this.auth.loggedIn$.subscribe(loggedIn => {
      if (loggedIn === false) {
        this.followedIds = [];
        return;
      }
    });
  }
}
