import {Injectable} from '@angular/core';
import {ChunkLoaderService} from "../../chunk-loader.service";
import {ProfileForMenuResource} from "../../../../../entities/Profile.entity";


@Injectable({
  providedIn: 'root',
})
export class ProfilesForProfilesService extends ChunkLoaderService<ProfileForMenuResource> {

  // init with either
  // /stateless/frontend/menu/sport-types/{{tag.id}} => Profiles of Sporttype (mostly associations)
  // /stateless/frontend/menu/associations/{{{profile.id}} => Profiles for Association (mostly leagues)
  // api/stateless/frontend/menu/leagues/{{profile.id}} => Profiles for Leagues (mostly clubs)
  // api/stateless/frontend/menu/clubs/{{profile.id}} => Profiles for Clubs (mostly athletes)
  public url = '';

  public override chunkSize = 20;
}
