import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {Title} from '@angular/platform-browser';
import {SeoMetaTagsService} from "../../services/seo-meta-tags.service";
import {of} from "rxjs";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  @ViewChild('template') public template?: ElementRef;
  @ViewChild('staticModal') public staticModal!: BsModalRef;
  @Output() public closed: EventEmitter<void> = new EventEmitter<void>();

  @Input() public size: 'md' | 'lg' | 'xl' = 'md';
  @Input() public closeEvent?: EventEmitter<void>;
  @Input() public backdrop = true;
  @Input() public closeOnBackdropClick = true;
  @Input() public closeOnEsc = true;
  @Input() public showModalFooter = true;
  @Input() public showModalHeader = true;
  @Input() public class?: string;
  @Input() public title!: string;
  @Input() public redirectRoute = '';
  private oldTitle?: string;

  public close(): void {
    this.staticModal.hide();
  }

  public ngOnInit(): void {
    this.oldTitle = this.titleService.getTitle();
    this.seoMetaTagsService.updateMetaTags({ indexFollow: false, pageTitle: of(this.title) });
    this.closeEvent?.subscribe(() => {
      this.log.log('close event received');
      this.staticModal.hide();
    });
  }

  public onHidden(): void {
    if (this.oldTitle) {
      this.seoMetaTagsService.updateMetaTags({ pageTitle: of(this.oldTitle) });
    }

    this.closed.emit();
    if (!this.router.getCurrentNavigation()) {
      this.router.navigate([this.redirectRoute, { outlets: { m: null } }], {
        queryParamsHandling:'preserve'
      });
    }
  }

  public constructor(
    private router: Router,
    private log: NGXLogger,
    private seoMetaTagsService: SeoMetaTagsService,
    private titleService: Title,
  ) {}
}
