export enum WEBSOCKET_EVENT {
  CLOSE = 'close',
  PING = 'ping',
  PONG = 'pong',
  ASSET_UPDATED = 'asset.updated',
  LIVESTREAM_UPDATED = 'livestream.updated',
  ASSET_MIDROLL_CREATED = 'asset.midroll.created',
  PROFILE_NOTIFICATION_CREATED = 'profile-notification.created',
}

export type WebSocketMessage = Ping | Pong | Close | AssetUpdated | AssetMidrollCreated | LiveStreamUpdated | ProfileNotificationCreated;

export interface WebsocketMessageInterface {
  event: WEBSOCKET_EVENT;
  data: unknown;
  timestamp: number;
}

interface Ping extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.PING;
  data: unknown;
  timestamp: number;
}

interface Pong extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.PONG;
  timestamp: number;
  data: unknown;
}

interface Close extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.CLOSE;
  timestamp: number;
}
export interface AssetUpdated extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.ASSET_UPDATED;
  data: {
    id: UUID;
  };
  timestamp: number;
}

export interface AssetMidrollCreated extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.ASSET_MIDROLL_CREATED;
  data: {
    id: UUID;
    asset_id: UUID;
  };
  timestamp: number;
}

export interface LiveStreamUpdated extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.LIVESTREAM_UPDATED;
  data: {
    id: UUID;
  };
  timestamp: number;
}

export interface ProfileNotificationCreated extends WebsocketMessageInterface {
  event: WEBSOCKET_EVENT.PROFILE_NOTIFICATION_CREATED;
  data: {
    unread_notification_count: number;
  };
  timestamp: number;
}
