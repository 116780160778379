import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';

import {LocaleService} from "../../shared/services/locale.service";
import {Subscription} from 'rxjs';
import {CurrencyPipe} from "@angular/common";

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform, OnDestroy {
  private transformedValue = '';
  private localeSubscription: Subscription;
  private priceInCents: number| undefined;



  public ngOnDestroy(): void {
    // Clean up the subscription when the pipe is destroyed
    this.localeSubscription.unsubscribe();
  }

  public transform(priceInCents: number): string {

    if (this.priceInCents === priceInCents && this.transformedValue) {
      return this.transformedValue;
    }
    this.priceInCents = priceInCents;
    if (!this.transformedValue) {
      // Initialize the transformedValue if it's not set yet
      this.updateTransformedValue(this.localeService.currentLocale);
    }
    return this.transformedValue;
  }

  private updateTransformedValue(locale: string): void {
    if (this.priceInCents !== undefined) {
      this.transformedValue = <string>this.currencyPipe.transform(this.priceInCents / 100, 'EUR', 'symbol', '1.2-2', locale);
      this.changeDetectorRef.markForCheck(); // Notify Angular that the value has changed
    }

  }

  public constructor(
    private localeService: LocaleService,
    private currencyPipe: CurrencyPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.localeSubscription = this.localeService.localeChanged.subscribe((locale) => {
      this.updateTransformedValue(locale);
    });
  }
}
