<app-modal
  #modal
  [backdrop]="true"
  [closeEvent]="closeEvent"
  [closeOnBackdropClick]="true"
  [closeOnEsc]="true"
  [showModalFooter]="false"
  class=""
  id="embed-modal"
  size="md"
  title="Video einbinden"
>
  <div modal-header>{{'embed_modal.title' | translate}}</div>
  <div modal-body>
    <div class="alert alert-info" *ngIf="asset && asset.flags.includes(assetFlags.NO_EMBED)">
      {{'embed_modal.error_no_embed_possible' | translate}}
    </div>

    <div class="" *ngIf="asset && !asset.flags.includes(assetFlags.NO_EMBED) && code">
      <div class="input-group">
        <input type="text" class="form-control" [value]="code" />
        <div class="input-group-btn">
          <button class="btn btn-primary btn-primary" (click)="copy()">
            <i class="fa fa-copy" *ngIf="!copied"></i>
            <i class="fa fa-check" *ngIf="copied"></i>
            {{'embed_modal.copy' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal>
