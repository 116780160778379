import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService} from '../services/authentication.service';
import {SeoMetaTagsService} from '../services/seo-meta-tags.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard implements CanActivate {
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.loggedIn$.asObservable().pipe(
      filter(l => typeof l === 'boolean'),
      take(1),
      map(loggedIn => {
        if (loggedIn) {
          return true;
        }
        this.seoMetaTagsService.addPrerenderingStatusCode(401);
        this.router.navigate(['/', { outlets: { m: 'login' } }], {
          queryParams: {
            redirect: state.url,
            reason: route.data['loginReasonCode'],
          },
        });
        return false;
      }),
    );
  }

  public constructor(
    private seoMetaTagsService: SeoMetaTagsService,
    private auth: AuthenticationService,
    private router: Router,
    private log: NGXLogger,
  ) {}
}
