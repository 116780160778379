<a href="#main_content" class="sr-only">{{'header.to_content'|translate}}</a>
<app-header></app-header>

<div class="d-flex flex-grow-1">
  <div class="container-fluid px-0 px-sm-2 mt-2 d-xl-flex justify-content-evenly" [class.fireplace-ad-mode]="(fireplaceAdService.firePlaceAdShown | async)" >
    <a [class.d-none]="!(fireplaceAdService.firePlaceAdShown | async)"
       (click)="trackGothaerFireplaceAdImpression()"
       href="https://www.youtube.com/playlist?list=PLjr4Qbf4s29gsmfktpLkwC-mQ9Ibi7uz5"
       target="_blank"
       class="fireplace-banner"
       data-track-content=""
       data-content-name="Fireplace Gothaer AD"
       data-content-piece="Banner"
    >
      <div class="extension"></div>
      <div class="main"></div>
    </a>

    <div class="container d-flex flex-column px-0" id="content-container"
         [class.mx-0]="fireplaceAdService.firePlaceAdShown | async"
         [class.pt-3]="fireplaceAdService.firePlaceAdShown | async"
    >
      <span>
        <!--<div *ngIf="isFromRussia" class="alert bg-primary text-black alert-dismissible w-100 text-center" style="font-size: 32px">
          Уберите ваших солдат из Украины и завершите войну!
        </div>-->
      <router-outlet id="main_content"
                     (deactivate)="loadSkyscraper()"
      ></router-outlet>
      <footer class="">
        <div class="container" >
          <div class="row">
            <div class="col-12  ">

              <p class="text-center mb-0 pb-0 mt-5">
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none"
                   routerLink="/datenschutz">
                  {{'footer.datenschutz' |translate}}
                  </a> •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none pointer"
                   (click)="showCMP()">
                  {{'footer.cookie_settings' |translate}}
                  </a>
                •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none" routerLink="/cookiehinweise">
                  {{'footer.cookie_notice' |translate}}
                </a>
                •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none" routerLink="/agb">
                  {{'footer.agb' |translate}}
                </a>
                •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none" routerLink="/faq">
                  {{'footer.faq' |translate}}
                  </a>
                •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none"
                   routerLink="/impressum">{{'footer.imprint' |translate}}</a> •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none"
                   routerLink="/vertrag-kuendigen">
                  {{'footer.cancel_contract' |translate}}

                </a> •
                <a rel="nofollow" class="sdtv-hover-text-primary-underline small text-muted text-decoration-none"
                   href="https://dosbnewmedia.de/karriere" target="_blank">
                  {{'footer.jobs' |translate}}
                  </a>
              </p>

            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center mt-1">

              <p>
                <a class="text-muted text-decoration-none sdtv-hover-text-primary-underline px-2" rel="nofollow,noopener"
                   href="https://twitter.com/SportDE_TV" target="_blank">
                  <i class="fa fa-twitter"></i>
                </a>


                <a class="text-muted text-decoration-none sdtv-hover-text-primary-underline px-2" rel="nofollow,noopener"
                   href="https://www.youtube.com/channel/UCfD6NnnGZjYi-MG3zEfMcmg" target="_blank">
                  <i class="fa fa-youtube"></i>
                </a>


                <a class="text-muted text-decoration-none sdtv-hover-text-primary-underline px-2" rel="nofollow,noopener"
                   href="https://www.facebook.com/Sportdeutschland.TV" target="_blank">
                  <i class="fa fa-facebook"></i>
                </a>


                <a class="text-muted text-decoration-none sdtv-hover-text-primary-underline px-2" rel="nofollow,noopener"
                   href="https://www.instagram.com/sportdeutschland.tv/" target="_blank">
                  <i class="fa fa-brands fa-instagram"></i>
                </a>


                <a class="text-muted text-decoration-none sdtv-hover-text-primary-underline px-2" rel="nofollow,noopener"
                   href="https://www.tiktok.com/@sportdeutschland.tv" target="_blank">
                  <i class="fa fa-brands fa-tiktok"></i>
                </a>
              </p>


            </div>

          </div>
        </div>


      </footer>
      </span>

    </div>

    <a
      [class.d-none]="!(fireplaceAdService.firePlaceAdShown | async)"
      (click)="trackGothaerFireplaceAdImpression()"
      href="https://www.youtube.com/playlist?list=PLjr4Qbf4s29gsmfktpLkwC-mQ9Ibi7uz5"
      target="_blank"
      class="fireplace-skyscraper"
      data-track-content=""
      data-content-name="Fireplace Gothear AD"
      data-content-piece="Skyscraper"
    ></a>

    <div class="d-none d-xxl-flex flex-column skyscraper" *ngIf="!(fireplaceAdService.firePlaceAdShown | async) && has_sportdeutschland_plus === false">
      <app-display-ad
        #skyscraper
        [format]="SOM_DISPLAY_AD_SLOT_TYPES.SKYSCRAPER"
      ></app-display-ad>
    </div>

  </div>
</div>
<router-outlet name="m"></router-outlet>
