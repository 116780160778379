import {Injectable} from '@angular/core';
import {ChunkLoaderService} from "../../chunk-loader.service";
import {ProfileForMenuResource} from "../../../../../entities/Profile.entity";


@Injectable({
  providedIn: 'root',
})
export class ProfilesForSportTypeTagService extends ChunkLoaderService<ProfileForMenuResource> {

  // init with stateless/frontend/menu/sport-types/{{tag.id}}
  public url = '';

  public override chunkSize = 20;
}
