import {SomtagService} from '../../services/somtag.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {Subscription} from 'rxjs';
import {SOM_DISPLAY_AD_SLOT_TYPES} from '../../../../types/somtag';
import {environment} from '../../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService} from "../../services/authentication.service";


@Component({
  selector: 'app-display-ad',
  templateUrl: './display-ad.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAdComponent implements OnDestroy, AfterViewInit {
  @Input() public format!: SOM_DISPLAY_AD_SLOT_TYPES;
  @Output() public height: EventEmitter<number> = new EventEmitter<number>();
  @Output() public width: EventEmitter<number> = new EventEmitter<number>();
  @Output() public disabled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public environment = environment;
  public has_sportdeutschland_plus?: boolean;
  public loaded = false;

  protected readonly SOM_DISPLAY_AD_SLOT_TYPES = SOM_DISPLAY_AD_SLOT_TYPES;
  private loadSubscription?: Subscription;


  /**
   * done when the result of the ad is here
   * @private
   */
  private slotResultCallback = {
    next: (res: {
             height: number,
             width: number,
             format: SOM_DISPLAY_AD_SLOT_TYPES
           }
    ) => {
      this.loaded = true;
      this.cdr.markForCheck();

      this.width.emit(res.width);
      this.height.emit(res.height);
      this.disabled.emit(false);
      this.cdr.markForCheck();

    },
    error: () => {
      //this.log.error('display-ad.component: ', e)
      this.disabled.emit(true);
      this.cdr.markForCheck();
    },
  }


  public ngOnDestroy(): void {

    if (this.loadSubscription) {
      this.loadSubscription.unsubscribe()
    }
  }

  public load(): void {
    if (this.has_sportdeutschland_plus) {
      return;
    }
    this.loadSubscription = this.somtagService.loadDisplayAdForSlot(this.format, '#' + this.format).subscribe(
      this.slotResultCallback
    );
  }

  public ngAfterViewInit(): void {

    // verwende afterViewInit, da der somtagservice checkt ob ein element sichbar ist
    this.load();


  }

  public constructor(
    private log: NGXLogger,
    private somtagService: SomtagService,
    private cdr: ChangeDetectorRef,
    private auth: AuthenticationService
  ) {

    auth.profile$.subscribe(
      p => {
        if (p && p.has_sportdeutschland_plus) {
          this.has_sportdeutschland_plus = true;
        } else {
          this.has_sportdeutschland_plus = false;
        }

        this.cdr.markForCheck()
      }
    )

    /**
     * SOM setzt unter Umständen die Werbung erst ein, wenn die display-ad.component komplett neu erstellt wurde.
     * dann bleibt der revive banner da, weil die display-ad.component gar nicht mitbekommt, dass was eingesetzt wurde.
     */
    /* this.somtagService.aboutToInsertAd.pipe(
       filter(res => res.format !== this.format),
       distinctUntilChanged(),
       tap(r => this.log.log('aboutToInsertAd', r)),
     ).subscribe(this.slotResultCallback)*/

    somtagService.displayAdShouldLoad.subscribe(
      () => this.load()
    )
  }


}
