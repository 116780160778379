import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {HttpStatusCode} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SeoMetaTagsService {
  public updateMetaTags(metaInfo: {
    pageTitle?: Observable<string>;
    description?: Observable<string>;
    indexFollow?: boolean;
    keywords?: string;
    canonicalURL?: string;
    imageUrl?: string | null;
    type?: 'article' | 'website' | 'profile';
  }): void {
    this.meta.removeTag('property="og:title"');
    if (metaInfo.pageTitle) {
      metaInfo.pageTitle.subscribe(pageTitle => {
        this.pageTitle.setTitle(pageTitle);
        this.meta.addTag({ property: 'og:title', content: pageTitle });
      });
    }else {
      this.pageTitle.setTitle('');

    }
    this.meta.removeTag('property="og:image"');
    if (metaInfo.imageUrl) {
      this.meta.addTag({ property: 'og:image', content: metaInfo.imageUrl });
    } else {
      this.meta.addTag({ property: 'og:image', content: '/assets/images/sdtv_og_logo.jpg' });
    }
    this.meta.removeTag('property="og:url"');
    if (metaInfo.canonicalURL) {
      this.meta.addTag({ property: 'og:url', content: metaInfo.canonicalURL });
    }
    this.meta.removeTag('property="og:type"');
    if (metaInfo.type) {
      this.meta.addTag({ property: 'og:type', content: metaInfo.type });
    }

    this.meta.removeTag('name="description"');
    this.meta.removeTag('property="og:description"');
    if (metaInfo.description) {
      metaInfo.description.subscribe(description => {
        this.meta.addTag({
          name: 'description',
          content: description,
        });
        this.meta.addTag({ property: 'og:description', content: description });
      })
    }
    this.meta.removeTag('name="keywords"');
    if (metaInfo.keywords) {
      this.meta.addTag({
        name: 'keywords',
        content: metaInfo.keywords,
      });
    }

    if (metaInfo.canonicalURL) {
      this.updateCanonicalURL(metaInfo.canonicalURL);
    }

    this.meta.removeTag('name="robots"');
    if (metaInfo.indexFollow === true) {
      this.meta.addTag({ name: 'robots', content: 'index, follow' });
    } else if (metaInfo.indexFollow === false) {
      this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' }, 'name="robots"');
      prerenderReady = true;
    }
  }

  public addPrerenderingStatusCode(code: HttpStatusCode, fullRedirectUrlWithProtocol: string | undefined = undefined): void {
    const existingTag = this.meta.getTag('name="prerender-status-code"');
    if (existingTag) {
      if (['301', '302'].includes(existingTag?.content)) {
        // there was already a redirect set!
        // do not update status code to 200
        // do update the redirect url!
        if (fullRedirectUrlWithProtocol) {
          this.meta.removeTag('name="prerender-header"');
          this.meta.addTag({ name: 'prerender-header', content: 'Location: ' + fullRedirectUrlWithProtocol });
        }
        prerenderReady = true;
        return;
      }
    }

    this.meta.removeTag('name="prerender-status-code"');
    this.meta.addTag({ name: 'prerender-status-code', content: code.toString() });

    if (fullRedirectUrlWithProtocol) {
      this.meta.removeTag('name="prerender-header"');
      this.meta.addTag({ name: 'prerender-header', content: 'Location: ' + fullRedirectUrlWithProtocol });
    }
    prerenderReady = true;
  }

  private updateCanonicalURL(url?: string): void {
    const canURL = url == undefined ? this.dom.URL : url;

    let link: HTMLLinkElement = this.dom.getElementById('canonical-url') as HTMLLinkElement;
    if (!link) {
      link = this.dom.createElement('link');
      link.setAttribute('id', 'canonical-url');
    }

    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  public constructor(@Inject(DOCUMENT) private dom: Document, private pageTitle: Title, private meta: Meta) {}
}
