import {Pipe, PipeTransform} from '@angular/core';
import {ImageCdnService} from '../../shared/services/image-cdn.service';
import {BannerElementResponse} from "../../entities/Lists/elements/banner-element.response";
import {BANNER_WIDE_IMAGE_SIZE} from "../../../enums/BANNER_WIDE_IMAGE_SIZE";

@Pipe({
  name: 'bannerWideImage',
})
export class BannerWideImagePipe implements PipeTransform {
  public transform(
    value: BannerElementResponse | undefined,
    args: { size: BANNER_WIDE_IMAGE_SIZE; imageError?: string | undefined } = {
      size: BANNER_WIDE_IMAGE_SIZE.XL_1940_500,
      imageError: undefined,
    },
  ): string {

    if (!value) {
      return args.imageError ?? '/assets/placeholders/1x1.svg';
    }

    let imageId: string | undefined;

    if ("image_src" in value) {
      imageId = value?.image_src as string | undefined;
    }
    if ("image_id" in value && !imageId) {
      imageId = value?.image_id as string | undefined;
    }

    if (!imageId || imageId.startsWith('http')) {
      return args.imageError ?? '/assets/placeholders/1x1.svg';
    }

    return this.imageCdnService.getImageFqdn(imageId, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {
  }
}
