import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nl2br',
})
export class Nl2brPipe implements PipeTransform {
  public transform(value: string | undefined | null = ''): string {
    if (typeof value !== 'string') {
      return '';
    }
    return value.replace(/\n/g, '<br/>');
  }
}
