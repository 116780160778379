import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageCdnService {
  public getImageFqdn(uuid: UUID, size: string): string {
    const uuidParts = uuid.split('-');
    const imagePath =
      'images/' +
      uuidParts[0].split('').join('/') +
      '/' +
      uuidParts[1] +
      '/' +
      uuidParts[2] +
      '/' +
      uuidParts[3] +
      '/' +
      uuidParts[4] +
      '/' +
      size +
      '.webp';

    return environment.image_cdn.url + '/' + imagePath;
  }
}
