import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  /**
   * @property userIsActive
   * Active: When there is a mouse movement, click, scroll, or key down within the last 2 minutes while the document is visible.
   * Inactive: When there has been no interaction (as specified above) for 2 minutes while the document is visible.
   */
  public userIsActive = new BehaviorSubject<boolean>(true);
  private isVideoPlaying = false;
  private debug = false;



  public constructor(private log: NGXLogger) {

    const activityHappenedEvents = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'custom-playing-video').pipe(
        tap(() => this.isVideoPlaying = true) // Mark as playing when custom-playing-video event fires
      )
    );

    const documentIsVisible = fromEvent(document, 'visibilitychange').pipe(
      map(() => !document.hidden),
      startWith(true)
    );

    activityHappenedEvents.pipe(
      tap(() => this.userIsActive.next(true)), // Set user as active immediately upon activity
      debounceTime(1000),
      switchMap(() =>
        timer(30 * 1000).pipe( // after 30 sec of inactivity, consider the user inactive
          map(() => false),
          startWith(true)
        )
      ),
      filter(() => !document.hidden || this.isVideoPlaying), // Ignore visibility if video is playing
      distinctUntilChanged(),
    ).subscribe(isActive => this.userIsActive.next(isActive));

    documentIsVisible.pipe(
      // Ignore visibility change if video is playing and document is hidden
      filter(() => !this.isVideoPlaying && document.hidden),
    ).subscribe(documentIsVisible => this.userIsActive.next(documentIsVisible));


    if (this.debug) {
      activityHappenedEvents.subscribe((e) => {
        this.log.debug('[UserActivityService] Activity happened', e.type);
      })
      this.userIsActive.pipe(distinctUntilChanged()).subscribe(isActive => {
        this.log.debug('[UserActivityService] User is active:', isActive);
      });
    }
  }
}
