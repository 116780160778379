import {Injectable} from '@angular/core';
import {ConsentService} from './consent.service';
import {distinctUntilChanged, filter, switchMap, take} from 'rxjs/operators';
import {document} from 'ngx-bootstrap/utils';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {NGXLogger} from "ngx-logger";

@Injectable({
  providedIn: 'root',
})
export class MicrosoftAdsService {
  private readonly loggingEnabled = false;

  public addMSAdsScript(): void {
    this.hasConsent().subscribe(() => {

      const scriptId = 'ms-ad-script-loader';
      if (document.getElementById(scriptId) !== null) {
        return;
      }

      const script: HTMLScriptElement = document.createElement('script');

      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.innerHTML = '(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"97152972", enableAutoSpaTracking: false};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");';
      script.type = 'text/javascript';
      document.body.append(script);
    });
  }

  public hasConsent(): Observable<boolean> {
    return this.consentService.initialized$.pipe(
      distinctUntilChanged(),
      filter(initialized => initialized),
      switchMap(() => {
        this.log('Check MS Ads TCF consent')
        return this.consentService.tcfVendorConsentGiven(environment.tcfConsentIds.microsoft_ads)
      }),
      filter((consentGiven) => {
        return consentGiven;
      }),

      filter(hasConsent => hasConsent),
      take(1),
    );
  }

  public trackCustomEvent(type: string, category: string): void {
    this.hasConsent().subscribe(() => {
      window.uetq = window.uetq || [];
      window.uetq.push('event', type, {event_category: category})
    });
  }

  public trackCheckoutConversion(orderId: string, itemId: string, priceInCents?: number): void {
    this.hasConsent().subscribe(() => {
      const payload =
        {
          ecomm_prodid: itemId,
          ecomm_pagetype: 'PURCHASE',
          revenue_value: priceInCents ? (priceInCents / 100) : 0,
          currency: 'EUR',

          transaction_id: orderId,
          ecomm_totalvalue: priceInCents ? (priceInCents / 100) : 0,
          items: [
            {
              id: itemId,
              quantity: 1,
              price: priceInCents ? (priceInCents / 100) : 0,
            }
          ]
        };

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'PRODUCT_PURCHASE', payload)
    })
  }

  public trackPageView(pagePath: string): void {
    this.hasConsent().subscribe(() => {
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'page_view', {page_path: pagePath})
    });
  }

  private log(message: string, data: unknown = {}, severity: 'log' | 'error' | 'warn' = 'log'): void {
    if (this.loggingEnabled) {

      if (data) {
        this.ngxLogger[severity](message, data);
      } else {
        this.ngxLogger[severity](message);
      }
    }
  }

  public constructor(private consentService: ConsentService, private ngxLogger: NGXLogger) {}
}
