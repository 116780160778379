import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-content-grid',
  templateUrl: './content-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('contentGrid') public contentGrid!: ElementRef;

  @Input() public itemTemplate!: TemplateRef<HTMLElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public items: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input() public total!: BehaviorSubject<number | undefined>;
  @Input() public loading!: BehaviorSubject<boolean>;
  @Input() public id?: string;

  @Input() public rowItemsXs = 1;
  @Input() public rowItemsSm = 2;
  @Input() public rowItemsMd = 3;
  @Input() public rowItemsLg = 3;
  @Input() public rowItemsXl = 4;
  @Input() public rowItemsXXl = 6;
  @Input() public desiredRows = 1;
  @Input() public desiredRowsXs = 2;
  @Input() public paddingXonXS = false;

  @Input() public showLoadMore: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() public showLoadMoreAsButton = false;
  @Input() public loadMoreLabel?: string;
  @Input() public paddingBottom = true;
  @Input() public loadMoreIncrementsBy = 1;
  @Input() public infinityLoading = false;
  @Input() public gridGap = 15;

  @Input() public debugLabel?: string;

  @Output() public load: EventEmitter<number> = new EventEmitter<number>();
  @Output() public rowItems: EventEmitter<number> = new EventEmitter<number>();

  public math = Math;
  public containerWidth: number | undefined;
  public itemsPerRow: number | undefined;
  public _desiredRows!: number;
  public _addedRows = 0;

  /**
   * When changing these, also update the scss $grid-breakpoints var in bootstrap-variables
   */
  public readonly BREAKPOINT_SM = 576;
  public readonly BREAKPOINT_MD = 768;
  public readonly BREAKPOINT_LG = 992;
  public readonly BREAKPOINT_XL = 1200;
  public readonly BREAKPOINT_XXL = 1440;

  private requestViewUpdateEvent = new EventEmitter(undefined);

  private onDestroy$ = new Subject<void>();

  public ngOnInit(): void {
    if (!this.total) {
      throw new TypeError('content grid attribute total not set');
    }
    this.calcItemsPerRow();
    this.items.subscribe(() => {
      this.cdr.markForCheck();
      this.requestViewUpdateEvent.emit();
    });

    // If items are 0 it usually means two things:
    // 1. Response was empty
    // -> resetting the addedRows in this case doesn't matter since the was no content shown anyway
    // 2. Items were reset
    // -> we need to reset the addedRows since we have a state conflict
    this.items.pipe(
      filter(items => items.length <= 0),
      takeUntil(this.onDestroy$)
    ).subscribe({
      next: () => {
       // this._addedRows = 0;
      }
    });
  }

  public ngAfterViewInit(): void {
    this.requestViewUpdateEvent.emit();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  // Loads the next row of the needed items amount and gets more items from the backend if required
  public addRow(): void {
    // Add delayed loading of the next chunk if all initial chunks are loaded
    // And more content is available
    this._addedRows += this.loadMoreIncrementsBy;
    this.loadItems();
  }

  // Has the same functionality as addRow() except it is only triggered when the chunk loader isn't currently loading something
  // This uses a scroll event which triggers often and rapidly
  public loadInfinite(): void {
    if (!this.loading.getValue()) {
      this._addedRows += this.loadMoreIncrementsBy;
      this.loadItems();
    }
  }

  private loadItems(): void {
    if (this.loading.getValue()) {
      return;
    }

    if (this.itemsPerRow === undefined) {
      this.log.error('loadItems called without itemsPerRow', this.itemsPerRow);
      return;
    }

    if (this.itemsPerRow === 0) {
      this.log.error('itemsPerRow is 0 ');
      return;
    }

    const total = this.total.getValue();
    const desiredRows = this._desiredRows + this._addedRows;
    let amountToLoad;

    if (total !== undefined) {
      amountToLoad = Math.min(total, desiredRows * this.itemsPerRow);
    } else {
      amountToLoad = desiredRows * this.itemsPerRow;
    }

    if (amountToLoad === this.items.getValue().length) {
      return;
    }

    this.load.emit(amountToLoad);
  }

  // Loads items depending on the amount of items needed pre row and how many rows are desired
  // Items per row and desired rows depend on the responsive settings given to the chunk loader
  private updateContentToView(): void {
    this.calcItemsPerRow();
    this.loadItems();
  }



  private calcItemsPerRow(): void {
    this.cdr.markForCheck();
    this.containerWidth = this.contentGrid?.nativeElement?.offsetWidth;


    if (this.containerWidth === undefined) {
      // this row is crucial for server side rendering to function!
      this.containerWidth = this.BREAKPOINT_MD;
    }
    if (this.containerWidth === 0) {
      this.itemsPerRow = 0;
    }
    if (this.containerWidth < this.BREAKPOINT_SM) {
      this.itemsPerRow = this.rowItemsXs;
      this._desiredRows = this.desiredRowsXs;
    } else if (this.containerWidth < this.BREAKPOINT_MD) {
      this.itemsPerRow = this.rowItemsSm;
      this._desiredRows = this.desiredRows;
    } else if (this.containerWidth < this.BREAKPOINT_LG) {
      this.itemsPerRow = this.rowItemsMd;
      this._desiredRows = this.desiredRows;
    } else if (this.containerWidth < this.BREAKPOINT_XL) {
      this.itemsPerRow = this.rowItemsLg;
      this._desiredRows = this.desiredRows;
    } else if (this.containerWidth < this.BREAKPOINT_XXL) {
      this.itemsPerRow = this.rowItemsXl;
      this._desiredRows = this.desiredRows;
    } else {
      // this.containerWidth >= 1320
      this.itemsPerRow = this.rowItemsXXl;
      this._desiredRows = this.desiredRows;
    }

    this.rowItems.next(this.itemsPerRow);
  }

  @HostListener('window:resize')
  private onDocumentResize(): void {
    this.requestViewUpdateEvent.emit();
  }

  public constructor(
    private cdr: ChangeDetectorRef,
    private log: NGXLogger,
  ) {
    this.requestViewUpdateEvent.pipe(debounceTime(50)).subscribe(() => {
      this.updateContentToView()
    });

  }
}
