import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class AuthenticationStatusService {

  public loggedInStatus = new BehaviorSubject<boolean | undefined>(undefined);
}


