import {Injectable, Injector} from '@angular/core';
import {catchError, map, take, takeWhile, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {StorageService} from './storage.service';
import {UserResource} from '../../entities/User.entity';
import {ProfileEditingResource} from '../../entities/Profile.entity';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {PROFILE_TYPE} from '../../../enums/PROFILE_TYPE';
import {AnalyticsService} from './analytics.service';
import {WebsocketService} from './websocket.service';
import {ProfileNotificationCreated, WEBSOCKET_EVENT} from '../../entities/WebSocketMessage.entity';
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationEventService} from "./authentication.event.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AuthenticationStatusService} from "./authentication.status.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public static STORAGE_USER_NAME = 'user';
  public loggedIn$: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  public country$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public user$: BehaviorSubject<UserResource | undefined> = new BehaviorSubject<UserResource | undefined>(undefined);
  public profile$: BehaviorSubject<ProfileEditingResource | undefined> = new BehaviorSubject<ProfileEditingResource | undefined>(undefined);
  public unreadNotificationsCount$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);


  private _redirectAfterLogin: string | null = null;

  private _analyticsService?: AnalyticsService;
  private get analyticsService(): AnalyticsService {
    if (!this._analyticsService) {
      this._analyticsService = this.injector.get(AnalyticsService);
    }
    return this._analyticsService;
  }

  public updateProfile(profile: ProfileEditingResource): void {
    this.profile$.next(profile);
  }

  public addOrderable(id: UUID): void {
    const p: ProfileEditingResource | undefined = this.profile$.getValue();
    if (p === undefined) {
      return;
    }
    p.bought_products_and_asset_ids.push(id);
    this.updateProfile(p);
  }

  public updateUser(user: UserResource): void {
    this.user$.next(user);
  }

  public deleteAccount(): Observable<boolean> {
    return this.http.delete('/auth/delete-account', {}).pipe(
      map(() => {
        this.handleLogout();
        return true;
      }),
    );
  }

  public login(user: UserResource): void {
    this.updateUser(user);
    this.updateProfile(user.profile)
    this.loggedIn$.next(true);
    if (user.profile.type !== PROFILE_TYPE.VIEWER) {
      setTimeout(() => this.websocketService.connect(user.profile), 1);
    }
    this.storage.set(AuthenticationService.STORAGE_USER_NAME, {
      id: user.id,
      email: user.email,
    });
    return;
  }

  public redirectAfterLogin(): boolean {
    if (this._redirectAfterLogin) {
      this.router.navigateByUrl(this._redirectAfterLogin).then(
        () => {
          this._redirectAfterLogin = null;
          this.log.log('redirected');
        },
        () => {
          this.log.error('could not redirect');
        },
      );
      return true;
    }

    return false;
  }

  public setRedirectAfterLogin(redirectTo: string): void {
    this._redirectAfterLogin = redirectTo;
  }

  public getUserId(): Observable<string | undefined> {
    return of(this.storage.get<{ id: string; email: string }>(AuthenticationService.STORAGE_USER_NAME)).pipe(
      map(user => {
        if (user && user.id) {
          return user.id;
        } else {
          return undefined;
        }
      }),
      catchError(() => of(undefined)),
    );
  }

  public logout(showToast = true): void {
    this.http.post('/web/personal/auth/logout', {}).subscribe(
      () => {
        this.handleLogout();
        if (showToast) {
          this.toastr.success(this.translate.instant('logout.logout_success'));
        }

        this.router.navigate(['/']);
      },
      (res: Error) => {
        this.log.warn('[auth] could not logout user');
        if (res instanceof HttpErrorResponse) {
          if (res.status === 401) {
            this.handleLogout();
            if (showToast) {
              this.toastr.success(this.translate.instant('logout.logout_success'));
            }
            this.router.navigate(['/']);
            return;
          }
        }
        if (showToast) {
          this.toastr.error(this.translate.instant('logout.logout_error'));
        }

      },
    );
  }

  public adsDisabledForUser(): Observable<boolean> {
    return this.profile$.pipe(
      map(p => {
        if (typeof p == 'undefined') {
          return false;
        }
        return p.has_sportdeutschland_plus;
      }),
    );
  }

  public updateNotificationsCount(): Observable<number | undefined> {
    if (this.profile$.value?.type === PROFILE_TYPE.VIEWER) {
      return of(undefined);
    }
    return this.http.get<{ count: number }>('/personal/profile-notifications/unread').pipe(
      map((res: { count: number }) => {
        this.unreadNotificationsCount$.next(res.count);
        return res.count;
      }),
    );
  }

  public reduceVisibleUnreadNotificationsCountBy(reduceBy: number): Observable<number | undefined> {
    if (this.profile$.value?.type === PROFILE_TYPE.VIEWER) {
      return of(undefined);
    }
    const currentCount = this.unreadNotificationsCount$.getValue();
    if (typeof currentCount === 'number') {
      if (currentCount >= reduceBy) {
        this.unreadNotificationsCount$.next(currentCount - reduceBy);
      } else {
        this.unreadNotificationsCount$.next(0);
      }
    }
    return this.unreadNotificationsCount$;
  }

  public updateUserAndProfileFromServer(): Observable<{ user: UserResource }> {
    return this.http.get<{ user: UserResource }>('/web/personal/user').pipe(
      tap(u => {
        this.updateProfile(u.user.profile);
        this.updateUser(u.user)
      })
    )
  }

  public changePassword(value: {
    current_password: string,
    new_password: string,
    new_password_confirmation: string
  }): Observable<void> {
    return this.http.post<void>('/auth/password-change', value).pipe(
      take(1),
      tap({
        error: () => {
          this.log.warn('[auth] could not change password');
          this.toastr.error(this.translate.instant('settings.password_changed_error_toastr'));
        }
      })
    );
  }

  private handleLogout(): void {
    this.clearUserCache();
    this._redirectAfterLogin = null;
    if (this.profile$.getValue() !== undefined || this.user$.getValue() !== undefined) {
      this.analyticsService.logout();
    }
    this.user$.next(undefined);
    this.profile$.next(undefined);
    this.loggedIn$.next(false);

    setTimeout(() => this.websocketService.disconnect(), 1);
  }


  private isLoggedIn(): Observable<UserResource | boolean> {
    return this.http.get<{ user: UserResource }>('/web/personal/user', { observe: 'response' }).pipe(
      map(response => {
        const user = response.body?.user;
        if (user) {
          // Extract the country from the headers
          this.country$.next(response.headers.get('x-country'));
          this.login(user);
          return true;
        }
        this.handleLogout();
        return false;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          this.country$.next(err.headers.get('x-country'));
          if (err.status === 401) {
            this.handleLogout();
            return of(false);
          }
        }
        throw err;
      }),
      tap((user: UserResource | boolean) => {
        if (user && user !== true) {
          if (user.profile.type !== PROFILE_TYPE.VIEWER) {
            this.websocketService.connect(user.profile);
          }
        }
      }),
    );
  }


  private clearUserCache(): void {
    this.storage.delete(AuthenticationService.STORAGE_USER_NAME);
  }

  private subscribeForNotificationCount(): void {
    this.websocketService
      .observe<ProfileNotificationCreated>(WEBSOCKET_EVENT.PROFILE_NOTIFICATION_CREATED)
      .pipe(takeWhile(() => this.loggedIn$.value === true))
      .subscribe(
        e => {
          this.unreadNotificationsCount$.next(e.data.unread_notification_count);
        },
        () => {
          this.log.error('Error getting notifications count');
          this.unreadNotificationsCount$.next(undefined);
        },
      );
  }

  public constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private websocketService: WebsocketService,
    private router: Router,
    private storage: StorageService,
    private log: NGXLogger,
    private http: HttpClient,
    private injector: Injector,
    private authenticationEventService: AuthenticationEventService,
    private authenticationStatusService: AuthenticationStatusService
  ) {

    this.isLoggedIn().subscribe(user => {
      if (typeof user == 'boolean') {
        this.clearUserCache();
      } else {
        this.user$.next(user);
        this.profile$.next(user.profile);

        if (user.profile.type !== PROFILE_TYPE.VIEWER) {
          this.updateNotificationsCount().subscribe();
          this.subscribeForNotificationCount();
        }
      }
    });

    this.authenticationEventService.notAuthorizedErrorEmitted.subscribe(() => {
      // only when the frontend thinks it is logged in
      if (this.user$.getValue() !== undefined) {
        this.toastr.warning(this.translate.instant('logout.logged_out_device', {limit: 2}), this.translate.instant('logout.logged_out_headline'));
        this.router.navigateByUrl('/').then(() => {
          // do nothing
        });
      }
      this.handleLogout();
    })

    this.loggedIn$.pipe(
      takeUntilDestroyed()
    ).subscribe({
      next: value => this.authenticationStatusService.loggedInStatus.next(value)
    })

    window.addEventListener(
      'storage',
      event => {
        if (event.storageArea == localStorage && event.key === AuthenticationService.STORAGE_USER_NAME) {
          // console.log('user changed in localstorage', user)

          this.isLoggedIn().subscribe(
            () => {
              // updated the user account
            },
            () => {
              if (this.profile$.getValue() !== undefined) {
                this.toastr.info(this.translate.instant('logout.logged_out_in_other_tab'), '', {
                  disableTimeOut: true,
                });
              }
              this.handleLogout();
              this.router.navigateByUrl('/');
            },
          );

        }
      },
      false,
    );

  }
}
