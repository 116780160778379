import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlayerComponent} from './components/player.component';
import {VideoContainerComponent} from './components/video-container/video-container.component';
import {PlayerErrorComponent} from './components/error/player-error.component';
import {PlayerLoginComponent} from './components/login/player-login.component';
import {PlayerPaywallComponent} from './components/paywall/player-paywall.component';
import {PlayerCountdownComponent} from './components/player-countdown/player-countdown.component';
import {VideoComponent} from './components/video/video.component';
import {ProductPriceComponent} from './components/player-countdown/product-price/product-price.component';
import {SmallOverlayBannerComponent} from './components/video/small-overlay-banner/small-overlay-banner.component';
import {AssetHydrationService} from './services/asset-hydration.service';
import {DateFnsModule} from 'ngx-date-fns';
import {PaywallContentComponent} from './components/paywall-content/paywall-content.component';
import {
  FullscreenOverlayBannerComponent
} from './components/video/fullscreen-overlay-banner/fullscreen-overlay-banner.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../pipes-module/pipes.module";

@NgModule({
  declarations: [
    PlayerComponent,
    VideoContainerComponent,
    PlayerErrorComponent,
    PlayerLoginComponent,
    PlayerPaywallComponent,
    PlayerCountdownComponent,
    VideoComponent,
    ProductPriceComponent,
    SmallOverlayBannerComponent,
    FullscreenOverlayBannerComponent,
    PaywallContentComponent,
  ],
  exports: [PlayerComponent],
  imports: [CommonModule, DateFnsModule, FormsModule, NgSelectModule, TranslateModule, RouterModule, PipesModule],
  providers: [AssetHydrationService],
})
export class PlayerModule {
}
