import {VASTClient} from 'vast-client';

export enum SOM_IAB_GENRE {
  OTHER = 'OTHER',
  ACTION = 'Action',
  ADVENTURE = 'Adventure',
  ANIMALS = 'Animals',
  ANIMATION = 'Animation',
  AUTOMOTIVE = 'Automotive',
  BIOGRAPHY = 'Biography',
  COMEDY = 'Comedy',
  COOKING = 'Cooking',
  CRIME = 'Crime',
  DOCUMENTARY = 'Documentary',
  DRAMA = 'Drama',
  EDUCATION = 'Education',
  ENTERTAINMENT = 'Entertainment',
  FANTASY = 'Fantasy',
  FASHION = 'Fashion',
  HISTORY = 'History',
  HORROR = 'Horror',
  MUSIC = 'Music',
  MYSTERY = 'Mystery',
  NEWS = 'News',
  REALITY = 'Reality',
  ROMANCE = 'Romance',
  SITCOM = 'Sitcom',
  THRILLER = 'Thriller',
  TRAVEL = 'Travel',
  WAR = 'War',
  WEATHER = 'Weather',
  WESTERN = 'Western',
}
export enum SOM_IAB_PRPDUCTION_QUALITY {
  UNKOWN = 0,
  PROFESSIOBALLY_PRODUCED = 1,
  PROSUMER = 2,
  USER_GENERATED_CONTENT = 3,
}
export enum SOM_IAB_CATEGORY {
  SPORTS = '483',
  AMERICAN_FOOTBALL = '484',
  BOXING = '485',
  CHEERLEADING = '486',
  COLLEGESPORTS = '487',
  COLLEGEFOOTBALL = '488',
  COLLEGEBASKETBALL = '489',
  COLLEGEBASEBALL = '490',
  CRICKET = '491',
  CYCLING = '492',
  DARTS = '493',
  DISABLEDSPORTS = '494',
  DIVING = '495',
  EQUINESPORTS = '496',
  HORSERACING = '497',
  EXTREMESPORTS = '498',
  CANOEINGANDKAYAKING = '499',
  CLIMBING = '500',
  PAINTBALL = '501',
  SCUBADIVING = '502',
  SKATEBOARDING = '503',
  SNOWBOARDING = '504',
  SURFINGBODYBOARDING = '505',
  WATERSKIINGANDWAKEBOARDING = '506',
  AUSTRALIANRULESFOOTBALL = '507',
  FANTASYSPORTS = '508',
  FIELDHOCKEY = '509',
  FIGURESKATING = '510',
  FISHINGSPORTS = '511',
  GOLF = '512',
  GYMNASTICS = '513',
  HUNTINGANDSHOOTING = '514',
  ICEHOCKEY = '515',
  INLINESKATING = '516',
  LACROSSE = '517',
  AUTORACING = '518',
  MOTORCYCLESPORTS = '519',
  MARTIALARTS = '520',
  OLYMPICSPORTS = '521',
  SUMMEROLYMPICSPORTS = '522',
  WINTEROLYMPICSPORTS = '523',
  POKERANDPROFESSIONALGAMBLING = '524',
  RODEO = '525',
  ROWING = '526',
  RUGBY = '527',
  RUGBYLEAGUE = '528',
  RUGBYUNION = '529',
  SAILING = '530',
  SKIING = '531',
  SNOOKERPOOLBILLIARDS = '532',
  SOCCER = '533',
  BADMINTON = '534',
  SOFTBALL = '535',
  SQUASH = '536',
  SWIMMING = '537',
  TABLETENNIS = '538',
  TENNIS = '539',
  TRACKANDFIELD = '540',
  VOLLEYBALL = '541',
  WALKING = '542',
  WATERPOLO = '543',
  WEIGHTLIFTING = '544',
  BASEBALL = '545',
  WRESTLING = '546',
  BASKETBALL = '547',
  BEACHVOLLEYBALL = '548',
  BODYBUILDING = '549',
  BOWLING = '550',
}

export interface SomtagConfig {
  enabled: boolean; // Default value is `true`, so this key is optional
  config: {
    id: string;
    integrationId: string;
    consent: {
      optOutUncertifiedVendors: boolean;
      // optOut: boolean;
      tcfVersion: 2;
      gdprConsent?: string;
    };
  };
  device: {
    mode: 'auto' | 'mobile' | 'desktop'; // default value. 'auto'
    breakpoint: number; // threshold value to switch between mobile and desktop,
    // when mode is 'auto'
  };
  keywords?: string[];
  custom?: { key: string; value: string[] }[];
  taxonomy?: {
    channels?: string[];
    content?: 'content' | 'gallery' | 'video' | 'liveticker'; // optional: page category. possible values:
    country?: 'de';
    site?: 'sportdeutschland';
    brand?: 'sportdeutschland';
    inventory?: {
      country: 'de';
      site: 'sportdeutschland';
      brand: 'sportdeutschland';
      device: 'desktop' | 'mobile';
      format: string;
      categories: string[];
    };
  };
  display?: {
    slots: { [key: string]: { enabled: boolean } };
  };
  video?: {
    longplay: boolean;
    block?: {
      duration: number;
    },
    blocks: {
      pre?: { enabled: boolean };
      mid?: { enabled: boolean };
      post?: { enabled: boolean };
      overlay?: { enabled: boolean };
      pause?: { enabled: boolean };
    };
    iabContent: {
      id: string;
      episode: number;
      title: string;
      series: string;
      season: string;
      artist: string;
      genre: SOM_IAB_GENRE;
      isrc: string; //'ISO3901_code';
      url: string;
      cat: SOM_IAB_CATEGORY[];
      prodq: SOM_IAB_PRPDUCTION_QUALITY;
      context: number;
      contentrating: 'FSK 18' | 'FSK 16' | 'FSK 12' | 'FSK 6' | 'FSK 0';
      sourcerelationship: 1 | 0;
      keywords: string;
      livestream: 0 | 1;
      len?: number;
      language: 'de';
    };
  };
}

export type SomtagDisplayAdResponse = SomtagAdRequestResponse | SomtagAdResponse;

export interface SomtagAdRequestResponse {
  data: {
    options: {
      container: string;
    };
    slotName: SOM_DISPLAY_AD_SLOT_TYPES;
  };
  error: null | unknown;
  type: 'adRequest';
}

export interface SomtagAdResponse {
  error: unknown;
  data: {
    options: {
      container: string;
    };
    slotName: SOM_DISPLAY_AD_SLOT_TYPES;
    adConfig: {
      width: number;
      height: number;
      modifier: 'fallback' | unknown;
    };
  };

  type: 'adResponse';
  // todo add types for error
}

export type SOMCallback = (err: Error | null, result: string[], vastClient?: VASTClient) => void;
export type SOMDisplayCallback = (err: Error | null, result: SomtagAdResponse) => void;

export enum SOM_DISPLAY_AD_SLOT_TYPES {
  FULLBANNER = 'fullbanner2',
  SKYSCRAPER = 'skyscraper1',
  RECTANGLE = 'rectangle1',
  MOBILE_BANNER_1 = 'mbanner1',
  MOBILE_BANNER_2 = 'mbanner2',
  INREAD_1 = 'inread1',
  MOBILE_INREAD_1 = 'minread1',
  PROMO_MED_RECT_1 = 'promo1',
  PROMO_MED_RECT_2 = 'promo2',
  PROMO_MED_RECT_3 = 'promo3',
  PROMO_MED_RECT_4 = 'promo4',
  PROMO_MED_RECT_5 = 'promo5',
  PERFORMANCE_FULLBANNER_1 = 'performanceFullbanner1',
  PERFORMANCE_FULLBANNER_2 = 'performanceFullbanner2',
  PERFORMANCE_FULLBANNER_3 = 'performanceFullbanner3',
  PERFORMANCE_FULLBANNER_4 = 'performanceFullbanner4',
  PERFORMANCE_FULLBANNER_5 = 'performanceFullbanner5',
  PERFORMANCE_MOBILE_BANNER_1 = 'performanceMbanner1',
  PERFORMANCE_MOBILE_BANNER_2 = 'performanceMbanner2',
  PERFORMANCE_MOBILE_BANNER_3 = 'performanceMbanner3',
  PERFORMANCE_RECTANGLE_1 = 'performanceRectangle1',
  PERFORMANCE_RECTANGLE_2 = 'performanceRectangle2',
  PERFORMANCE_RECTANGLE_3 = 'performanceRectangle3',
  PERFORMANCE_RECTANGLE_4 = 'performanceRectangle4',
  PERFORMANCE_RECTANGLE_5 = 'performanceRectangle5',
  PERFORMANCE_RECTANGLE_6 = 'performanceRectangle6',
  PERFORMANCE_RECTANGLE_7 = 'performanceRectangle7',
  PERFORMANCE_RECTANGLE_8 = 'performanceRectangle8',
}

export enum SOM_VIDEO_AD_BLOCK_TYPES {
  PRE_ROLL = 'pre',
  POST_ROLL = 'post',
  MID_ROLL = 'mid',
  CUTIN = 'cutin',
}

export declare class Somtag {
  /**
   * The init Command
   * @see https://sevenonemedia.github.io/adtec-se-docs/docs/desktop-introduction.html
   * @param command
   * @param configObject
   */
  public cmd(command: 'init', configObject: SomtagConfig): Promise<Record<string, unknown>>;

  public cmd(
    command: 'getVideoBlock',
    block: SOM_VIDEO_AD_BLOCK_TYPES,
    config: {
      adBreakDuration?: number;
      maxNonSponsorCount?: number;
    },
    callback?: SOMCallback | SOMDisplayCallback,
  ): Promise<Record<string, unknown>>;

  public cmd(
    command: 'insertAd',
    block: SOM_DISPLAY_AD_SLOT_TYPES,
    customValues: {
      container?: string;
    },
    callback?: SOMDisplayCallback,
  ): Promise<SomtagDisplayAdResponse>;

  public cmd(command: 'reloadDisplaySlots'): Promise<Record<string, unknown>>;
}
