import {HttpErrorResponse} from "@angular/common/http";

export abstract class TooManyDevicesResponse extends HttpErrorResponse {

  public override readonly status = 409;
  public override readonly  error!:TooManyDevicesResponseError
}


export enum TOO_MANY_DEVICES_REASON {
  CODE_BASED_LOGIN = 'CODE_BASED_LOGIN',
  WEB_LOGIN = 'WEB_LOGIN',
  APP_LOGIN = 'APP_LOGIN',
  TOO_MANY_DEVICES_DETECTED = 'TOO_MANY_DEVICES_DETECTED',
  LIST_DEVICES = 'LIST_DEVICES',
}


export interface TooManyDevicesResponseError {
  error: 'auth.too_many_devices',
  active_devices: Device[],
  device_limit: number,
  need_to_remove: number,
  reason: TOO_MANY_DEVICES_REASON,
  clear_all_devices_url: string
}

export interface Device {
  delete_url: string,
  created_timestamp: number, // in millis
  last_used_timestamp?: number // in millis
  current_session: boolean,
  country?: string
  city?: string
  device_icon: string
  device_name: string
}
