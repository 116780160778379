import {NgModule} from '@angular/core';
import {AssetDatePipe} from "./pipes/asset-date.pipe";
import {AssetImageSizePipe} from "./pipes/asset-image-size.pipe";
import {ProfileHeaderImageSizePipe} from "./pipes/profile-header-image-size.pipe";
import {ProfileImageSizePipe} from "./pipes/profile-image-size.pipe";
import {ProfileSponsorImageSizePipe} from "./pipes/profile-sponsor-image-size.pipe";
import {SafeResourceUrlPipe} from "./pipes/safe-url.pipe";
import {DurationFormatPipe} from "./pipes/duration-format.pipe";
import {DeviceCategoryPipe} from "./pipes/device-category.pipe";
import {Nl2brPipe} from "./pipes/nl2br.pipe";
import {CurrencyFormatPipe} from "./pipes/currency-format.pipe";
import {CommonModule, CurrencyPipe} from "@angular/common";
import {GetAllHeimspielResultProfileIdsPipe} from "./pipes/get-all-heimspiel-result-profile-ids.pipe";
import {ProfileCarouselBackgroundImageSizePipe} from "./pipes/profile-carousel-background-image-size.pipe";
import {BannerWideImagePipe} from "./pipes/banner-wide-image.pipe";
import {BannerSquareImagePipe} from "./pipes/banner-square-image.pipe";
import {SecondsToCountdownPipe} from './pipes/seconds-to-countdown.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AssetDatePipe,
    AssetImageSizePipe,
    ProfileHeaderImageSizePipe,
    ProfileImageSizePipe,
    ProfileSponsorImageSizePipe,
    SafeResourceUrlPipe,
    DurationFormatPipe,
    DeviceCategoryPipe,
    Nl2brPipe,
    CurrencyFormatPipe,
    SafeResourceUrlPipe,
    GetAllHeimspielResultProfileIdsPipe,
    ProfileCarouselBackgroundImageSizePipe,
    BannerWideImagePipe,
    BannerSquareImagePipe,
    SecondsToCountdownPipe
  ],
  providers: [
    AssetDatePipe,
    AssetImageSizePipe,
    ProfileHeaderImageSizePipe,
    ProfileImageSizePipe,
    ProfileSponsorImageSizePipe,
    SafeResourceUrlPipe,
    DurationFormatPipe,
    DeviceCategoryPipe,
    Nl2brPipe,
    CurrencyFormatPipe,
    SafeResourceUrlPipe,
    CurrencyPipe,
    GetAllHeimspielResultProfileIdsPipe,
    ProfileCarouselBackgroundImageSizePipe,
    BannerWideImagePipe,
    BannerSquareImagePipe
  ],
  exports: [
    AssetDatePipe,
    AssetImageSizePipe,
    ProfileHeaderImageSizePipe,
    ProfileImageSizePipe,
    ProfileSponsorImageSizePipe,
    SafeResourceUrlPipe,
    DurationFormatPipe,
    DeviceCategoryPipe,
    Nl2brPipe,
    CurrencyFormatPipe,
    SafeResourceUrlPipe,
    GetAllHeimspielResultProfileIdsPipe,
    ProfileCarouselBackgroundImageSizePipe,
    BannerWideImagePipe,
    BannerSquareImagePipe,
    SecondsToCountdownPipe
  ]
})
export class PipesModule { }
