import {ChangeDetectorRef, Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConsentService} from "../../services/consent.service";
import {AuthenticationService} from "../../services/authentication.service";
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-consent-wall',
  templateUrl: './consent-wall.component.html',
})
export class ConsentWallComponent {

  public accepting = false;

  public accept(): void {
    this.accepting = true;
    this.cdr.markForCheck()
    this.consentService.acceptAll().subscribe(
      {
        next: () => this.activeModal.close(),
        error: () => {
          this.accepting = false;
          this.cdr.markForCheck()
        }
      }
    );

  }

  public openCMP(): void {
    this.consentService.showCMP()
  }

  public deny(): void {
    this.consentService.denyAllForSportdeutschlandPlus();
    this.activeModal.close()
  }


  public constructor(
    public localeService: LocaleService,
    public activeModal: NgbActiveModal,
    private consentService: ConsentService,
    public authService: AuthenticationService,
    private cdr: ChangeDetectorRef) {

  }


}
