import {Component, Input} from '@angular/core';
import {AnalyticsService} from "../../services/analytics.service";
import {EVENT_ACTION, EVENT_CATEGORY} from "../../../../types/gtm-options";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html'
})
export class LogoComponent {

  @Input() public isHandballNet = false
  @Input() public showGothaer = true

  public trackGothaerLogoImpression(): void {

    this.analyticsService.trackEvent(EVENT_CATEGORY.ADS, EVENT_ACTION.GOTHAER_SIDEBAR_IMPRESSION, 'Gothaer Logo', 1, false);
  }
  public constructor(private analyticsService: AnalyticsService) {


  }

}
