
<div class="modal-header">
  {{ 'too_many_devices.title' |translate }}
</div>

<div class="modal-body bg-dark">
    <p>

      <ng-container *ngIf="tooManyDevicesResponse.reason === TOO_MANY_DEVICES_REASON.CODE_BASED_LOGIN; else elseBlock">
        {{ 'too_many_devices.code_based_login' | translate: {
        activeDeviceCount: tooManyDevicesResponse.active_devices.length,
        limit: tooManyDevicesResponse.device_limit
      } }}
      </ng-container>

      <ng-template #elseBlock>
        {{ 'too_many_devices.web_login_message' | translate: {
        activeDeviceCount: tooManyDevicesResponse.active_devices.length,
        limit: tooManyDevicesResponse.device_limit
      } }}
      </ng-template>

      <br>

      <ng-container *ngIf="tooManyDevicesResponse.need_to_remove > 0">{{ 'too_many_devices.message_removal_note' | translate: {
        amountOfDevicesNeededToRemove: tooManyDevicesResponse.need_to_remove
      }
        }}</ng-container>

    </p>

    <app-device-list [devices]="tooManyDevicesResponse.active_devices" [reason]="tooManyDevicesResponse.reason" (deleteDevice)="deleteDevice($event)"></app-device-list>

  <div class="mt-3 text-center">
    <button class="btn btn-danger" (click)="clearAllDevices()" *ngIf="tooManyDevicesResponse.reason !== TOO_MANY_DEVICES_REASON.CODE_BASED_LOGIN">
      {{ 'too_many_devices.device.clear_all_button' | translate }}
    </button>
    <button class="btn btn-primary ms-2" (click)="abort.emit()"  *ngIf="[TOO_MANY_DEVICES_REASON.WEB_LOGIN, TOO_MANY_DEVICES_REASON.CODE_BASED_LOGIN].includes($any(tooManyDevicesResponse.reason))">
      {{ 'too_many_devices.device.cancel_button' | translate }}
    </button>
  </div>
</div>
