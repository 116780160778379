import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Device, TOO_MANY_DEVICES_REASON, TooManyDevicesResponseError} from "./too-many-devices.response";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {NGXLogger} from "ngx-logger";
import {TranslateService} from "@ngx-translate/core";
import {LocaleService} from "../../services/locale.service";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-too-many-devices[tooManyDevicesResponse]',
  templateUrl: './too-many-devices.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooManyDevicesComponent {


  @Input() public tooManyDevicesResponse!: TooManyDevicesResponseError;
  @Output() public close: EventEmitter<void> = new EventEmitter<void>();
  @Output() public abort: EventEmitter<void> = new EventEmitter<void>();

  protected readonly TOO_MANY_DEVICES_REASON = TOO_MANY_DEVICES_REASON;

  public deleteDevice(device: Device): void {
    this.http.delete<void>(device.delete_url).subscribe(
      {
        next: () => {
          this.tooManyDevicesResponse.active_devices = this.tooManyDevicesResponse.active_devices.filter(d => d !== device);
          this.cdr.markForCheck();
          this.toastr.success(this.translate.instant('too_many_devices.device.removed_success'));

          if (device.current_session) {
            this.auth.logout(false);
          }

          if (this.tooManyDevicesResponse.active_devices.length <= this.tooManyDevicesResponse.device_limit) {
            this.close.next()
            this.cdr.markForCheck();
          }
        },
        error: (error) => {
          this.log.error('delete device error', error);
          if (error.status === 403) {
            // signature invalid
            this.close.next()
            this.cdr.markForCheck();
          }
          this.toastr.error(this.translate.instant('too_many_devices.device.removed_error'));
        }
      }
    );
  }


  public clearAllDevices(): void {
    this.http.delete<void>(this.tooManyDevicesResponse.clear_all_devices_url).subscribe({
      next: () => {
        this.tooManyDevicesResponse.active_devices = [];
        this.cdr.markForCheck();
        this.toastr.success(this.translate.instant('too_many_devices.device.removed_all_success'));
        this.close.next()
        this.cdr.markForCheck();

      },
      error: (error) => {

        this.toastr.error(this.translate.instant('too_many_devices.device.removed_all_error'));
        if (error.status === 403) {
          // signature invalid
          this.close.next()
          this.cdr.markForCheck();
        }
      },
    });
  }

  public constructor(
    public localeService: LocaleService,
    public http: HttpClient,
    public toastr: ToastrService,
    public log: NGXLogger,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public cdr: ChangeDetectorRef,
  ) {
  }
}
