import {ChangeDetectionStrategy, Component, EventEmitter, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {AssetResource} from '../../../entities/asset.entity';
import {ASSET_FLAG} from '../../../../enums/ASSET_FLAG';

import {AnalyticsService} from '../../services/analytics.service';
import {ASSET_TYPE} from '../../../../enums/ASSET_TYPE';
import {EVENT_ACTION, EVENT_CATEGORY} from '../../../../types/gtm-options';
import {SeoMetaTagsService} from "../../services/seo-meta-tags.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareModalComponent implements OnInit {
  public title!: string;
  public text!: string;

  public closeEvent: EventEmitter<void> = new EventEmitter();

  public asset?: AssetResource;
  public assetFlags = ASSET_FLAG;
  public assetTypes = ASSET_TYPE;

  private readonly link!: string;

  public copy(): void {
    if (!this.asset) {
      return;
    }
    this.analyticsService.trackEvent(
      EVENT_CATEGORY.FEATURE,
      EVENT_ACTION.FEATURE_SHARE_COPY_LINK,
      this.analyticsService.getLabelForAsset(this.asset),
      1,
    );

    this.clipboardService.copy('https://sportdeutschland.tv/' + this.link);
    this.toastr.success(this.translate.instant('share_modal.copied'));
    this.closeEvent.next();
  }

  public email(): void {
    if (!this.asset) {
      return;
    }
    this.analyticsService.trackEvent(
      EVENT_CATEGORY.FEATURE,
      EVENT_ACTION.FEATURE_SHARE_EMAIL_CLICK,
      this.analyticsService.getLabelForAsset(this.asset),
      1,
    );


    const subjectVideo= this.translate.instant('share_modal.email_subject_video');
    const subjectPost= this.translate.instant('share_modal.email_subject_post');
    const bodyVideo= this.translate.instant('share_modal.email_body_video');
    const bodyPost= this.translate.instant('share_modal.email_body_post');

    window.location.href =
      'mailto:?subject=' +
      encodeURIComponent(
        this.asset?.type === this.assetTypes.IMAGE_POST ? subjectPost : subjectVideo) +
      '&body=' +
      encodeURIComponent(
        (this.asset?.type === this.assetTypes.IMAGE_POST ? bodyPost : bodyVideo)
        + 'https://sportdeutschland.tv/' + this.link
      );


    this.closeEvent.next();
  }

  public facebook(): void {
    if (!this.asset) {
      return;
    }
    this.analyticsService.trackEvent(
      EVENT_CATEGORY.FEATURE,
      EVENT_ACTION.FEATURE_SHARE_FACEBOOK_CLICK,
      this.analyticsService.getLabelForAsset(this.asset),
      1,
    );


    window.open(
      'https://www.facebook.com/sharer.php?u=' + encodeURIComponent('https://sportdeutschland.tv/' + this.link),
      '_blank',
      'noopener,resizable,scrollbars',
    );

    this.closeEvent.next();
  }

  public twitter(): void {
    if (!this.asset) {
      return;
    }
    this.analyticsService.trackEvent(
      EVENT_CATEGORY.FEATURE,
      EVENT_ACTION.FEATURE_SHARE_TWITTER_CLICK,
      this.analyticsService.getLabelForAsset(this.asset),
      1,
    );


    window.open(
      'https://twitter.com/intent/tweet?url=' + encodeURIComponent('https://sportdeutschland.tv/' + this.link),
      '_blank',
      'noopener,resizable,scrollbars',
    );

    this.closeEvent.next();
  }

  public ngOnInit(): void {
    this.seoMetaTagsService.updateMetaTags({
      indexFollow: false,
      canonicalURL: 'https://sportdeutschland.tv/' + this.asset?.profile.slug + '/' + this.asset?.slug,
    });
  }

  public constructor(
    private seoMetaTagsService: SeoMetaTagsService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    private clipboardService: ClipboardService,
  ) {
    const state = router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.title = state['title'];
      this.text = state['text'];
      this.link = state['link'];
      this.asset = state['asset'];
    }
  }
}
