import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NavigationStart, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {timer} from 'rxjs';

declare const COMMIT_REF: string;

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  private currentHash: string;

  private url: string;
  private updateHandled = false;

  private enableDebug = false;

  public initVersionCheck(frequency = 5 * 1000 * 60): void {
    this.currentHash = COMMIT_REF;

    timer(1000, frequency).subscribe(() => {
      this.checkVersion();
    });
  }

  private checkVersion(): void {

    this.log('check version');
    this.http
      .get<{
        version: string;
        version_update_method: 'notice' | 'none' | 'next_route' | 'force';
      }>(this.url)
      .subscribe(
        (response: { version: string; version_update_method: 'notice' | 'none' | 'next_route' | 'force' }) => {
          const hashChanged = this.currentHash !== response.version;

          const versionUpdateMethod = response.version_update_method;

          // If new version, do something
          if (hashChanged) {
            this.updateHandled = true;
            switch (versionUpdateMethod) {
              case 'force':
                this.log('Forcing update');
                window.location.reload();
                break;
              case 'none':
                return;
              case 'notice':
              case 'next_route':
              default:
                this.router.events.subscribe(routerEvent => {
                  if (routerEvent instanceof NavigationStart) {
                    const url = window.location.origin + routerEvent.url;
                    window.location.replace(url);
                  }
                });

                break;
            }
          }
        },
        () => {
          this.log('Failed to load version from server');
        },
      );
  }

  private log(message: string): void {
    if (this.enableDebug) {
      this.ngxLogger.log('[version check service] ' + message, {version: COMMIT_REF, current: this.currentHash});
    }
  }

  public constructor(
    private http: HttpClient,
    private router: Router,
    private ngxLogger: NGXLogger,
  ) {
    this.currentHash = COMMIT_REF;
    this.url = `${window.location.protocol}//${window.location.host}/assets/version_update.json`;

  }
}
