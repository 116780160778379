import {ASSET_TYPE} from '../enums/ASSET_TYPE';
import {ASSET_MONETIZATION} from '../enums/ASSET_MONETIZATION';
import {PROFILE_TYPE} from '../enums/PROFILE_TYPE';

export enum MATOMO_VALUES {
  TRUE = 'yes',
  FALSE = 'no',
  NOT_SET = 'not-set',
}

export enum PAGE_TYPE {
  HOME = 'home',
  LIVESTREAMS = 'livestreams',
  PROFILE = 'profile',
  TAG = 'tag',
  ASSET = 'asset',
  CREATOR = 'creator',
  MODAL = 'modal',
  NEWSFEED = 'meine-inhalte',
  SEARCH = 'search',
  REGISTER = 'register',
  LOGIN = 'login',
  ACCOUNT = 'account',
  ECOMMERCE = 'ecommerce',
  LEGAL = 'legal',
  OTHER = 'other',
}

export enum MATOMO_SITE_ID {
  PRODUCTION = '1',
  STAGE = '2',
  LOCAL = '5',
}

/**
 * GA LIMITS THE DIMENSION NAMES TO 24 Characters
 */
export const DIMENSIONS: Record<keyof AllMatomoDimensions, number> = {
  VISIT_USER_LOGGED_IN: 1,
  VISIT_MARKETABLE: 2,
  VISIT_EMBEDDED: 3,
  VISIT_USER_HAS_SDTV_PLUS: 4,
  VISIT_ALL_CONSENTS_GIVEN: 5,
  VISIT_USER_PROFILE_TYPE: 6,
  VISIT_SPORT_TYPE_NAME: 7,
  VISIT_ADBLOCKER_DETECTED: 8,
  VISIT_EMBEDDED_ORIGIN: 9,
  ACTION_SPORT_TYPE_NAME: 10,
  ACTION_PROFILE_NAME: 11,
  ACTION_PROFILE_ID: 12,
  ACTION_LEAGUE_NAME: 13,
  ACTION_LEAGUE_ID: 14,
  ACTION_ASSOCIATION_NAME: 15,
  ACTION_AD_BLOCK: 16,
  ACTION_AD_BLOCK_COUNT: 17,
  ACTION_ADBLOCKER_DETECTED: 18,
  ACTION_USER_LOGGED_IN: 19,
  ACTION_EMBEDDED: 20,
  ACTION_EMBEDDED_ORIGIN: 21,
  ACTION_USER_HAS_SDTV_PLUS: 22,
  ACTION_SPORT_TYPE_ID: 23,
  ACTION_TAG_NAME: 24,
  ACTION_TAG_ID: 25,
  ACTION_PAGE_TYPE: 26,
  ACTION_ASSET_NAME: 27,
  ACTION_ASSET_ID: 28,
  ACTION_ASSET_TYPE: 29,
  ACTION_ASSET_MARKETABLE: 30,
  ACTION_HOME_TEAM_NAME: 31,
  ACTION_GUEST_TEAM_NAME: 32,
  ACTION_ASSET_NEEDS_PAY: 33,
  ACTION_USED_MONETIZATION: 34,
  ACTION_PRODUCTION_PARTNER: 35,
  ACTION_LICENCE_OWNER: 36,
  ACTION_HOME_TEAM_ID: 37,
  ACTION_GUEST_TEAM_ID: 38,
  ACTION_ASSET_DURATION: 39,
  ACTION_ASSET_HAS_PPV: 40,
  ACTION_ASSET_HAS_PASS: 41,
  ACTION_ASSET_HAS_ABO: 42,
  ACTION_USED_PRODUCT_NAME: 43,
  ACTION_USED_PRODUCT_ID: 44,
  ACTION_ASSET_VENUE_STATE: 45,
  ACTION_ASSET_VENUE_CITY: 46,
  ACTION_ASSET_TAGS_CSV: 47,
  ACTION_AD_POS_IN_BLOCK: 48,
  ACTION_LIFECYCLE_ID: 49,
  ACTION_FRONTEND_VERSION: 50,
  VISIT_TID: 51,
  ACTION_ASSOCIATION_ID: 52,
  ACTION_CLUB_ID: 53,
  VISIT_SOFTWARE: 54,
};
export type GoogleAnalyticsDimensions = GoogleAnalyticsDimensionsPrivate &
  Impossible<Exclude<keyof GoogleAnalyticsDimensionsPrivate, keyof GoogleAnalyticsDimensionsPrivate>>;

type GoogleAnalyticsDimensionsPrivate = {
  D001_USER_LOGGED_IN: string | undefined | number;
  D002_MARKETABLE: string | undefined | number;
  D003_EMBEDDED: string | undefined | number;
  D004_USER_HAS_SDTV_PLUS: string | undefined | number;
  D005_ALL_CONSENTS_GIVEN: string | undefined | number;
  D006_USER_PROFILE_TYPE: string | undefined | number;
  D007_SPORT_TYPE_NAME: string | undefined | number;
  D008_ADBLOCKER_DETECTED: string | undefined | number;
  D009_EMBEDDED_ORIGIN: string | undefined | number;
  D010_SPORT_TYPE_NAME: string | undefined | number;
  D011_PROFILE_NAME: string | undefined | number;
  D012_PROFILE_ID: string | undefined | number;
  D013_LEAGUE_NAME: string | undefined | number;
  D014_LEAGUE_ID: string | undefined | number;
  D015_ASSOCIATION_NAME: string | undefined | number;
  D016_AD_BLOCK: string | undefined | number;
  D017_AD_BLOCK_COUNT: string | undefined | number;
  D018_ADBLOCKER_DETECTED: string | undefined | number;
  D019_USER_LOGGED_IN: string | undefined | number;
  D020_EMBEDDED: string | undefined | number;
  D021_EMBEDDED_ORIGIN: string | undefined | number;
  D022_USER_HAS_SDTV_PLUS: string | undefined | number;
  D023_SPORT_TYPE_ID: string | undefined | number;
  D024_TAG_NAME: string | undefined | number;
  D025_TAG_ID: string | undefined | number;
  D026_PAGE_TYPE: string | undefined | number;
  D027_ASSET_NAME: string | undefined | number;
  D028_ASSET_ID: string | undefined | number;
  D029_ASSET_TYPE: string | undefined | number;
  D030_ASSET_MARKETABLE: string | undefined | number;
  D031_HOME_TEAM_NAME: string | undefined | number;
  D032_GUEST_TEAM_NAME: string | undefined | number;
  D033_ASSET_NEEDS_PAY: string | undefined | number;
  D034_USED_MONETIZATION: string | undefined | number;
  D035_PRODUCTION_PARTNER: string | undefined | number;
  D036_LICENCE_OWNER: string | undefined | number;
  D037_HOME_TEAM_ID: string | undefined | number;
  D038_GUEST_TEAM_ID: string | undefined | number;
  D039_ASSET_DURATION: string | undefined | number;
  D040_ASSET_HAS_PPV: string | undefined | number;
  D041_ASSET_HAS_PASS: string | undefined | number;
  D042_ASSET_HAS_ABO: string | undefined | number;
  D043_USED_PRODUCT_NAME: string | undefined | number;
  D044_USED_PRODUCT_ID: string | undefined | number;
  D045_ASSET_VENUE_STATE: string | undefined | number;
  D046_ASSET_VENUE_CITY: string | undefined | number;
  D047_ASSET_TAGS_CSV: string | undefined | number;
  D048_AD_POS_IN_BLOCK: string | undefined | number;
  D049_LIFECYCLE_ID: string | undefined | number;
  D050_FRONTEND_VERSION: string | undefined | number;
  D051_TID: string | undefined | number;
  D052_ACTION_ASSOCIATION_ID: string | undefined | number;
  D053_ACTION_CLUB_ID: string | undefined | number;
  D054_VISIT_SOFTWARE: string | undefined | number;
};
type MatomoString = string | MATOMO_VALUES.NOT_SET;

export type GlobalMatomoDimensions = GlobalMatomoDimensionsDefinition &
  Impossible<Exclude<keyof GlobalMatomoDimensionsDefinition, keyof GlobalMatomoDimensionsDefinition>>;
type GlobalMatomoDimensionsDefinition = {
  // visit based
  VISIT_MARKETABLE: MATOMO_VALUES;
  VISIT_USER_LOGGED_IN: MATOMO_VALUES;
  VISIT_ALL_CONSENTS_GIVEN: MATOMO_VALUES;
  VISIT_EMBEDDED: MATOMO_VALUES;
  VISIT_USER_PROFILE_TYPE: PROFILE_TYPE | MATOMO_VALUES.NOT_SET;

  VISIT_USER_HAS_SDTV_PLUS: MATOMO_VALUES;
  VISIT_ADBLOCKER_DETECTED: MATOMO_VALUES;
  VISIT_EMBEDDED_ORIGIN: MatomoString;

  // action based
  ACTION_ADBLOCKER_DETECTED: MATOMO_VALUES;
  ACTION_USER_LOGGED_IN: MATOMO_VALUES;
  ACTION_EMBEDDED: MATOMO_VALUES;
  ACTION_EMBEDDED_ORIGIN: MatomoString;
  ACTION_USER_HAS_SDTV_PLUS: MATOMO_VALUES;
  ACTION_FRONTEND_VERSION: string;

  ACTION_PAGE_TYPE: PAGE_TYPE;
};
export type AllMatomoDimensions = (GlobalMatomoDimensions & MatomoDimensions) &
  Impossible<Exclude<keyof (GlobalMatomoDimensions & MatomoDimensions), keyof (GlobalMatomoDimensions & MatomoDimensions)>>;

// First, define a type that, when passed a union of keys, creates an object which
// cannot have those properties. I couldn't find a way to use this type directly,
// but it can be used with the below type.
export type Impossible<K extends keyof never> = {
  [P in K]: never;
};

// The secret sauce! Provide it the type that contains only the properties you want,
// and then a type that extends that type, based on what the caller provided
// using generics.
export type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;

export type MatomoDimensions = UnLimitedDimensions & Impossible<Exclude<keyof UnLimitedDimensions, keyof UnLimitedDimensions>>;
type UnLimitedDimensions = {
  VISIT_SPORT_TYPE_NAME?: MatomoString;
  ACTION_SPORT_TYPE_NAME?: MatomoString;
  ACTION_SPORT_TYPE_ID?: UUID;
  ACTION_PROFILE_NAME?: MatomoString;
  ACTION_PROFILE_ID?: UUID;
  ACTION_LEAGUE_NAME?: MatomoString;
  ACTION_LEAGUE_ID?: UUID;
  ACTION_ASSOCIATION_NAME?: MatomoString;
  ACTION_TAG_NAME?: MatomoString;
  ACTION_TAG_ID?: UUID;
  ACTION_ASSET_NAME?: MatomoString;
  ACTION_ASSET_ID?: UUID;
  ACTION_ASSET_TYPE?: ASSET_TYPE;
  ACTION_ASSET_MARKETABLE?: MATOMO_VALUES;
  ACTION_PRODUCTION_PARTNER?: MatomoString;
  ACTION_LICENCE_OWNER?: MatomoString;
  ACTION_HOME_TEAM_NAME?: MatomoString;
  ACTION_HOME_TEAM_ID?: UUID;
  ACTION_GUEST_TEAM_NAME?: MatomoString;
  ACTION_GUEST_TEAM_ID?: UUID;
  ACTION_ASSET_DURATION?: number;
  ACTION_ASSET_NEEDS_PAY?: MATOMO_VALUES;
  ACTION_ASSET_HAS_PPV?: MATOMO_VALUES;
  ACTION_ASSET_HAS_PASS?: MATOMO_VALUES;
  ACTION_ASSET_HAS_ABO?: MATOMO_VALUES;
  ACTION_USED_MONETIZATION?: ASSET_MONETIZATION | MATOMO_VALUES.NOT_SET;
  ACTION_USED_PRODUCT_NAME?: MatomoString;
  ACTION_USED_PRODUCT_ID?: UUID;
  ACTION_ASSET_VENUE_STATE?: MatomoString;
  ACTION_ASSET_VENUE_CITY?: MatomoString;
  ACTION_ASSET_TAGS_CSV?: MatomoString;
  ACTION_AD_BLOCK?: 'pre' | 'post' | 'mid';
  ACTION_AD_BLOCK_COUNT?: number;
  ACTION_AD_POS_IN_BLOCK?: number;
  ACTION_LIFECYCLE_ID?: string;
  VISIT_USER_LOGGED_IN?: MATOMO_VALUES; //1
  VISIT_MARKETABLE?: MATOMO_VALUES; // 2
  VISIT_EMBEDDED?: MATOMO_VALUES; //3
  VISIT_USER_HAS_SDTV_PLUS?: MATOMO_VALUES;
  VISIT_TID?: MATOMO_VALUES;
  ACTION_ASSOCIATION_ID?: UUID;
  ACTION_CLUB_ID?: UUID;
  VISIT_SOFTWARE?: "web";
};

export enum EVENT_CATEGORY {
  VIDEO = 'Video',
  NAVIGATION = 'Navigation',
  FEATURE = 'Feature',
  CMP = 'CMP',
  ECOMMERCE = 'Ecommerce',
  REGISTRATION = 'Registration',
  IMPRESSION = 'Impression',
  ADS = 'Ads',
  SEARCH = 'Search',
}

export enum EVENT_ACTION {
  NAVIGATION_ASSET_CLICK = 'Asset Click',
  NAVIGATION_HEADLINE_CLICK = 'Headline Click',
  NAVIGATION_CLICK = 'Navigation Click',
  NAVIGATION_PROFILE_IMPRINT_CLICK = 'Profile Imprint Click',
  NAVIGATION_PROFILE_EDIT_CLICK = 'Profile Edit Click',
  NAVIGATION_SIDEBAR_CLICK = 'Navigation Sidebar Click',
  NAVIGATION_HEADER_CLICK = 'Navigation Header Click',
  MORE_BUTTON_CLICK = 'More Button Click',
  NAVIGATION_PROFILE_CLICK = 'Profile Click',
  NAVIGATION_TAG_CLICK = 'Tag Click',
  NAVIGATION_FOOTER_CLICK = 'Navigation Footer Click',

  SEARCH_CLICK = 'Search Click',
  SEARCH_RESULT_CLICK = 'Search Result Click',
  SEARCH_AUTOCOMPLETE_CLICK = 'Search Autocomplete Click',

  LOGIN = 'Login',
  LOGOUT = 'Logout',

  FEATURE_OPEN_SHARE_MODAL_CLICK = 'Open Share Modal Click',
  FEATURE_SHARE_COPY_LINK = 'Share Copy Link',
  FEATURE_SHARE_EMAIL_CLICK = 'Share Email Click',
  FEATURE_SHARE_FACEBOOK_CLICK = 'Share Facebook Click',
  FEATURE_SHARE_TWITTER_CLICK = 'Share Twitter Click',

  FEATURE_LIKE_CLICK = 'Like Click',
  FEATURE_UNLIKE_CLICK = 'Unlike Click',
  FEATURE_FOLLOW_PROFILE_CLICK = 'Follow Profile Click',
  FEATURE_UNFOLLOW_PROFILE_CLICK = 'Unfollow Profile Click',
  FEATURE_FOLLOW_TAG_CLICK = 'Follow Tag Click',
  FEATURE_UNFOLLOW_TAG_CLICK = 'Unfollow Tag Click',

  REGISTRATION_OPEN = 'Registration Open',
  REGISTRATION_START = 'Registration Start',
  REGISTRATION_STEP_2 = 'Registration Step2',
  REGISTRATION_FIRST_OPT_IN = 'Registration First OptIn',
  REGISTRATION_SUBMITTED = 'Registration Submitted',
  REGISTRATION_DOUBLE_OPT_IN = 'Registration Double OptIn',

  GOTHAER_LIVE_STREAM_TABLE_IMPRESSION = 'Gothaer Livestream Table Impression',
  GOTHAER_SPONSORED_TILE_IMPRESSION = 'SponsoredTileImpression',
  GOTHAER_SIDEBAR_IMPRESSION = 'Gothaer Sidebar Impression',
  GOTHAER_FIREPLACE_DISPLAY_IMPRESSION = 'Gothaer Fireplace Display Impression',
  GOTHAER_FIREPLACE_CLICK_IMPRESSION = 'Gothaer Fireplace Click Impression',

  CHECKOUT_SUCCESS = 'Checkout Success',
  ECOMMERCE_ORDER_SUCCESS = 'Order Success',

  IMPRESSION_ASSET_TILE_IMPRESSION = 'Asset Tile Impression',
  IMPRESSION_ASSET_PAGE_IMPRESSION = 'Asset Page Impression',
  IMPRESSION_PROFILE_TILE_IMPRESSION = 'Profile Tile Impression',
  IMPRESSION_PROFILE_PAGE_IMPRESSION = 'Profile Page Impression',
  IMPRESSION_TAG_PAGE_IMPRESSION = 'Tag Page Impression',

  VIDEO_INIT = 'Init',
  VIDEO_VIEW_MARKETABLE = 'Video View Marketable',
  VIDEO_VIEW = 'Video View',
  VIDEO_VIEW_MUTED_AUTOPLAY = 'Muted Autoplay Video View ',
  VIDEO_COMPLETED = 'Video Completed',
  VIDEO_LIFECYCLE_ABORT = 'Lifecycle Abort',

  VIDEO_PLAYER_ERROR = 'Player Error', // Send Player_STATE and PLAYER ERROR in event

  AD_START = 'Ad Start',

  AD_BLOCK_EMPTY = 'Ad Block Empty',

  AD_PROFILE_SPONSOR_IMAGE_CLICK = 'Profile Sponsor Image Clicked',
  PROFILE_SPONSOR_IMAGE_IMPRESSION = 'Profile Sponsor Image Impression',
  PROFILE_POWERED_BY_IMAGE_IMPRESSION = 'Profile Powered by Image Impression',

  CMP_INIT_NO_DECISION_MADE = 'CMP Init No Decision Made',
  CMP_INIT_ALL_ACCEPTED = 'CMP Init All Accepted',
  CMP_INIT_NOT_ALL_ACCEPTED = 'CMP Init Not All Accepted',
}
