import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Device, TOO_MANY_DEVICES_REASON} from "../too-many-devices.response";
import {LocaleService} from "../../../services/locale.service";

@Component({
  selector: 'app-device-list[devices]',
  templateUrl: './device-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceListComponent {

  @Input() public devices: Device[] = [];
  @Input() public reason?: TOO_MANY_DEVICES_REASON;
  public readonly TOO_MANY_DEVICES_REASON = TOO_MANY_DEVICES_REASON;
  @Output() public deleteDevice: EventEmitter<Device> = new EventEmitter<Device>();

  public constructor(public localeService: LocaleService) {

  }


}
