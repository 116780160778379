import {Pipe, PipeTransform} from '@angular/core';
import {ProfileEditingResource, ProfilePageResource} from '../../entities/Profile.entity';
import {ImageCdnService} from '../../shared/services/image-cdn.service';

export enum PROFILE_SPONSOR_IMAGE_SIZE {
  XL_2560_701 = '2560x701',
}

@Pipe({
  name: 'profileSponsorImage',
})
export class ProfileSponsorImageSizePipe implements PipeTransform {
  public transform(
    value: ProfilePageResource | ProfileEditingResource | undefined,
    args: { size: PROFILE_SPONSOR_IMAGE_SIZE; imageError?: string } = {
      size: PROFILE_SPONSOR_IMAGE_SIZE.XL_2560_701,
      imageError: '',
    },
  ): string {
    if (!value || !value.sponsor_image_id || value.sponsor_image_id.startsWith('http')) {
      return args.imageError || '';
    }

    return this.imageCdnService.getImageFqdn(value.sponsor_image_id, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {}
}
