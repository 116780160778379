import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener} from '@angular/core';
import {NgbActiveOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {SidebarAndHeaderService} from "../../services/sidebar-and-header.service";
import {NavigationStart, Router} from "@angular/router";
import {PROFILE_TYPE} from "../../../../enums/PROFILE_TYPE";
import {AuthenticationService} from "../../services/authentication.service";
import {ProfileEditingResource, ProfilePageResource, ProfileReducedResource} from "../../../entities/Profile.entity";
import {CurrentlyLiveService} from "../../services/currently-live.service";
import {TagForMenuResource, TagReducedResource, TagResource} from "../../../entities/Tag.entity";
import {TopProfilesService} from "../../services/api/services/top-profiles.service";
import {AllSporttypeTagsService} from "../../services/api/services/menu/all-sporttype-tags.service";
import {ProfilesForProfilesService} from "../../services/api/services/menu/profiles-for-profiles.service";
import {ProfilesForSportTypeTagService} from "../../services/api/services/menu/profiles-for-sport-type-tag.service";
import {HttpClient} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {distinctUntilChanged, filter} from "rxjs/operators";
import {PROFILE_IMAGE_SIZE} from "../../../../enums/PROFILE_IMAGE_SIZE";
import {ConsentService} from "../../services/consent.service";

export enum SIDEBAR_MENU {
  TOP = 'TOP',
  ALL_SPORT_TYPES = 'ALL_SPORT_TYPES',
  TOP_PROFILES = 'TOP_PROFILES',
  PROFILE = 'PROFILE',
  SPORT_TYPE_TAG = 'SPORT_TYPE_TAG',
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarComponent {

  public readonly PROFILE_TYPE = PROFILE_TYPE;
  public currentUserProfile: ProfileEditingResource | undefined;
  public loggedIn?: boolean;
  public numberOfCurrentLivestreams?: number;

  public isHandballNet = false;

  public shownMenu?: SIDEBAR_MENU;
  public sportTypeTag?: TagReducedResource;
  public profile: ProfileReducedResource | undefined;
  protected readonly MENU = SIDEBAR_MENU;
  protected readonly PROFILE_IMAGE_SIZE = PROFILE_IMAGE_SIZE;

  public openTopProfiles(): void {
    this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.TOP_PROFILES)
  }

  public openTop(): void {
    this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.TOP)
  }

  public showMenuForProfileId(id: UUID): void {

    this.http.get<ProfilePageResource>('/stateless/frontend/profile/' + id).subscribe(
      (p) => {
        this.log.warn('❌ sidebar showMenuForProfileId', p)
        this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.PROFILE, p)
      }
    )

  }

  public openParentProfileMenu(profile: ProfileReducedResource | undefined): void {

    if (profile?.club_id) {
      this.log.log('going to parent club', profile)
      this.showMenuForProfileId(profile.club_id)

    } else if (profile?.league_id) {
      this.log.log('going to parent league', profile)
      this.showMenuForProfileId(profile.league_id)

    } else if (profile?.association_id) {
      this.log.log('going to parent league', profile)
      this.showMenuForProfileId(profile.association_id)
    } else if (profile?.sport_type) {
      this.log.log('going to parent sport_type', profile)
      this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.SPORT_TYPE_TAG, undefined, profile.sport_type)
    } else {
      this.log.error('❌ missing sport type??? going to parent all_sport_types')
      this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.ALL_SPORT_TYPES)
    }
  }

  public showCMP(): void {
    this.close();
    this.consentService.showCMP();
  }

  public openAllSportTypes(): void {
    this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.ALL_SPORT_TYPES)
  }

  public openSportTypeTag(tag: TagForMenuResource): void {
    this.http.get<TagResource>('/stateless/frontend/tags/' + tag.slug).subscribe(
      (t) => {
        this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.SPORT_TYPE_TAG, undefined, t)
      }
    )

  }

  @HostListener('window:resize')
  public windowResize(): void {
    this.close()
  }

  public close(): void {
    this.offcanvas.dismiss();
  }

  public constructor(
    public currentlyLiveService: CurrentlyLiveService,
    public topProfilesService: TopProfilesService,
    public allSportTypesService: AllSporttypeTagsService,
    public profilesForProfilesService: ProfilesForProfilesService,
    public profilesForSportTypeTagService: ProfilesForSportTypeTagService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public offcanvas: NgbActiveOffcanvas,
    private sidebarAndHeaderService: SidebarAndHeaderService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private log: NGXLogger,
    private consentService: ConsentService,
  ) {

    // hide the sidebar when clicking on a link
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart)
    ).subscribe(
      () => this.close()
    )


    this.loggedIn = this.authenticationService.loggedIn$.getValue();
    this.authenticationService.loggedIn$.subscribe(
      (l) => {
        this.loggedIn = l
        this.cdr.markForCheck()
      }
    )
    this.authenticationService.profile$.subscribe(
      (p) => {
        this.currentUserProfile = p
        this.cdr.markForCheck()
      }
    )

    this.currentlyLiveService.numberOfCurrentLivestreams.subscribe(
      (d) => {
        this.numberOfCurrentLivestreams = d
        this.cdr.markForCheck()
      }
    )

    this.sidebarAndHeaderService.isHandballNet.subscribe(
      (is) => {
        this.isHandballNet = is;
        this.cdr.markForCheck()
      }
    );

    this.sidebarAndHeaderService.sidebarMenu$.subscribe(
      (l) => {
        this.shownMenu = l.menu;
        this.sportTypeTag = l.tag;
        this.profile = l.profile;

        if (l.tag) {
          this.profilesForSportTypeTagService.reset();
          this.profilesForSportTypeTagService.setUrl('/stateless/frontend/menu/sport-types/' + l.tag.id)
          this.profilesForSportTypeTagService.load(this.profilesForSportTypeTagService.chunkSize);
        }
        if (l.profile) {

          let url = '';
          switch (l.profile.type) {
            case PROFILE_TYPE.ASSOCIATION:
              url = '/stateless/frontend/menu/associations/'
              break
            case PROFILE_TYPE.LEAGUE:
              url = '/stateless/frontend/menu/leagues/'
              break
            case PROFILE_TYPE.CLUB:
              url = '/stateless/frontend/menu/clubs/'
              break
            case PROFILE_TYPE.EVENT:
            case PROFILE_TYPE.ATHLETE:

              if (l.profile.club_id) {
                this.showMenuForProfileId(l.profile.club_id)
                return

              } else if (l.profile.league_id) {
                this.showMenuForProfileId(l.profile.league_id)

              } else if (l.profile.association_id) {
                this.showMenuForProfileId(l.profile.association_id)
              } else {
                // zeige alle anderen profile der sportart
                this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.SPORT_TYPE_TAG, undefined, l.profile.sport_type)
                return;
              }
              break;
            case PROFILE_TYPE.VIEWER:
              // just in case
              this.profile = undefined;
              this.log.error('❌ VIEWER MENU!!!')
              this.sidebarAndHeaderService.configureSidebar(SIDEBAR_MENU.TOP,)
              return;
          }

          this.profilesForProfilesService.setUrl(url + l.profile.id)
          this.profilesForProfilesService.load(this.allSportTypesService.chunkSize);

        }

        this.cdr.markForCheck()
      }
    )


    this.profilesForSportTypeTagService.total$.pipe(
      filter(amount => amount !== undefined),
      distinctUntilChanged()
    )
      .subscribe(
        (total) => {
          if (total === 0) {
            this.log.warn('❌ NO profiles found for tag => opening all SportTypes', {total, profile: this.profile})
            this.openAllSportTypes()
          }

        }
      )


    this.profilesForProfilesService.total$.pipe(
      filter(amount => amount !== undefined),
      distinctUntilChanged()
    )
      .subscribe(
        (total) => {
          if (total === 0) {
            this.log.warn('❌ NO profiles found for menu => open parent profile', {total, profile: this.profile})
            this.openParentProfileMenu(this.profile)
          }

        }
      )


  }
}
