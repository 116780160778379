import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpCacheService} from './http-cache.service';
import {tap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';

/**
 * This interceptor caches requests
 */
@Injectable()
export class RequestCacheInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // pass along non-cacheable requests and invalidate cache
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    // attempt to retrieve a cached response
    const cachedResponse: HttpResponse<unknown> | void = this.cacheService.get(req);

    // return cached response
    if (cachedResponse && cachedResponse.url) {
      this.log.log('[req cache intercept] returning response from cache');
      return of(cachedResponse);
    }

    // send request to server and add response to cache
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cacheService.put(req, event);
        }
      }),
    );
  }
  public constructor(private cacheService: HttpCacheService, private log: NGXLogger) {}
}
