<div
  #staticModal="bs-modal"
  (onHidden)="onHidden()"
  [config]="{
    show: true,
    backdrop: this.backdrop,
    ignoreBackdropClick: !this.closeOnBackdropClick,
    animated: false,
    keyboard: this.closeOnEsc,
    ariaLabelledBy: this.title,
    focus: false
  }"
  bsModal
  class="modal"
>
  <div [class]="this.class + ' modal-' + this.size" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="showModalHeader">
        <h1 class="modal-title"><ng-content select="[modal-header]"></ng-content></h1>
        <button (click)="staticModal.hide()" aria-label="Schließen" class="btn btn-close btn text-white"  type="button"
        >

        </button>
      </div>
      <div class="modal-body pb-5">
        <ng-content select="[modal-body]"></ng-content>
      </div>
      <div class="modal-footer" *ngIf="showModalFooter">
        <ng-content select="[modal-footer]"></ng-content>
      </div>

    </div>
  </div>
</div>
