import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'durationFormat',
  pure: false,
})
export class DurationFormatPipe implements PipeTransform {
  public transform(value: number | null): string {
    if (value === null) {
      return '';
    }
    if (value < 0) {
      return '';
    }
    const seconds = Math.floor(value % 60);
    const minutes = Math.floor((value / 60) % 60);
    const hours = Math.floor((value / 60 / 60) % 24);
    const days = Math.floor((value / 60 / 60 / 24) % 365);
    const years = Math.floor(value / 60 / 60 / 24 / 365);

    if (years > 1) {
      return `${years} Jahre ${days} Tage ${hours}h`;
    }

    if (years > 0) {
      return `${years} Jahr ${days} Tage ${hours}h`;
    }

    if (days > 1) {
      return `${days} Tage, ${hours}h`;
    }

    if (days > 0) {
      return `${days} Tag, ${hours}h`;
    }

    if (hours > 0) {
      return `${hours}h,  ${minutes}m`;
    }

    if (minutes > 1) {
      return `${minutes + 1} Minuten`;
    }
    if (minutes > 0) {
      return `${minutes + 1} Minute`;
    }

    return `${seconds} Sek.`;
  }
}
