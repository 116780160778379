import {HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import parseCacheControl from 'parse-cache-control';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
  private cache: { [key: string]: HttpResponse<unknown> } = {};

  public get(req: HttpRequest<unknown>): HttpResponse<unknown> | void {
    if (req.method !== 'GET') {
      return undefined;
    }
    return this.cache[req.method + req.urlWithParams];
  }

  public put(req: HttpRequest<unknown>, resp: HttpResponse<unknown>): void {
    if (req.method !== 'GET') {
      return;
    }
    // check if caching is allowed! and how long
    this.log.log('[request cache]: check for url' + req.urlWithParams);
    const headerString = resp.headers.get('Cache-Control');
    if (headerString) {
      const headers = parseCacheControl(headerString);

      if (headers && typeof headers['max-age'] === 'number') {
        // cache only if max age header is set

        this.log.log('[request cache]: putting to cache - response is cacheable for ' + headers['max-age']);
        this.cache[req.method + req.urlWithParams] = resp;

        setTimeout(() => {
          delete this.cache[req.method + req.urlWithParams];
          this.log.debug('[request cache] removing item from cache: ' + req.method + req.urlWithParams);
        }, 1000 * headers['max-age']);
      } else {
        this.log.log('[request cache]: did not put response into cache for URL ' + req.url);
      }
    }

    // Cache-Control: must-revalidate
    // Cache-Control: no-cache
    // Cache-Control: no-store
    // Cache-Control: no-transform
    // Cache-Control: public
    // Cache-Control: private
    // Cache-Control: proxy-revalidate
    // Cache-Control: max-age=<seconds>
    // Cache-Control: s-maxage=<seconds>
  }

  public deleteCache(): void {
    this.cache = {};
  }

  public constructor(private log: NGXLogger) {}
}
