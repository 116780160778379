<app-modal
  #modal
  [backdrop]="true"
  [closeEvent]="closeEvent"
  [closeOnBackdropClick]="true"
  [closeOnEsc]="true"
  [showModalFooter]="false"
  class=""
  id="share-modal"
  size="md"
>
  <div modal-header>{{ title }}</div>
  <div modal-body>
    <div class="row">
      <div class="col-12">
        {{ text }}
      </div>
    </div>


    <div class="btn-group w-100">
      <button (click)="copy()" [attr.aria-label]="'share_modal.copy_link'|translate" class="btn btn-secondary px-1">
        <i class=" text-white mt-1 fa fa-copy "></i>
        <br>{{'share_modal.copy'|translate}}
      </button>

      <button (click)="email()" [attr.aria-label]="'Per Email versenden'" class="btn btn-secondary px-1">
        <i class=" text-white mt-1 fa fa-mail-bulk"></i>
        <br>{{'share_modal.email'|translate}}
      </button>

      <ng-container *ngIf="asset && !asset.flags.includes(assetFlags.NO_SHARE)">
        <button (click)="facebook()" [attr.aria-label]="'share_modal.share_on_facebook'|translate" class="btn btn-secondary px-1">
          <i class=" text-white mt-1 fa fa-facebook"></i>
          <br>{{'share_modal.facebook'|translate}}
        </button>
        <button (click)="twitter()" [attr.aria-label]="'share_modal.share_on_twitter'|translate"  class="btn btn-secondary px-1">
          <i class=" text-white mt-1 fa fa-twitter"></i>
          <br>{{'share_modal.twitter'|translate}}
        </button>
      </ng-container>

      <button
        *ngIf="asset && asset.type !== assetTypes.IMAGE_POST && !asset.flags.includes(assetFlags.NO_EMBED)"
        [routerLink]="['/', { outlets: { m: 'embed' } }]"
        [state]="{
    asset: asset
  }"
        [attr.aria-label]="'share_modal.embed_on_website'|translate"
        class="btn btn-secondary"
      >
        <i class=" text-white mt-1 fa fa-code"></i>
        <br>{{'share_modal.embed'|translate}}
      </button>


    </div>
  </div>
</app-modal>
