import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {NGXLogger} from 'ngx-logger';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdBlockerDetectionService {
  private hasAdBlocker$: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);

  public hasAdBlocker(): Observable<boolean | undefined> {
    return this.hasAdBlocker$.pipe(
      map((v: boolean | undefined) => {
        if (AD_LOADING_ERROR === true) {
          return true;
        }
        return v;
      }),
    );
  }

  public adBlockerDetected(detected = true): void {
    this.hasAdBlocker$.next(detected);
  }

  public constructor(@Inject(DOCUMENT) private document: Document, private log: NGXLogger) {
    if (AD_LOADING_ERROR === true) {
      this.hasAdBlocker$.next(true);
    }

    if (document) {
      const head = document.getElementsByTagName('head')[0];
      const noAdBlocker = (): void => {
        this.hasAdBlocker$.next(false);
        document.getElementById('important-ad-script')?.remove();
      };
      const hasAdBlocker = (): void => {
        this.log.log('adblocker detected');
        this.hasAdBlocker$.next(true);
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src =
        '/assets/ads.js?&ad_number=1' +
        '&ad_slot=2' +
        '&ad_sub=3' +
        '&ad_time=' +
        '&ad_type=' +
        '&ad_type_' +
        '&ad_url=' +
        '&ad_zones=' +
        '&adbannerid=' +
        '&adclient=' +
        '&adcount=' +
        '&adflag=' +
        '&adgroupid=' +
        '&adlist=' +
        '&admeld_' +
        '&admid=$~subdocument' +
        '&adname=' +
        '&adnet=' +
        '&adnum=' +
        '&adpageurl=' +
        '&Ads_DFP=' +
        '&adsafe=' +
        '&adserv=' +
        '&adserver=' +
        '&adslot=' +
        '&adslots=' +
        '&adsourceid=' +
        '&adspace=' +
        '&adsrc=' +
        '&adstra=';
      script.id = 'important-ad-script';
      script.onload = noAdBlocker;
      script.onerror = hasAdBlocker;
      head.appendChild(script);
    }
  }
}
