import {Injectable, Injector} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, delay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {HttpCacheService} from './http-cache.service';
import {CookieService} from 'ngx-cookie-service';
import {TooManyDevicesEventService} from "../too-many-devices.event.service";
import {AuthenticationEventService} from "../authentication.event.service";

/**
 * This interceptor adds the correct API endpoint to each request.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private readonly http: HttpClient;
  private log: NGXLogger;
  private httpCacheService: HttpCacheService;
  private cookieService: CookieService;
  private tooManyDevicesEventService: TooManyDevicesEventService;
  private authEventService: AuthenticationEventService


  private csrfRetries = 0;

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const handleRequest = (request: HttpRequest<unknown>, handler: HttpHandler): Observable<HttpEvent<unknown>> => {
      return handler.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 419) {
            // csrf token mismatch
            this.cookieService.delete('XSRF-TOKEN', '/', environment.api.session_domain, true, 'Lax');
            return this.http.get('/web/personal/csrf', {observe: 'response'}).pipe(
              delay(1000),
              tap(() => {
                this.csrfRetries++
                this.log.warn('retrying request after fetching csrf token', this.csrfRetries);
              }),

              switchMap(r => {
                this.log.warn('response from token', r);
                const newToken = this.cookieService.get('XSRF-TOKEN');
                request = request.clone({headers: request.headers.set('X-XSRF-TOKEN', newToken)});
                // retry once
                return handleRequest(request, handler);
              }),
            );
          } else if (error.status === 409) {
            this.tooManyDevicesEventService.tooManyDevicesResponseErrorSubject.emit(error.error);
            return this.tooManyDevicesEventService.tooManyDevicesResponseErrorMitigated.pipe(
              take(1),
              takeUntil(this.tooManyDevicesEventService.tooManyDevicesResponseErrorCancelled),
              switchMap(() => handleRequest(request, handler))
            );
          } else if (error.status === 401) {
            // log user out
            this.authEventService.notAuthorizedErrorEmitted.emit();

          }
          return throwError(() => error);
        }),
        tap(() => {
          this.csrfRetries = 0;
        })
      )
        ;
    };

    if (req.url.includes(`https://${window.location.host}`)) {
      return next.handle(req);
    }

    // do not modify requests for translation files
    if (req.url.includes('i18n')) {
      return next.handle(req);
    }
    // do not modify requests for other domains
    if (req.url.startsWith('http') && !req.url.startsWith(environment.api.url)) {
      return next.handle(req);
    } else if (!req.url.startsWith('http')) {
      req = req.clone({
        url: environment.api.url + req.url,
      });
    }
    req = req.clone({
      withCredentials: true,
    });

    // fetch csrf token if cookie not present!
    if (!document.cookie.includes('XSRF-TOKEN') && !req.url.includes('/web/personal/csrf') && req.method !== 'GET') {
      this.log.error('fetching csrf token for url', req.url)
      return this.http.get('/web/personal/csrf', {observe: 'response'}).pipe(
        switchMap(() => handleRequest(req, next)),
      );
    }

    return handleRequest(req, next).pipe(
      tap(() => {
        if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(req.method)) {
          this.httpCacheService.deleteCache();
        }
      }),
    );
  }

  public constructor(public injector: Injector) {
    this.http = injector.get(HttpClient);
    this.log = injector.get(NGXLogger);
    this.httpCacheService = injector.get(HttpCacheService);
    this.cookieService = injector.get(CookieService);
    this.tooManyDevicesEventService = injector.get(TooManyDevicesEventService);
    this.authEventService = injector.get(AuthenticationEventService);

  }
}
