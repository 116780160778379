import {Injectable} from '@angular/core';
import {
  AllMatomoDimensions,
  DIMENSIONS,
  EVENT_ACTION,
  EVENT_CATEGORY,
  GoogleAnalyticsDimensions,
  MATOMO_VALUES,
  MatomoDimensions,
  NoExtraProperties,
  PAGE_TYPE,
} from '../../../types/gtm-options';
import {NGXLogger} from 'ngx-logger';
import {delay, distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {Angulartics2GoogleGlobalSiteTag, Angulartics2Matomo} from 'angulartics2';
import {AssetFromSearchIndexResource, AssetReducedResource, AssetResource} from '../../entities/asset.entity';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {ProfileEditingResource, ProfilePageResource, ProfileReducedResource} from '../../entities/Profile.entity';
import {AdBlockerDetectionService} from './ad-blocker-detection.service';
import {ASSET_MONETIZATION} from '../../../enums/ASSET_MONETIZATION';
import {SomtagService} from './somtag.service';
import {environment} from '../../../environments/environment';
import {GoogleAdsConversionService} from './google.ads.conversion.service';
import {EventTrackingService} from './event-tracking.service';
import {TagReducedResource} from '../../entities/Tag.entity';
import {ASSET_FLAG} from '../../../enums/ASSET_FLAG';
import {DisplayProducts} from '../../entities/DisplayProduct';
import {ConsentService} from "./consent.service";
import {MicrosoftAdsService} from "./microsoft.ads.service";

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private debug = false;
  private pageViewTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pageType: PAGE_TYPE = PAGE_TYPE.OTHER;
  private matomoInitialized = false;
  private gaInitialized = false;


  public static escape(s: string): string {
    return s.replace('#', '-').replace('&', '-').replace('?', '-').replace('%', '-');
  }

  public logout(): void {
    this.consentService.consentGiven(environment.consentIds.matomo).subscribe(
      () => {
        // User has just logged out, we reset the User ID
        _paq.push(['resetUserId']);

        // we also force a new visit to be created for the page views after logout
        _paq.push(['appendToTrackingUrl', 'new_visit=1']);

        _paq.push(['trackPageView']);

        // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
        _paq.push(['appendToTrackingUrl', '']);
      }
    )


  }

  public getLabelForAsset(asset: AssetReducedResource): string {
    return asset.profile.slug + ': ' + asset.name + '(' + asset.id + ')';
  }

  public getLabelForProfile(profile: ProfileReducedResource): string {
    return AnalyticsService.escape(profile.slug + ' (' + profile.id + ')');
  }

  public getLabelForTag(tag: TagReducedResource): string {
    return tag.slug + ' (' + tag.id + ')';
  }

  public trackProductView(displayProducts: DisplayProducts[]): void {
    this.afterFirstPageView().pipe(
      switchMap(() => this.consentService.consentGiven(environment.consentIds.matomo)),
      filter((consentGiven) => consentGiven)
    ).subscribe(() => {
      displayProducts.forEach(displayProduct => {
        this.log('setEcommerceView', displayProduct.profile);

        const data = [
          'setEcommerceView',
          displayProduct.product_id ?? displayProduct.asset?.id, // (Required) productSKU
          displayProduct.profile.name + ': ' + displayProduct.name, // (Optional) productName
          displayProduct.type, // (Optional) categoryName
          displayProduct.price_in_cents / 100, // (Optional) price
        ];
        this.log('setEcommerceView', data);
        _paq.push(data);
      });
    });
  }

  public trackPageView(pageType: PAGE_TYPE, dim: MatomoDimensions = {}): void {
    this.pageViewTriggered.next(false);
    this.pageType = pageType;
    this.log('start trackPageView', pageType);

    // wait until the consent service is initialized
    this.consentService.initialized$.pipe(
      filter(init => init),
      // wait until the first consent decision was made
      switchMap(() => this.consentService.allConsentsGiven()),

      switchMap((allAccepted) => {
        if (allAccepted) {
          this.log('all consents already accepted');
          return of(true);
        }
        this.log('waiting for all consents to be accepted');
        return this.consentService.consentsChanged$.pipe(
          tap(() => this.log('consentService.consentsChanged$ whilst waiting to send page view')),
          switchMap(() => this.consentService.allConsentsGiven()),
          filter((allConsentsGiven) => allConsentsGiven),
          take(1),
          tap(() => this.log('all consents finally accepted')),
        )
      }),
      switchMap(() => this.enrichWithGlobalDimensions(pageType, dim)),
      tap(AnalyticsService.resetCustomDimensions),
      map((dim: AllMatomoDimensions) => this.setDimensions(dim)),
    )
      .subscribe({
        next: () => {

          _paq.push(['setConsentGiven']);
          this.angulartics2Matomo.pageTrack(location.pathname);
          this.angulartics2GoogleGlobalSiteTag.pageTrack(location.pathname);
          this.microsoftAdsService.trackPageView(location.pathname);

          this.pageViewTriggered.next(true);
          this.log('page view triggered', pageType);

        },
        error: (error) => {
          this.log('error in trackPageView', error);
        }
      })
  }


  public getDimensionsForAsset(asset: AssetReducedResource | AssetFromSearchIndexResource): Observable<MatomoDimensions> {
    return this.somtagService.adsDisabledForAsset(asset).pipe(
      map(adsDisabledForAsset => {
        const adsEnableForAsset = !adsDisabledForAsset;
        return {
          ACTION_SPORT_TYPE_NAME: asset.profile?.sport_type?.name ?? 'not-set',
          ACTION_SPORT_TYPE_ID: asset.profile?.sport_type?.id ?? 'not-set',
          ACTION_PROFILE_NAME: asset.profile?.name ? AnalyticsService.escape(asset.profile?.name) : 'not-set',
          ACTION_PROFILE_ID: asset.profile?.id ?? 'not-set',

          ACTION_TAG_NAME: asset.profile?.sport_type?.name ?? 'not-set',
          ACTION_TAG_ID: asset.profile?.sport_type?.slug ?? 'not-set',
          ACTION_ASSET_NAME: AnalyticsService.escape(asset.name),
          ACTION_ASSET_ID: asset.id,
          ACTION_ASSET_TYPE: asset.type,
          ACTION_ASSET_MARKETABLE: AnalyticsService.parseBool(adsEnableForAsset), //checked
          ACTION_HOME_TEAM_NAME: asset.home_team?.name ? AnalyticsService.escape(asset.home_team?.name) : undefined,

          ACTION_HOME_TEAM_ID: asset.home_team?.id,
          ACTION_GUEST_TEAM_NAME: asset.guest_team?.name ? AnalyticsService.escape(asset.guest_team?.name) : undefined,
          ACTION_GUEST_TEAM_NAME_ID: asset.guest_team?.id,
          ACTION_ASSET_NEEDS_PAY: asset.monetizations.length === 0 ? MATOMO_VALUES.FALSE : MATOMO_VALUES.TRUE,
          ACTION_ASSET_HAS_PPV: asset.monetizations.includes(ASSET_MONETIZATION.PPV) ? MATOMO_VALUES.TRUE : MATOMO_VALUES.FALSE,
          ACTION_ASSET_HAS_PASS: asset.monetizations.includes(ASSET_MONETIZATION.PASS) ? MATOMO_VALUES.TRUE : MATOMO_VALUES.FALSE,
          ACTION_ASSET_HAS_ABO: asset.monetizations.includes(ASSET_MONETIZATION.SUBSCRIPTION) ? MATOMO_VALUES.TRUE : MATOMO_VALUES.FALSE,

          ACTION_LEAGUE_ID: asset.profile.league_id ?? 'not-set',
          ACTION_CLUB_ID: asset.profile.club_id ?? 'not-set',
          ACTION_ASSOCIATION_ID: asset.profile.association_id ?? 'not-set',

          ACTION_LICENCE_OWNER: asset.flags.includes(ASSET_FLAG.SPORT_A) ? ASSET_FLAG.SPORT_A : 'not-set',

          // [todo analytics] ACTION_PRODUCTION_PARTNER: asset.production_partner,
          // [todo analytics] ACTION_VIDEO_DURATION_IN_SECONDS: undefined,
          // [todo analytics] ACTION_USED_MONETIZATION_TYPE:
          // [todo analytics] ACTION_USED_MONETIZATION_PRODUCT_NAME:
          // [todo analytics] ACTION_USED_MONETIZATION_PRODUCT_ID:
          // [todo analytics] ACTION_VENUE_STATE: asset.venue_state,
          // [todo analytics] ACTION_VENUE_CITY:
          // [todo analytics] ACTION_TAGS_CSV: asset.tags
          // ACTION_VIDEO_LIFECYCLE_ID:
        };
      }),
    );
  }

  public trackVideoEvent(
    action: EVENT_ACTION,
    asset: AssetResource,
    dimensions: Partial<NoExtraProperties<AllMatomoDimensions>>,
    value: number,
    interaction = true,
  ): void {
    const label = AnalyticsService.getEventLabelForAsset(asset);

    this.log('videoEvent -> ', action);

    this.getDimensionsForAsset(asset)
      .pipe(
        map(dimensionsForAsset => ({...dimensionsForAsset, ...dimensions})),
        tap(dimensions => {
          this.trackEvent(EVENT_CATEGORY.VIDEO, action, label, value, interaction, dimensions);

          if (action === EVENT_ACTION.VIDEO_VIEW) {
            this.eventTrackingService.pushVideoViewEvent(asset.id);
          }
        }),
        switchMap(() =>
          action === EVENT_ACTION.VIDEO_VIEW_MARKETABLE
            ? this.consentService.consentGiven(environment.consentIds.google_ad_services)
            : of(false)
        )
      )
      .subscribe(consentGiven => {
        if (consentGiven) {
          this.googleAdsConversionService.trackGoogleAdsConversion('AW-984392417/fB-lCJzTyfEBEOHFstUD')
        }
      });

    this.getDimensionsForAsset(asset)
      .pipe(
        map(dimensionsForAsset => ({...dimensionsForAsset, ...dimensions})),
        switchMap(() => {
            return action === EVENT_ACTION.VIDEO_VIEW_MARKETABLE
              ? this.microsoftAdsService.hasConsent()
              : of(false)
          }
        )
      )
      .subscribe(consentGiven => {
        if (consentGiven) {
          this.microsoftAdsService.trackCustomEvent('video_view', 'video_view')
        }
      });
  }


  public trackAssetTileImpression(asset: AssetReducedResource | AssetFromSearchIndexResource): void {

    this.getDimensionsForAsset(asset).subscribe(dimensions => {
      //this.log('trackAssetTileImpression with dimensions', dimensions);
      this.trackEvent(
        EVENT_CATEGORY.IMPRESSION,
        EVENT_ACTION.IMPRESSION_ASSET_TILE_IMPRESSION,
        AnalyticsService.getEventLabelForAsset(asset),
        1,
        false,
        dimensions,
        false,
        true,
      );
    });
  }

  public trackProfileTileImpression(profile: ProfileReducedResource): void {

    const dimensions = AnalyticsService.getDimensionsForProfile(profile);
    // this.log('trackProfileTileImpression with dimensions', dimensions);
    this.trackEvent(
      EVENT_CATEGORY.IMPRESSION,
      EVENT_ACTION.IMPRESSION_PROFILE_TILE_IMPRESSION,
      this.getLabelForProfile(profile),
      1,
      false,
      dimensions,
      false,
      true,
    );
  }

  public trackProfilePageImpression(profile: ProfilePageResource): void {

    const dimensions = AnalyticsService.getDimensionsForProfile(profile);
    // this.log('trackProfileTileImpression with dimensions', dimensions);
    this.trackEvent(
      EVENT_CATEGORY.IMPRESSION,
      EVENT_ACTION.IMPRESSION_PROFILE_PAGE_IMPRESSION,
      this.getLabelForProfile(profile),
      1,
      false,
      dimensions,
      false,
      true,
    );
  }

  public trackTagPageImpression(tag: TagReducedResource, dimensions: MatomoDimensions): void {

    // this.log('trackTagPageImpression with dimensions', dimensions);
    this.trackEvent(
      EVENT_CATEGORY.IMPRESSION,
      EVENT_ACTION.IMPRESSION_TAG_PAGE_IMPRESSION,
      this.getLabelForTag(tag),
      1,
      false,
      dimensions,
      false,
      true,
    );
  }

  public trackAssetPageImpression(asset: AssetReducedResource, dimensions: MatomoDimensions): void {
    if (dimensions.ACTION_ASSET_NAME) dimensions.ACTION_ASSET_NAME = dimensions.ACTION_ASSET_NAME?.replace('#', '-').replace('&', '-').replace('?', '-').replace('%', '-')
    //this.log('trackAssetPageImpression with dimensions', dimensions);
    this.trackEvent(
      EVENT_CATEGORY.IMPRESSION,
      EVENT_ACTION.IMPRESSION_ASSET_PAGE_IMPRESSION,
      AnalyticsService.getEventLabelForAsset(asset),
      1,
      false,
      dimensions,
      false,
    );
  }

  public trackMatomoCheckoutConversion(orderId: string, priceInCent: number, discountInCents = 0): void {

    this.consentService.consentGiven(environment.consentIds.matomo)
      .subscribe(consentGiven => {
        if (consentGiven) {
          const data = [
            'trackEcommerceOrder',
            orderId, // (Required) orderId
            priceInCent / 100, // (Required) grandTotal (revenue)
            priceInCent / 100 / 1.19, // (Optional) subTotal
            (priceInCent / 100 / 1.19) * 0.19, // (optional) tax
            0, // (optional) shipping
            discountInCents === 0 ? false : discountInCents / 100, // (optional) discount
          ];
          _paq.push(data);
        }
      });
  }

  public trackFacebookEvent(event: string, value: Record<string, string|number>): void {

    this.log('trackFacebookEvent', event);
    this.consentService.initialized$.pipe(
      filter(init => init),
      tap(() => this.log('trackFacebookEvent consentService.initialized$')),
      switchMap(() => this.consentService.allConsentsGiven()),
      filter(allConsentsGiven => allConsentsGiven),
      tap(() => this.log('trackFacebookEvent areAllConsentsAccepted')),
      switchMap(() => this.afterFirstPageView()),
      tap(() => this.log('trackFacebookEvent afterFirstPageView')),
      tap(() =>  {
          if (window.fbq) return;
          this.log('trackFacebookEvent init fbq');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const n: any = window.fbq = function () {
            n.callMethod ?
              // eslint-disable-next-line prefer-rest-params,prefer-spread
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!window._fbq) window._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          const t = document.createElement('script');
          t.async = !0;
          t.src = 'https://connect.facebook.net/en_US/fbevents.js';
          const s = document.getElementsByTagName('script')[0];
          s.parentNode?.insertBefore(t, s)
          window.fbq('init', '');
      }),
    ) .subscribe(() => {
      this.log('trackFacebookEvent subscribe');
      try {
        fbq("track", event, value);
        this.log('trackFacebookEvent', {event, value});
      }catch (e) {
        this.log('trackFacebookEvent ERROR in trackFacebookEvent', e, );
      }
    })
  }

  public trackMicrosoftAdsCheckoutConversion(orderId: string, itemId: string, priceInCents?: number): void {
    this.microsoftAdsService.hasConsent()
      .subscribe(consentGiven => {
        if (consentGiven) {
          this.microsoftAdsService.trackCheckoutConversion(orderId, itemId, priceInCents);
        }
      });
  }

  public trackEvent(
    category: EVENT_CATEGORY,
    action: EVENT_ACTION,
    name = '',
    value: number,
    interaction = true,
    dimensions: Partial<NoExtraProperties<AllMatomoDimensions>> | undefined = undefined,
    trackAlsoAtGoogle = true,
    trackAsBulk = false,
  ): void {


    name = AnalyticsService.escape(name);

    this.consentService.initialized$.pipe(
      filter(init => init),
      delay(1),// wir brauchen ein kurzes delay, ansonsten is window.UC_UI.areAllConsentsAccepted() nicht korrekt => danke für nichts sch*** User Centrics
      switchMap(() => this.consentService.allConsentsGiven()),
      filter(allConsentsGiven => allConsentsGiven),
      switchMap(() => this.afterFirstPageView()),
      switchMap(() => this.enrichWithGlobalDimensions(this.pageType, dimensions)),
      tap(AnalyticsService.resetCustomDimensions),
      map((dim: AllMatomoDimensions) => this.setDimensions(dim)),
    )
      .subscribe(() => {
        _paq.push(['setConsentGiven'])
        if (trackAsBulk) {
          _paq.push([
            'queueRequest',
            [
              `e_c=${encodeURIComponent(category)}&e_a=${encodeURIComponent(action)}&e_n=${encodeURIComponent(
                name as string,
              )}&e_v=${encodeURIComponent(value)}`,
            ],
          ]);
        } else {
          _paq.push(['trackEvent', category, action, name, value, !interaction]);
        }
        if (trackAlsoAtGoogle) {
          const gstCustom = dimensions ? AnalyticsService.mapToGoogleAnalyticsCustomDimensions(dimensions) : {};
          gtag('event', action, {
            event_category: category,
            event_label: name,
            value: value,
            non_interaction: !interaction,
            ...gstCustom,
          });
        }
      });

  }

  public trackVisibleContentImpression(
    dimensions: Partial<NoExtraProperties<AllMatomoDimensions>> | undefined = undefined,
  ): void {
    this.consentService.initialized$.pipe(
      filter(init => init),
      delay(1),// wir brauchen ein kurzes delay, ansonsten is window.UC_UI.areAllConsentsAccepted() nicht korrekt => danke für nichts sch*** User Centrics
      switchMap(() => this.consentService.allConsentsGiven()),
      filter(allConsentsGiven => allConsentsGiven),
      switchMap(() => this.afterFirstPageView()),
      switchMap(() => this.enrichWithGlobalDimensions(this.pageType, dimensions)),
      tap(AnalyticsService.resetCustomDimensions),
      map((dim: AllMatomoDimensions) => this.setDimensions(dim)),
    )
      .subscribe(() => {
        _paq.push(['trackVisibleContentImpressions']);
      });
  }

  private static getEventLabelForAsset(asset: AssetReducedResource | AssetFromSearchIndexResource): string {
    return AnalyticsService.escape(asset.profile.slug + ': ' + asset.name + '(' + asset.id + ')');
  }

  private static resetCustomDimensions(): void {
    _paq.push(['deleteCustomDimension', 1]);
  }

  private static mapToGoogleAnalyticsCustomDimensions(dimensions: Partial<AllMatomoDimensions>): GoogleAnalyticsDimensions {
    const r: GoogleAnalyticsDimensions = {} as GoogleAnalyticsDimensions;
    Object.keys(DIMENSIONS).map((dimensionName: string) => {
      if (Object.keys(dimensions).includes(dimensionName)) {
        const dimensionNumberWithPaddedZeros = String(DIMENSIONS[dimensionName as keyof AllMatomoDimensions] as number).padStart(3, '0');
        let dimensionNameForGA = dimensionName;
        dimensionNameForGA = dimensionNameForGA.replace('VISIT_', '');
        dimensionNameForGA = dimensionNameForGA.replace('ACTION_', '');

        r[`D${dimensionNumberWithPaddedZeros}_${dimensionNameForGA}` as keyof GoogleAnalyticsDimensions] =
          dimensions[dimensionName as keyof AllMatomoDimensions];
      }
    });

    return r;
  }

  private static mapToMatomoActionCustomDimensions(dimensions: Partial<AllMatomoDimensions>): {
    [key: string]: string | undefined | number;
  } {
    const r: { [key: string]: string | undefined | number } = {};

    Object.keys(DIMENSIONS).map(dimensionName => {
      if (Object.keys(dimensions).includes(dimensionName)) {
        r[`dimension${DIMENSIONS[dimensionName as keyof AllMatomoDimensions]}`] = dimensions[dimensionName as keyof AllMatomoDimensions];
      }
    });

    return r;
  }

  private static parseBool(val: boolean | undefined): MATOMO_VALUES {
    if (val === true) {
      return MATOMO_VALUES.TRUE;
    } else if (val === false) {
      return MATOMO_VALUES.FALSE;
    }
    return MATOMO_VALUES.NOT_SET;
  }

  private static getDimensionsForProfile(profile: ProfileReducedResource): MatomoDimensions {
    return {
      VISIT_SOFTWARE: "web",
      ACTION_SPORT_TYPE_NAME: profile?.sport_type?.name ?? 'not-set',
      ACTION_SPORT_TYPE_ID: profile?.sport_type?.id ?? 'not-set',
      ACTION_PROFILE_NAME: profile?.name ?? 'not-set',
      ACTION_PROFILE_ID: profile?.id ?? 'not-set',

      ACTION_TAG_NAME: profile?.sport_type?.name ?? 'not-set',
      ACTION_TAG_ID: profile?.sport_type?.slug ?? 'not-set',

      // [todo analytics] ACTION_LEAGUE_NAME:asset.profile.league?.name ?? 'not-set',
      // [todo analytics] ACTION_LEAGUE_ID: asset.profile.league?.id ?? 'not-set',
      // [todo analytics] ACTION_ASSOCIATION_NAME: asset.profile.association?.id ?? 'not-set',
      // [todo analytics] ACTION_ASSET_PRODUCTION_PARTNER: asset.production_partner,
      // [todo analytics] ACTION_ASSET_LICENCE_OWNER:asset.licence_owner,
      // [todo analytics] ACTION_ASSET_VIDEO_DURATION_IN_SECONDS: undefined,
      // [todo analytics] ACTION_ASSET_USED_MONETIZATION_TYPE:
      // [todo analytics] ACTION_ASSET_USED_MONETIZATION_PRODUCT_NAME:
      // [todo analytics] ACTION_ASSET_USED_MONETIZATION_PRODUCT_ID:
      // [todo analytics] ACTION_ASSET_VENUE_STATE: asset.venue_state,
      // [todo analytics] ACTION_ASSET_VENUE_CITY:
      // [todo analytics] ACTION_ASSET_TAGS_CSV: asset.tags
      // ACTION_VIDEO_LIFECYCLE_ID: ,
    };
  }

  private setDimensions(dimensions: Partial<NoExtraProperties<AllMatomoDimensions>>): void {

    this.consentService.consentGiven(environment.consentIds.matomo).subscribe(
      (c) => {
        if (c) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.angulartics2Matomo.setUserProperties(AnalyticsService.mapToMatomoActionCustomDimensions(dimensions));
        }
      }
    )

    this.consentService.consentGiven(environment.consentIds.google_analytics).subscribe(
      (c) => {
        if (c) {
          this.angulartics2GoogleGlobalSiteTag.setUserProperties(AnalyticsService.mapToGoogleAnalyticsCustomDimensions(dimensions));
        }
      }
    )
  }

  private enrichWithGlobalDimensions(pageType: PAGE_TYPE, dim: MatomoDimensions = {}): Observable<AllMatomoDimensions> {
    return combineLatest([
      this.consentService.initialized$,
      this.authenticationService.loggedIn$,
      this.authenticationService.profile$,
      this.adBlockDetectionService.hasAdBlocker(),
      this.authenticationService.getUserId(),
      this.consentService.consentGiven(environment.consentIds.matomo),
      this.consentService.allConsentsGiven()
    ]).pipe(
      map(([initialized, loggedIn, profile, hasAdBlocker, userId, matomoConsent, allNonTCFConsentsGiven]) => ({
        initialized,
        loggedIn,
        profile,
        hasAdBlocker,
        userId,
        matomoConsent,
        allNonTCFConsentsGiven
      })),
      filter(({initialized}) => initialized),
      filter(({loggedIn}) => loggedIn !== undefined),
      map(({loggedIn, profile, hasAdBlocker, userId, matomoConsent, allNonTCFConsentsGiven}) => {
        return {
          visitMarketable: AnalyticsService.parseBool(allNonTCFConsentsGiven && !hasAdBlocker),
          allConsentsGiven: AnalyticsService.parseBool(allNonTCFConsentsGiven),
          loggedIn: AnalyticsService.parseBool(loggedIn as boolean),
          profile,
          adBlockerDetected: AnalyticsService.parseBool(hasAdBlocker as boolean),
          profileHasSportDeutschlandPlus: profile?.has_sportdeutschland_plus ? MATOMO_VALUES.TRUE : MATOMO_VALUES.FALSE,
          userId,
          matomoConsentGiven: matomoConsent
        };
      }),
      map((value: {
        visitMarketable: MATOMO_VALUES;
        allConsentsGiven: MATOMO_VALUES;
        loggedIn: MATOMO_VALUES;
        profile: ProfileEditingResource | undefined;
        adBlockerDetected: MATOMO_VALUES;
        profileHasSportDeutschlandPlus: MATOMO_VALUES;
        userId: string | undefined;
        matomoConsentGiven: boolean;
      }) => {
        if (value.matomoConsentGiven) {
          _paq.push(['setUserId', value.userId]);
        }

        const allMatomoDimensions: AllMatomoDimensions = {
          ...dim,
          VISIT_MARKETABLE: value.visitMarketable,
          ACTION_PAGE_TYPE: pageType,
          VISIT_USER_LOGGED_IN: value.loggedIn,
          VISIT_ALL_CONSENTS_GIVEN: value.allConsentsGiven,
          VISIT_EMBEDDED: MATOMO_VALUES.FALSE,
          VISIT_USER_PROFILE_TYPE: value.profile?.type || MATOMO_VALUES.NOT_SET,
          VISIT_USER_HAS_SDTV_PLUS: value.profileHasSportDeutschlandPlus,
          VISIT_ADBLOCKER_DETECTED: value.adBlockerDetected,
          VISIT_EMBEDDED_ORIGIN: MATOMO_VALUES.NOT_SET,
          ACTION_ADBLOCKER_DETECTED: value.adBlockerDetected,
          ACTION_USER_LOGGED_IN: value.loggedIn,
          ACTION_EMBEDDED: MATOMO_VALUES.FALSE,
          ACTION_EMBEDDED_ORIGIN: MATOMO_VALUES.NOT_SET,
          ACTION_USER_HAS_SDTV_PLUS: value.profileHasSportDeutschlandPlus,
          ACTION_FRONTEND_VERSION: COMMIT_REF ?? 'unknown',
          VISIT_SOFTWARE: "web",
        };
        return allMatomoDimensions;
      }),
    );
  }


  private log(message: string, values: unknown = undefined): void {
    if (this.debug) {
      this.logger.log('[analytics service] -> ' + message, values);
    }
  }


  private afterFirstPageView(): Observable<boolean> {
    return this.pageViewTriggered.pipe(
      distinctUntilChanged(),
      filter(triggered => triggered),
      take(1),
    );
  }

  public constructor(
    private angulartics2Matomo: Angulartics2Matomo,
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    private authenticationService: AuthenticationService,
    private consentService: ConsentService,
    private somtagService: SomtagService,
    private adBlockDetectionService: AdBlockerDetectionService,
    private googleAdsConversionService: GoogleAdsConversionService,
    private eventTrackingService: EventTrackingService,
    private microsoftAdsService: MicrosoftAdsService,
    private logger: NGXLogger,
  ) {
    consentService.analyticsEvent$.subscribe(e => this.trackEvent(e.category, e.action, '', 1, false));


    this.consentService.consentsChanged$
      .pipe(
        switchMap(() => this.consentService.consentGiven(environment.consentIds.google_analytics)),
        filter((gaConsent: boolean) => gaConsent && !this.gaInitialized),
        take(1)
      )
      .subscribe({
        next: () => {
          this.log('ga consent given');
          const script = document.createElement('script');
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtag.measurement_id}`;
          script.onerror = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // window.AD_LOADING_ERROR = true
          }
          script.onload = () => {
            this.gaInitialized = true;
            gtag('js', new Date());


            gtag('config', environment.gtag.measurement_id, {
              anonymize_ip: true,
              allow_google_signals: false,
              allow_ad_personalization_signals: false,
              custom_map: {
                dimension1: 'D001_USER_LOGGED_IN',
                dimension2: 'D002_MARKETABLE',
                dimension3: 'D003_EMBEDDED',
                dimension4: 'D004_USER_HAS_SDTV_PLUS',
                dimension5: 'D005_ALL_CONSENTS_GIVEN',
                dimension6: 'D006_USER_PROFILE_TYPE',
                dimension7: 'D007_SPORT_TYPE_NAME',
                dimension8: 'D008_ADBLOCKER_DETECTED',
                dimension9: 'D009_EMBEDDED_ORIGIN',
                dimension10: 'D010_SPORT_TYPE_NAME',
                dimension11: 'D011_PROFILE_NAME',
                dimension12: 'D012_PROFILE_ID',
                dimension13: 'D013_LEAGUE_NAME',
                dimension14: 'D014_LEAGUE_ID',
                dimension15: 'D015_ASSOCIATION_NAME',
                dimension16: 'D016_AD_BLOCK',
                dimension17: 'D017_AD_BLOCK_COUNT',
                dimension18: 'D018_ADBLOCKER_DETECTED',
                dimension19: 'D019_USER_LOGGED_IN',
                dimension20: 'D020_EMBEDDED',
                dimension21: 'D021_EMBEDDED_ORIGIN',
                dimension22: 'D022_USER_HAS_SDTV_PLUS',
                dimension23: 'D023_SPORT_TYPE_ID',
                dimension24: 'D024_TAG_NAME',
                dimension25: 'D025_TAG_ID',
                dimension26: 'D026_PAGE_TYPE',
                dimension27: 'D027_ASSET_NAME',
                dimension28: 'D028_ASSET_ID',
                dimension29: 'D029_ASSET_TYPE',
                dimension30: 'D030_ASSET_MARKETABLE',
                dimension31: 'D031_HOME_TEAM_NAME',
                dimension32: 'D032_GUEST_TEAM_NAME',
                dimension33: 'D033_ASSET_NEEDS_PAY',
                dimension34: 'D034_USED_MONETIZATION',
                dimension35: 'D035_PRODUCTION_PARTNER',
                dimension36: 'D036_LICENCE_OWNER',
                dimension37: 'D037_HOME_TEAM_ID',
                dimension38: 'D038_GUEST_TEAM_ID',
                dimension39: 'D039_ASSET_DURATION',
                dimension40: 'D040_ASSET_HAS_PPV',
                dimension41: 'D041_ASSET_HAS_PASS',
                dimension42: 'D042_ASSET_HAS_ABO',
                dimension43: 'D043_USED_PRODUCT_NAME',
                dimension44: 'D044_USED_PRODUCT_ID',
                dimension45: 'D045_ASSET_VENUE_STATE',
                dimension46: 'D046_ASSET_VENUE_CITY',
                dimension47: 'D047_ASSET_TAGS_CSV',
                dimension48: 'D048_AD_POS_IN_BLOCK',
                dimension49: 'D049_LIFECYCLE_ID',
                dimension50: 'D050_FRONTEND_VERSION',
                dimension51: 'D051_TID',
                dimension54: 'D054_SOFTWARE',
              },
            });
          }
          document.head.appendChild(script);
        }
      });


    of(this.consentService.consentsChanged$).pipe(
      tap(() => this.log('consentService.consentsChanged$')),
      switchMap(() => this.consentService.consentGiven(environment.consentIds.matomo)),
      filter((matomoConsent) => matomoConsent && !this.matomoInitialized),
    )
      .subscribe(() => {
          this.log('matomo consent given');

          this.matomoInitialized = true
          _paq.push(['setConsentGiven']);
          _paq.push(['setSiteId', environment.matomo.siteId]);
          if (environment.matomo.cookieDomain) {
            _paq.push(['setCookieDomain', environment.matomo.cookieDomain]);
          }
          if (environment.matomo.domains) {
            _paq.push(['setDomains', environment.matomo.domains]);
          }
          _paq.push(['setRequestQueueInterval', 2500]);
          _paq.push(['enableLinkTracking']);
          _paq.push(['enableHeartBeatTimer', 15]);
          _paq.push(['MediaAnalytics::setPingInterval', 60]);
          _paq.push(['MediaAnalytics::setMaxTrackingTime', 24 * 60 * 60]);
        }
      )


  }
}
