import {ErrorHandler, Injectable} from '@angular/core';

class ChunkLoadError {
}

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  public handleError(error: Error | ChunkLoadError | ErrorEvent): void {
    if (error instanceof ErrorEvent) {
      // Client-side or network error.
      // eslint-disable-next-line no-console
      console.error('An error occurred:', error.error.message);
    } else if ('message' in error && error.message.includes('ChunkLoadError')) {
      // We're assuming here that ChunkLoadError will have a `message` property.
      // eslint-disable-next-line no-console
      console.error('ChunkLoadError detected, reloading the page...');
      window.location.reload();
    } else {
      // If it's a generic Error object, you can handle it this way.
      // eslint-disable-next-line no-console
      console.error(`Error occurred`, error);
    }
  }
}
