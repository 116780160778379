import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[imageError]',
})
export class ImageErrorDirective {
  @Input() public imageError = '/assets/placeholders/profil.svg';
  private loadErrorImage(): void {
    // remove the onerror handler to prevent a loop
    this.el.nativeElement.onerror = null;
    this.el.nativeElement.srcset = '';
    this.el.nativeElement.src = this.imageError;
  }
  public constructor(public el: ElementRef, public renderer: Renderer2) {
    if (this.el.nativeElement.src === null) {
      this.loadErrorImage();
    }
    this.el.nativeElement.onerror = this.loadErrorImage.bind(this);
  }
}
