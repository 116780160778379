import {Injectable, Optional, SkipSelf} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TagReducedResource} from "../../entities/Tag.entity";
import {Router} from "@angular/router";
import {ProfileReducedResource} from "../../entities/Profile.entity";
import {SIDEBAR_MENU, SidebarComponent} from "../components/sidebar/sidebar.component";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class SidebarAndHeaderService {


  public isHandballNet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  public sidebarMenu$: BehaviorSubject<{
    menu: SIDEBAR_MENU,
    profile?: ProfileReducedResource,
    tag?: TagReducedResource,
    changeLogoAndTags: boolean
  }> = new BehaviorSubject<{
    menu: SIDEBAR_MENU,
    profile?: ProfileReducedResource,
    tag?: TagReducedResource,
    changeLogoAndTags: boolean
  }>({menu: SIDEBAR_MENU.TOP, changeLogoAndTags: true})

  public openSidebar(): void {
    this.offcanvasService.open(SidebarComponent, {
      ariaLabelledBy: 'sidebarLabel',
      scroll: false,
      container: 'body',
      panelClass: ''
    }).result.then(
      () => {
        //

      },
      () => {
        //
      },
    );
  }

  public configureSidebar(menu: SIDEBAR_MENU, profile?: ProfileReducedResource, tag?: TagReducedResource, changeLogoAndTags = false): void {
    this.sidebarMenu$.next({menu, profile, tag, changeLogoAndTags})
  }

  public constructor(
    private offcanvasService: NgbOffcanvas,
    @Optional()
    @SkipSelf()
      otherInstance: SidebarAndHeaderService,
    private router: Router) {
    if (otherInstance) {
      throw 'sidebarAndHeaderService should have only one instance.'
    }


    this.sidebarMenu$.subscribe(
      (m => {

        if (m.changeLogoAndTags) {
          if (m.menu === SIDEBAR_MENU.TOP || m.menu === SIDEBAR_MENU.ALL_SPORT_TYPES || m.menu === SIDEBAR_MENU.SPORT_TYPE_TAG) {
            this.isHandballNet.next(false)
          } else if (m.profile) {
            this.isHandballNet.next([m.profile.id, m.profile.association_id].includes(environment.api.handball_net_profile_id))
          }
        }
      })
    )

    this.isHandballNet.subscribe(isHandballNet => {
      if(isHandballNet) {
        if(document.getElementById('handball-net-theme-css') === null) {
          const handballNetThemeLink = document.createElement("link");
          handballNetThemeLink.id = 'handball-net-theme-css';
          handballNetThemeLink.rel = "stylesheet";
          handballNetThemeLink.href = "handballnet-main.css";

          document.head.appendChild(handballNetThemeLink);
          document.getElementsByTagName('html')[0].style.backgroundColor = 'white';
        }
      } else {
        document.getElementById('handball-net-theme-css')?.remove();
        document.getElementsByTagName('html')[0].style.backgroundColor = '#0f0e0b';
      }
    })
  }

}
