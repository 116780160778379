import {EventEmitter, Injectable, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ProfileEditingResource} from '../../entities/Profile.entity';
import {environment} from '../../../environments/environment';
import {PROFILE_TYPE} from '../../../enums/PROFILE_TYPE';
import {interval, Observable, timer} from 'rxjs';
import {WEBSOCKET_EVENT, WebSocketMessage} from '../../entities/WebSocketMessage.entity';
import {filter, map} from 'rxjs/operators';
import {AuthenticationStatusService} from "./authentication.status.service";

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  @Output() public messages: EventEmitter<WebSocketMessage> = new EventEmitter();
  @Output() public ready: EventEmitter<void> = new EventEmitter();
  @Output() public closed: EventEmitter<void> = new EventEmitter();
  private currentWebSocket: WebSocket | null = null;
  private logEnabled = false;
  private shouldDisconnect = false;


  public send(message: WebSocketMessage): void {
    this.currentWebSocket?.send(JSON.stringify({message}));
  }

  public observe<T>(event: WEBSOCKET_EVENT): Observable<T> {
    return this.messages.pipe(
      filter(e => e.event === event),
      map(t => t as unknown as T),
    );
  }

  public connect(profile: ProfileEditingResource): void {
    if (profile.type === PROFILE_TYPE.VIEWER) {
      this.log('Did not connect viewer to websocket', {});
      return;
    }

    if (this.authenticationStatusService.loggedInStatus.value === false) {
      this.log('Not connected to websocket because user is not logged in', {});
      return;
    }

    if (this.currentWebSocket?.readyState === WebSocket.CONNECTING) {
      this.log('already connecting', {});
      return;
    }
    if (this.currentWebSocket?.readyState === WebSocket.OPEN) {
      this.log('connection is open', {});
      return;
    }
    this.log('connecting to websocket', {});

    this.shouldDisconnect = false;
    this.currentWebSocket = new WebSocket(environment.api.websocket);

    this.currentWebSocket.addEventListener('open', () => {
      this.log('connection opened', {});
      this.ready.emit();
    });

    this.currentWebSocket.addEventListener('message', (event: MessageEvent<string>) => {
      const data: { message: WebSocketMessage } = JSON.parse(event.data);
      this.log('received message', data.message);
      if (data.message.event === WEBSOCKET_EVENT.PING) {
        this.currentWebSocket?.send(JSON.stringify({event: 'pong', message: {}}));
      } else {
        this.messages.emit(data.message);
      }
    });

    this.currentWebSocket.addEventListener('close', event => {
      this.log('connection closed', event.code);

      if (this.shouldDisconnect || event.code === 1001) { // Close frame
        this.closed.emit();
        this.currentWebSocket = null;
        return;
      }

      if (event.code === 401) { // Unauthorized response
        this.log('Unauthorized: not reconnecting', {});
        return;
      }

      this.log('connection closed.. will retry in 20 seconds', event.code);
      timer(20000).subscribe(() => this.connect(profile));
    });

    this.currentWebSocket.addEventListener('error', event => {
      this.log('error ', {error_code: event, ws: this.currentWebSocket}, 'error');
      this.currentWebSocket = null;

      timer(20000).subscribe(() => this.connect(profile)); // Retry after 20 seconds
    });
  }

  public disconnect(): void {
    this.send({event: WEBSOCKET_EVENT.CLOSE, data: {}, timestamp: Math.round(+new Date())});

    this.shouldDisconnect = true;
    this.currentWebSocket?.close();
  }

  private log(message: string, data: unknown, level: 'log' | 'error' = 'log'): void {
    if (this.logEnabled) {
      this.logger[level]('[websocket]' + message, data);
    }
  }

  public constructor(
    private logger: NGXLogger,
    private authenticationStatusService: AuthenticationStatusService
  ) {
    interval(30000).subscribe(async () => {
      switch (this.currentWebSocket?.readyState) {
        case WebSocket.OPEN:
          return this.currentWebSocket?.send(JSON.stringify({event: 'ping', message: {}}));

        case WebSocket.CLOSED:
          return this.log('connection is closed...', {});

        case WebSocket.CONNECTING:
          return this.log('is connecting...', {});

        case WebSocket.CLOSING:
          return this.log('is closing connection...', {});

        case null:
          return this.log('no current websocket...', {});
      }
    });
  }
}
