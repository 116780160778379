import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {INGXLoggerConfig, LoggerModule, NGXLogger, NgxLoggerLevel} from "ngx-logger";
import {RequestCacheInterceptor} from "./shared/services/api/request-cache.interceptor";
import {HttpCacheService} from "./shared/services/api/http-cache.service";
import {CookieService} from "ngx-cookie-service";
import {ApiInterceptor} from "./shared/services/api/api.interceptor";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CollapseModule} from "ngx-bootstrap/collapse";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {Angulartics2Module} from "angulartics2";
import {RouterModule} from "@angular/router";
import {environment} from "../environments/environment";
import {GlobalErrorHandlerService} from "./shared/services/global-error-handler.service";
import {DatePipe} from "@angular/common";

const loggerConfig: INGXLoggerConfig = {
  level: environment.envName === 'production' ? NgxLoggerLevel.LOG : NgxLoggerLevel.DEBUG,
  disableConsoleLogging: environment.envName === 'production',
  serverLogLevel: undefined,
  serverLoggingUrl: undefined,
  disableFileDetails: false,
  timestampFormat: 'mm:ss',
  enableSourceMaps: true,
  //colorScheme: ['red', 'blue', 'green', 'yellow', 'white', 'black', 'black'],
};

// AoT requires an exported function for factories
function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    LoggerModule.forRoot(loggerConfig),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    NgbPopoverModule,
    CollapseModule,
    NgbModule,
    Angulartics2Module.forRoot()
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    {provide: DatePipe, useClass: DatePipe},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCacheInterceptor,
      deps: [HttpClient, NGXLogger, HttpCacheService, CookieService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      deps: [Injector],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
