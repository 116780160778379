<nav class="navbar navbar-expand bg-dark navbar-dark">
  <div class="container-fluid ps-2 pe-3">

    <app-logo [isHandballNet]="isHandballNet" class="d-flex"></app-logo>


    <div class="collapse navbar-collapse  justify-content-end">
      <app-autocomplete-input class="ms-auto d-none d-md-flex"></app-autocomplete-input>
      <ul class="navbar-nav ms-auto align-items-md-center">

        <li class="nav-item d-flex">
          <a class="nav-link text-nowrap"
             routerLink="/livestreams"
             routerLinkActive="active"
          ><span class="d-xl-none">{{'header.live_short' | translate}} </span><span class="d-none d-xl-inline">{{'header.live_long' | translate}} </span>
            <sup><span class="badge bg-danger" *ngIf="numberOfCurrentLivestreams && numberOfCurrentLivestreams >  0">
            {{(numberOfCurrentLivestreams > 1000 ? '1000+' : numberOfCurrentLivestreams |number)}}
            </span></sup>
          </a>
        </li>

        <li *ngIf="!isHandballNet" class="nav-item d-none d-lg-flex" >
          <div class="dropdown" dropdown>
            <button
              role="button"
              dropdownToggle
              class="nav-link btn px-0 px-md-2 dropdown-toggle"
            >
              {{ 'header.more_info' | translate }}
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right shadow" role="menu">
              <li role="menuitem">
                <a
                  class="dropdown-item font-weight-bold"
                  target="_blank"
                  rel="nofollow noopener"
                  referrerpolicy="no-referrer"
                  href="https://aktionen.sportdeutschland.tv/{{ localeService.currentLocale === availableLocale.EN ? 'en' : '' }}"
                >{{'header_more_info_menu.how_it_works' | translate}}</a>
              </li>

              <li role="menuitem">
                <a
                  class="dropdown-item font-weight-bold"
                  target="_blank"
                  rel="nofollow noopener"
                  referrerpolicy="no-referrer"
                  href="https://aktionen.sportdeutschland.tv/{{ localeService.currentLocale === availableLocale.EN ? 'en' : '' }}/smart-tv"
                >{{'header_more_info_menu.smart_tv_app' | translate}}</a>
              </li>

              <li role="menuitem">
                <a
                  class="dropdown-item font-weight-bold"
                  target="_blank"
                  rel="nofollow noopener"
                  referrerpolicy="no-referrer"
                  href="https://aktionen.sportdeutschland.tv/{{ localeService.currentLocale === availableLocale.EN ? 'en' : '' }}/sdtv-plus"
                >{{'header_more_info_menu.ad_free_streaming' | translate}}</a>
              </li>

              <li role="menuitem">
                <a
                  class="dropdown-item font-weight-bold"
                  target="_blank"
                  rel="nofollow noopener"
                  referrerpolicy="no-referrer"
                  href="https://aktionen.sportdeutschland.tv/{{ localeService.currentLocale === availableLocale.EN ? 'en' : '' }}/event-streamen"
                >{{'header_more_info_menu.how_to_stream' | translate}}</a>
              </li>

              <li role="menuitem">
                <a
                  class="dropdown-item font-weight-bold"
                  routerLink="/faq"
                >{{'header_more_info_menu.faq' | translate}}</a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item " *ngIf="(authenticationService.loggedIn$|async) === false">
          <a class="btn btn-primary mt-1 ms-2"
             [routerLink]="[{ outlets: { m: 'login' } }]"

          >
            {{'header.login' | translate}}
          </a>
        </li>

        <li class="nav-item"
            *ngIf="(authenticationService.loggedIn$|async) && profile && profile.type !== profileTypeEnum.VIEWER">
          <a
            class="nav-link text-nowrap d-flex mt-1"
            [routerLink]="'/profil/benachrichtigungen'"
          >
            <i class="d-flex fa fa-bell"></i><sup><span class="badge bg-danger small"
                                                        *ngIf="notificationsCount && notificationsCount > 0">{{ notificationsCount }}</span>
            <span class="sr-only">Neue Benachrichtigungen</span>
          </sup>
          </a>
        </li>

        <li class="nav-item" *ngIf="(authenticationService.loggedIn$|async) === true && profile">
          <div class="" dropdown placement="bottom right">

            <button
              role="button"
              [attr.aria-label]=" 'header_login_menu.open_login_menu' | translate "
              dropdownToggle
              class="nav-link btn px-0 px-md-2 dropdown-toggle"
            >
              <img
                [src]="profile | profileImage"
                imageError="./assets/placeholders/profil.svg"
                class="sdtv-profile-image sdtv-profile-image-small mr-1"
              />
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right shadow" role="menu">
              <li role="menuitem">
                <a
                  class="dropdown-item font-weight-bold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/meine-inhalte"
                  routerLinkActive="active"
                >{{'header_login_menu.my_content' | translate}}</a>
              </li>

              <ng-container *ngIf="profile?.type !== profileTypeEnum.VIEWER">
                <li role="menuitem">
                  <a
                    [routerLink]="profile.slug"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="dropdown-item font-weight-bold"
                    routerLinkActive="active"
                  >{{ 'header_login_menu.my_profile' | translate }}</a>
                </li>

                <li role="menuitem">
                  <a
                    routerLink="/profil/medien/neu"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="dropdown-item font-weight-bold"
                    routerLinkActive="active"
                  >{{ 'header_login_menu.new_post' | translate }}</a>
                </li>
                <li role="menuitem">
                  <a
                    routerLink="/profil/medien"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="dropdown-item font-weight-bold"
                    routerLinkActive="active"
                  >{{ 'header_login_menu.media_library' | translate }}</a>
                </li>

                <li role="menuitem" *ngIf="profile?.insights_enabled">
                  <a
                    routerLink="/profil/insights"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="dropdown-item"
                    routerLinkActive="active"
                  >{{ 'header_login_menu.insights' | translate }} <sup><span class="badge bg-primary text-black">Beta</span></sup></a>
                </li>
              </ng-container>

              <li role="menuitem">
                <a
                  routerLink="/profil/ich-folge"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="dropdown-item"
                  routerLinkActive="active"
                >{{ 'header_login_menu.i_follow' | translate }}</a>
              </li>
              <li role="menuitem">
                <a
                  routerLink="/profil/einstellungen"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="dropdown-item"
                  routerLinkActive="active"
                >{{ 'header_login_menu.settings' | translate }}</a>
              </li>


              <li role="menuitem" *ngIf="profile?.type !== 'viewer'">
                <a class="dropdown-item" href="https://aktionen.sportdeutschland.tv/streamer">{{ 'header_login_menu.streamer_app' | translate }}</a>
              </li>


              <li role="menuitem">
                <a class="dropdown-item " routerLink="/faq" [routerLinkActiveOptions]="{ exact: true }"
                   routerLinkActive="active">{{ 'header_login_menu.help' | translate }}</a>
              </li>

              <li role="menuitem" *ngIf="profile?.type !== 'viewer'">
                <a class="dropdown-item " [routerLink]="'/profil/creator-api'"
                   [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">{{ 'header_login_menu.creator_api' | translate }}</a>
              </li>

              <li role="menuitem" *ngIf="profile?.type !== 'viewer'">
                <a class="dropdown-item " [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="'/profil/hotline'" routerLinkActive="active">{{ 'header_login_menu.hotline' | translate }}</a>
              </li>

              <li role="menuitem">
                <button class="dropdown-item pointer" (click)="logout()">{{ 'header_login_menu.logout' | translate }}</button>
              </li>
            </ul>
          </div>

        </li>

        <app-language-switcher class="d-none d-sm-flex" idAffix="non-mobile"></app-language-switcher>

      </ul>


    </div>


  </div>
</nav>

<nav class="navbar border-bottom navbar-expand py-0 bg-dark navbar-dark border-top border-1 border-super-dark overflow-hidden pe-2">

  <button class="d-block  btn btn-link btn-dark  px-3 px-lg-4 border-1 rounded-0  py-2"
          (click)="openSidebar()">
    <i class="fa fs-4 fa-bars"></i>
  </button>


  <ul class="navbar-nav  w-100 flex-nowrap overflow-x-hidden sdtv-fade-out ">

    <li class="nav-item" *ngIf="activeTag">
      <span class="nav-link text-nowrap  text-primary "
      [routerLink]="'/videos/' + activeTag.slug"
      >{{activeTag.name}}</span>
    </li>

    <ng-container *ngIf="!isHandballNet">
      <li class="nav-item d-none d-sm-flex" *ngFor="let tag of topSportTypes">
        <a class="nav-link text-nowrap" *ngIf="tag.id !== activeTag?.id"  [routerLink]="'/videos/' + tag.slug">{{tag.name}}</a>
      </li>
    </ng-container>

    <li class="nav-item d-sm-none ms-auto" >
      <a routerLink="/suche" class="btn btn-link mt-1 ms-md-2 pe-0">
        <i class="fa fa-search"></i>
      </a>
    </li>

    <app-language-switcher class="d-sm-none" idAffix="mobile"></app-language-switcher>

  </ul>

  <button *ngIf="!isHandballNet" type="button" class="btn btn-secondary btn-sm text-nowrap ms-auto me-2 d-none d-sm-block" (click)="openSidebar(SIDEBAR_MENU.ALL_SPORT_TYPES)">
    {{'header.all_sport_types' | translate}}
  </button>
</nav>


