import {Pipe, PipeTransform} from '@angular/core';
import {ImageCdnService} from '../../shared/services/image-cdn.service';
import {BANNER_SQUARE_IMAGE_SIZE} from "../../../enums/BANNER_SQUARE_IMAGE_SIZE";

@Pipe({
  name: 'bannerSquareImage',
})
export class BannerSquareImagePipe implements PipeTransform {
  public transform(
    value: string | undefined,
    args: { size: BANNER_SQUARE_IMAGE_SIZE; imageError?: string | undefined } = {
      size: BANNER_SQUARE_IMAGE_SIZE.XL_600_500,
      imageError: undefined,
    },
  ): string {

    if (!value) {
      return args.imageError ?? '/assets/placeholders/1x1.svg';
    }

    return this.imageCdnService.getImageFqdn(value, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {
  }
}
