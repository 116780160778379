import {Pipe, PipeTransform} from '@angular/core';
import {ProfilePageResource} from '../../entities/Profile.entity';
import {ImageCdnService} from '../../shared/services/image-cdn.service';
import {PROFILE_CAROUSEL_BG_IMAGE_SIZE} from "../../../enums/PROFILE_CAROUSEL_BG_IMAGE_SIZE";

@Pipe({
  name: 'profileCarouselBackgroundImageSize',
})
export class ProfileCarouselBackgroundImageSizePipe implements PipeTransform {
  public transform(
    value: ProfilePageResource | undefined,
    args: { size: PROFILE_CAROUSEL_BG_IMAGE_SIZE } = {
      size: PROFILE_CAROUSEL_BG_IMAGE_SIZE.DEFAULT_1800_860,
    },
  ): string {
    if (!value || !value.carousel_bg_image_id || value.carousel_bg_image_id.startsWith('http')) {
      return '';
    }

    return this.imageCdnService.getImageFqdn(value.carousel_bg_image_id, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {
  }
}
