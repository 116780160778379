import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ClipboardService} from 'ngx-clipboard';
import {AssetResource} from '../../../entities/asset.entity';
import {ASSET_FLAG} from '../../../../enums/ASSET_FLAG';
import {environment} from '../../../../environments/environment';
import {SeoMetaTagsService} from '../../services/seo-meta-tags.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-embed-modal',
  templateUrl: './embed-modal.component.html',
})
export class EmbedModalComponent implements OnInit {
  public closeEvent: EventEmitter<void> = new EventEmitter();
  public asset?: AssetResource;
  public assetFlags = ASSET_FLAG;
  public copied = false;
  public code?: string;

  public copy(): void {
    if (!this.code) {
      return;
    }
    navigator.clipboard.writeText(this.code).then(
      () => {
        this.copied = true;
        this.toastr.info(this.translate.instant('embed_modal.success'));
      },
      () => this.toastr.error(this.translate.instant('embed_modal.error_while_copying')),
    );

    this.cdr.markForCheck();
  }

  public ngOnInit(): void {
    this.seoMetaTagsService.updateMetaTags({
      indexFollow: false,
      canonicalURL: 'https://sportdeutschland.tv/' + this.asset?.profile.slug + '/' + this.asset?.slug,
    });
  }

  public constructor(
    private seoMetaTagsService: SeoMetaTagsService,
    private toastr: ToastrService,
    private router: Router,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
    let baseUrl;
    switch (environment.envName) {
      case 'production':
        baseUrl = 'https://player.sportdeutschland.tv/';
        break;
      case 'stage':
        baseUrl = 'https://player.sdtv-stage.teraone-preview.de/';
        break;
      case 'local':
        baseUrl = 'https://player.sdtv.test:4203/';
        break;
    }

    const state = router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.asset = state['asset'];
      this.code = '<iframe src="' + baseUrl + this.asset?.id + '" width=640 height="420" frameborder=0 allowfullscreen></iframe>';
    }
  }
}
