import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {delay} from 'rxjs/operators';

@Directive({
  selector: '[trackVisibility]',
})
export class ListenToFirstFullyVisibleEvent implements OnDestroy, OnInit, AfterViewInit {
  @Input() public debounceTime = 0;
  @Input() public threshold = 1;
  @Output() public visible: EventEmitter<boolean> = new EventEmitter<boolean>();

  private intersectionObserver: IntersectionObserver | undefined;
  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();

  public ngOnInit(): void {
    this.createObserver();
  }

  public ngAfterViewInit(): void {
    this.startObservingElements();
  }

  public ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = undefined;
    }
    this.subject$.complete();
  }

  private isVisible(element: HTMLElement): Promise<boolean> {
    return new Promise(resolve => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio === 1);
        observer.disconnect();
      });

      observer.observe(element);
    });
  }

  private createObserver(): void {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold,
    };

    const isIntersecting = (entry: IntersectionObserverEntry): boolean => entry.isIntersecting || entry.intersectionRatio > 0;

    this.intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (isIntersecting(entry)) {
          this.subject$.next({entry, observer});
        }
      });
    }, options);
  }

  private startObservingElements(): void {
    if (!this.intersectionObserver) {
      return;
    }

    this.intersectionObserver.observe(this.element.nativeElement);

    this.subject$.pipe(delay(this.debounceTime)).subscribe(async ({entry, observer}) => {
      const isStillVisible = await this.isVisible(entry.target as HTMLElement);

      this.visible.emit(isStillVisible);
      if (isStillVisible) {
        observer.unobserve(entry.target);
      }
    });
  }

  public constructor(private element: ElementRef) {
  }
}
