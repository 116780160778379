import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

import {NGXLogger} from 'ngx-logger';
import {PLAYER_STATE} from '../enums/player-state';
import {ASSET_TYPE} from '../../../enums/ASSET_TYPE';
import {
  AssetResource,
  HydratedAsset,
  VideoResource,
  VideoResourceOrPublicLiveStreamResource
} from "../../entities/asset.entity";

@Injectable()
export class AssetHydrationService {
  public playerStateEnum = PLAYER_STATE;

  /**
   * Adds an array of source objects to every Video of an Asset. Adds an empty array, if no source can be found.
   * Only throws one of PlayerErrorEnum Errors
   * @param asset Asset which videos' should be hydrated
   */
  public hydrateVideosOfAssetWithSource(asset: AssetResource): Observable<HydratedAsset> {
    const playables: VideoResourceOrPublicLiveStreamResource[] = asset.videos.filter(
      (v: VideoResource, index: number) => v.src !== null && index < 20,
    );
    if (asset.livestream) {
      playables.unshift(asset.livestream);
    }
    return forkJoin(
      playables
        .filter(v => v.src !== null)
        .map((video, index) => {
          return this.geSourceObjectForMuxVideoOrLiveStream(asset, video, index);
        }),
    ).pipe(
      map(src => {
        const ret: HydratedAsset = {
          ...asset,
          videoSource: src,
        };
        return ret;
      }),
    );
  }

  public geSourceObjectForMuxVideoOrLiveStream(
    asset: AssetResource,
    src: VideoResourceOrPublicLiveStreamResource,
    index: number,
  ): Observable<{ name: string; type: string; src: string }> {
    if (src.src === null) {
      throw new Error(PLAYER_STATE.MISSING_VIDEO_SOURCE);
    }
    return this.http
      .get<{ token: string }>('/web/personal/asset-token/' + asset.id + '?type=' + src.type + '&playback_id=' + encodeURIComponent(src.src))
      .pipe(
        map(r => {
          return {
            src: 'https://stream.mux.com/' + src.src + '.m3u8?token=' + encodeURIComponent(r.token),
            type: 'application/x-mpegURL',
            name: src.type === 'mux_vod' ? 'Teil ' + (index + 1) : 'Livestream',
          };
        }),
      );
  }

  public canAccessAsset(asset: AssetResource): Observable<boolean> {
    let type = 'mux_vod';

    if (asset.type === ASSET_TYPE.LIVESTREAM) {
      type = 'mux_live';
    }

    return this.http.get<{ token: string }>('/web/personal/asset-token/' + asset.id + '?type=' + type).pipe(map(r => !!r.token));
  }

  public constructor(private http: HttpClient, private log: NGXLogger) {}
}
