export enum ASSET_FLAG {
  NO_ADS = 'NO_ADS',
  NO_MIDROLL = 'NO_MIDROLL',
  NO_PREROLL = 'NO_PREROLL',
  NO_SHARE = 'NO_SHARE',
  NO_CHAT = 'NO_CHAT',
  NO_EMBED = 'NO_EMBED',
  NO_LOGO = 'NO_LOGO',
  NO_RELATED = 'NO_RELATED',
  LOGO_BOTTOM = 'LOGO_BOTTOM',
  LOGO_LEFT = 'LOGO_LEFT',
  SPORT_A = 'SPORT_A',
  HIGHLIGHT = 'HIGHLIGHT',
  SHORT_CLIP = 'SHORT_CLIP',
}
