import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {timer} from 'rxjs';

export enum EVENT_NAMES {
  EVENT_VIDEO_VIEW = 2,
}

export interface StatisticEvent {
  event: EVENT_NAMES;
  content_id: string;
  time: number;
  watch_time_start?: number;
  watch_time_end?: number;
}

export interface AssetVideoView extends StatisticEvent {
  event: EVENT_NAMES.EVENT_VIDEO_VIEW;
  content_id: string;
  time: number;
}

@Injectable({
  providedIn: 'root',
})
export class EventTrackingService {
  private events: StatisticEvent[] = [];
  private timeOffset = 0;

  public pushVideoViewEvent(assetId: string): void {
    const assetEvent: AssetVideoView = {
      event: EVENT_NAMES.EVENT_VIDEO_VIEW,
      content_id: assetId,
      time: this.getTime(),
    };

    this.events.push(assetEvent);
  }

  public getTime(): number {
    return Math.floor((+new Date() + this.timeOffset) / 1000);
  }

  private getTimeOffset(): void {
    const beforeRequest = +new Date(); // 100
    this.http.get<{ t: number }>('/statistics/time', {withCredentials: false}).subscribe(
      res => {
        const afterRequest = +new Date(); // 120
        const requestDuration = afterRequest - beforeRequest; // 20

        // res t  == 1000
        this.timeOffset = res.t - beforeRequest - requestDuration; // 1000 - 100 - 20 = +880= -> d.h. die serverzeit liegt 880 ms in der zukunft
      },
      () => {
        // ignore errors
        this.timeOffset = 0;
      },
    );
  }

  private sendEventsToServer(): void {
    const eventsToPush = this.events.splice(0, 100);
    // this.log.log('[statistical events] send events to server', this.events, eventsToPush);
    if (eventsToPush.length > 0) {
      this.http
        .post('/statistics/e', {
          events: eventsToPush,
        })
        .subscribe(
          () => {
            //events pushed
          },
          () => {
            this.log.error('[statistical events] failed to push events', eventsToPush);
          },
        );
    }
  }

  public constructor(private http: HttpClient, private log: NGXLogger) {
    this.getTimeOffset();
    // take care: running a timer in a constructor might cause an infinite loop with SSR
    timer(4000, 10000).subscribe(() => this.sendEventsToServer());

  }
}
