import {EventEmitter, Injectable} from "@angular/core";
import {TooManyDevicesResponseError} from "../components/too-many-devices/too-many-devices.response";


@Injectable({
  providedIn: 'root',
})
export class TooManyDevicesEventService {

  public tooManyDevicesResponseErrorSubject = new EventEmitter<TooManyDevicesResponseError>
  public tooManyDevicesResponseErrorMitigated = new EventEmitter<void>
  public tooManyDevicesResponseErrorCancelled = new EventEmitter<void>
}


