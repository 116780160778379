import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TagComponent} from './components/tag/tag.component';
import {DisplayAdComponent} from './components/display-ad/display-ad.component';
import {AssetCardComponent} from './components/asset-card/asset-card.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TopbannerComponent} from './components/topbanner/topbanner.component';
import {ModalComponent} from './components/modal/modal.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormGroupComponent} from './components/form-group/form-group.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TagsInputComponent} from './components/tags-input/tags-input.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AutocompleteInputComponent} from './components/autocomplete-input/autocomplete-input.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {MediumRectangleComponent} from './components/medium-rectangle/medium-rectangle.component';
import {ShareModalComponent} from './components/share-modal/share-modal.component';
import {ClipboardModule} from 'ngx-clipboard';
import {ToastrModule} from 'ngx-toastr';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {EmbedModalComponent} from './components/embed-modal/embed-modal.component';
import {DateFnsConfigurationService, DateFnsModule} from 'ngx-date-fns';
import {de} from 'date-fns/locale';
import {CustomToastComponent} from './components/custom-toast/custom-toast.component';
import {TagCardComponent} from './components/tag-card/tag-card.component';
import {InputSearchService} from './components/tags-input/services/tags-input-search.service';
import {InfiniteScrollComponent} from './components/infinite-scroll/infinite-scroll.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {ImageErrorDirective} from './image-error.directive';
import {FollowProfileBtnComponent} from './components/profile-button/follow-profile-btn/follow-profile-btn.component';
import {FollowTagBtnComponent} from './components/follow-tag-btn/follow-tag-btn.component';
import {CookieService} from 'ngx-cookie-service';
import {TrackAssetImpressionWhenVisible} from './directives/track-asset-impression-when-visible.directive';
import {ListenToFirstFullyVisibleEvent} from './directives/listen-to-first-fully-visible-event.directive';
import {RichSnippetComponent} from './components/rich-snippet/rich-snippet.component';
import {SwiperModule} from "swiper/angular";
import {MY_STORAGE_SERVICE, StorageService} from "./services/storage.service";
import {LOCAL_STORAGE} from "ngx-webstorage-service";
import {AnalyticsService} from "./services/analytics.service";
import {SomtagService} from "./services/somtag.service";
import {AdBlockerDetectionService} from "./services/ad-blocker-detection.service";
import {AuthenticationService} from "./services/authentication.service";
import {HttpCacheService} from "./services/api/http-cache.service";
import {FollowingService} from "./services/following.service";
import {ImageCdnService} from "./services/image-cdn.service";
import {HeaderComponent} from "./components/header/header.component";
import {TranslateModule} from "@ngx-translate/core";
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ConsentWallComponent} from "./components/consent-wall/consent-wall.component";
import {LogoComponent} from './components/logo/logo.component';
import {LoginComponent} from "./components/login/login.component";
import {RegisterComponent} from "./components/register/register.component";
import {LocaleService} from "./services/locale.service";
import {NgxWebstorageModule} from "ngx-webstorage";
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {SidebarAndHeaderService} from "./services/sidebar-and-header.service";
import {AutosizeModule} from "ngx-autosize";
import {ContentGridComponent} from "./components/content-grid/content-grid.component";
import {ProfileCardComponent} from "./components/profile-card/profile-card.component";
import {AssetMenuComponent} from "./components/asset-dropdown-menu/asset-menu.component";
import {PlaylistCarouselComponent} from "./components/playlist-carousel/playlist-carousel.component";
import {CoverflowComponent} from "./components/coverflow/coverflow.component";
import {AssetRowComponent} from "./components/asset-row/asset-row.component";
import {GameTableComponent} from "./components/game-schedule/game-table.component";
import {PlayerModule} from "../player/player.module";
import {PipesModule} from "../pipes-module/pipes.module";
import {CurrentlyLiveService} from "./services/currently-live.service";
import {UserActivityService} from "./services/user-activity.service";
import {ProfileImageForIdPipe} from "../pipes-module/pipes/profile-image-for-id.pipe";
import {ProfileTeaserImagePipe} from "../pipes-module/pipes/profile-teaser-image.pipe";
import {SportTypeTagComponent} from './components/sport-type-tag/sport-type-tag.component';
import {AssetDateNoTimePipe} from "../pipes-module/pipes/asset-date-no-time.pipe";
import {AssetDateTimePipe} from "../pipes-module/pipes/asset-date-time.pipe";
import {AssetCarouselComponent} from "./components/asset-carousel/asset-carousel.component";
import {RedirectComponent} from "./components/redirect/redirect.component";
import {FireplaceAdService} from "./services/fireplace-ad.service";
import {TooManyDevicesComponent} from "./components/too-many-devices/too-many-devices.component";
import { DeviceListComponent } from './components/too-many-devices/device-list/device-list.component';
import { GothaerTileCardComponent } from './components/gothaer-tile-card/gothaer-tile-card.component';
const germanDateConfig = new DateFnsConfigurationService();
germanDateConfig.setLocale(de);

@NgModule({
  declarations: [
    ContentGridComponent,
    SidebarComponent,
    HeaderComponent,
    TagsInputComponent,
    DisplayAdComponent,
    TagCardComponent,
    TagComponent,
    TopbannerComponent,
    MediumRectangleComponent,
    AssetCardComponent,
    ModalComponent,
    FormGroupComponent,
    DropdownComponent,
    AutocompleteInputComponent,
    CustomToastComponent,
    InfiniteScrollComponent,
    ShareModalComponent,
    EmbedModalComponent,
    ImageErrorDirective,
    FollowProfileBtnComponent,
    FollowTagBtnComponent,
    TrackAssetImpressionWhenVisible,
    ListenToFirstFullyVisibleEvent,
    RichSnippetComponent,
    ConsentWallComponent,
    LogoComponent,
    LoginComponent,
    RegisterComponent,
    LanguageSwitcherComponent,
    ProfileCardComponent,
    AssetMenuComponent,
    PlaylistCarouselComponent,
    AssetRowComponent,
    CoverflowComponent,
    AssetCardComponent,
    GameTableComponent,
    ProfileImageForIdPipe,
    ProfileTeaserImagePipe,
    SportTypeTagComponent,
    AssetDateNoTimePipe,
    AssetDateTimePipe,
    AssetCarouselComponent,
    RedirectComponent,
    TooManyDevicesComponent,
    DeviceListComponent,
    GothaerTileCardComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    ClipboardModule,
    FormsModule,
    TranslateModule,
    ModalModule.forRoot(),
    DateFnsModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    SwiperModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      countDuplicates: true,
      onActivateTick: true,
      maxOpened: 3,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      timeOut: 5000,
      extendedTimeOut: 1000,
      tapToDismiss: true,
      closeButton: true,
    }),
    NgxWebstorageModule.forRoot(),
    AutosizeModule,
    PlayerModule,
    PipesModule,
    NgOptimizedImage
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SidebarComponent,
    HeaderComponent,
    TagsInputComponent,
    DisplayAdComponent,
    TagCardComponent,
    TagComponent,
    TopbannerComponent,
    MediumRectangleComponent,
    AssetCardComponent,
    ModalComponent,
    FormGroupComponent,
    DropdownComponent,
    AutocompleteInputComponent,
    CustomToastComponent,
    InfiniteScrollComponent,
    ShareModalComponent,
    EmbedModalComponent,
    LoginComponent,
    ImageErrorDirective,
    FollowProfileBtnComponent,
    FollowTagBtnComponent,
    TrackAssetImpressionWhenVisible,
    ListenToFirstFullyVisibleEvent,
    RichSnippetComponent,
    ConsentWallComponent,
    RegisterComponent,
    ContentGridComponent,
    ProfileCardComponent,
    AssetMenuComponent,
    PlaylistCarouselComponent,
    AssetRowComponent,
    CoverflowComponent,
    AssetCardComponent,
    GameTableComponent,
    ProfileImageForIdPipe,
    ProfileTeaserImagePipe,
    SportTypeTagComponent,
    AssetCarouselComponent,
    DeviceListComponent,
    GothaerTileCardComponent,
  ],
  providers: [
    FollowingService,
    {provide: MY_STORAGE_SERVICE, useExisting: LOCAL_STORAGE},
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CurrentlyLiveService,
        AuthenticationService,
        InputSearchService,
        CookieService,
        StorageService,
        AnalyticsService,
        SomtagService,
        AdBlockerDetectionService,
        HttpCacheService,
        FollowingService,
        ImageCdnService,
        LocaleService,
        SidebarAndHeaderService,
        SwiperModule,
        UserActivityService,
        FireplaceAdService
      ],
    };
  }
}
