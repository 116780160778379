
export enum LOGIN_REASON_CODES {
  SEE_MEDIA = 'SEE_MEDIA',
  SEE_LIVESTREAM = 'SEE_LIVESTREAM',
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  UPLOAD_VIDEO = 'UPLOAD_VIDEO',
  LOGIN_FIRST = 'LOGIN_FIRST',
  SEE_INSIGHTS = 'SEE_INSIGHTS',
  SEE_FOLLOWED_PROFILES = 'SEE_FOLLOWED_PROFILES',
  CHANGE_COUNTRY_SETTINGS = 'CHANGE_COUNTRY_SETTINGS',
  SEE_ASSETS = 'SEE_ASSETS',
  REGISTER_SMART_TV = 'REGISTER_SMART_TV',
  UPDATE_PASSWORD_LINK_EXPIRED = 'UPDATE_PASSWORD_LINK_EXPIRED',
  PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  UPDATE_PASSWORD_LINK_INVALID = 'UPDATE_PASSWORD_LINK_INVALID',
  DEFAULT = 'DEFAULT',
  REGISTER_EMAIL_CONFIRMED = "REGISTER_EMAIL_CONFIRMED",
  REGISTER_LINK_EXPIRED = "REGISTER_LINK_EXPIRED EMAIL_CONFIRMED"
}
