import {EventEmitter, Injectable, Optional, SkipSelf} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable, of, Subject, switchMap} from 'rxjs';
import {delay, filter, map, retryWhen, take, tap} from 'rxjs/operators';
import {EVENT_ACTION, EVENT_CATEGORY} from '../../../types/gtm-options';
import {AuthenticationService} from "./authentication.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ConsentWallComponent} from "../components/consent-wall/consent-wall.component";
import {ActivationEnd, Router} from "@angular/router";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {ServiceData, UserCentrics} from "../../../types/UserCentrics";


@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public consentsChanged$: EventEmitter<void> = new EventEmitter<void>();
  public analyticsEvent$: Subject<{
    category: EVENT_CATEGORY;
    action: EVENT_ACTION;
  }> = new Subject<{
    category: EVENT_CATEGORY;
    action: EVENT_ACTION;
  }>();
  private readonly loggingEnabled: boolean = false;
  private modalRef?: NgbModalRef;


  public getTCString(): Observable<string> {
    return new Observable<string>(observer => {
      this.initialized$.subscribe({
        next: () => {
          window.__tcfapi('getTCData', 2, (tcData, success) => {
            if (success && tcData && tcData.tcString) {
              this.log('tcString from tcfAPI ', tcData);
              observer.next(tcData.tcString);
              observer.complete();
            } else {
              observer.error('Failed to retrieve tcString');
            }
          });
        },
        error: err => observer.error(err)
      });
    });
  }

  public tcfVendorConsentGiven(vendor: number): Observable<boolean> {

    return new Observable<boolean>(observer => {
      this.initialized$.subscribe({
        next: () => {
          window.__tcfapi('getTCData', 2, (tcData, success) => {
            if (success && tcData && tcData.vendor && tcData.vendor.consents) {
              this.log('tcfVendorConsentGiven from tcfAPI ', {vendor: !!tcData.vendor.consents[vendor]});
              observer.next(!!tcData.vendor.consents[vendor]);
              observer.complete();
            } else {
              observer.error('Failed to retrieve tcString');
            }
          });
        },
        error: err => observer.error(err)
      });
    });
  }

  public allConsentsGiven(): Observable<boolean> {
    return this.initialized$.pipe(
      filter(initialized => initialized),
      take(1),
      switchMap(() => {
        return this.getUserCentrics().pipe(
          switchMap((userCentrics) => userCentrics.getConsentDetails()),
          map((consentDetails) => {

            const allConsentsGiven = consentDetails.consent.status === 'ALL_ACCEPTED';
            const newConsentsRequired = consentDetails.consent.required;

            this.log('[consent service], new Consents required?', {newConsentsRequired});
            this.log('[consent service], all Consents given?', {allConsentsGiven});

            this.log('allConsentsGiven', {'allGiven' :  !newConsentsRequired && allConsentsGiven})
            // Is true when consent is not required anymore and all consents were given
            // If all consent was given but the consent is generally required (resurface, new consent version, etc.)
            // Then allConsentsGiven doesn't really matter since it basically invalidates the old allConsentsGiven
            return !newConsentsRequired && allConsentsGiven;
          })
        );
      })
    );
  }


  public showCMP(): void {
    this.getUserCentrics().subscribe(
      userCentrics => {
        userCentrics.showFirstLayer();
      }
    )
  }


  public consentGiven(consentId: string): Observable<boolean> {
    return this.initialized$.pipe(
      filter(initialized => initialized),
      switchMap(() => {
        if (this.authenticationService.profile$.getValue()?.has_sportdeutschland_plus) {
          return of(false);
        }
        return this.getUserCentrics().pipe(
          switchMap((userCentrics) => userCentrics.getConsentDetails()),
          map((consentDetails) => {
              const service: ServiceData | undefined = consentDetails.services[consentId];
              if (!service) {
                this.ngxLogger.error('[consent service] tried to check consent, but it does not exist in the CMP', {consentId: consentId});
                return false;
              }
              if (this.loggingEnabled) {
                this.log('[consent service] consent given? ', {
                  consentId,
                  name: service.name,
                  given: service.consent?.given
                }, 'log');
              }
              return service?.consent?.given ?? false;
            }
          ))
      })
    )
  }

  public anyConsentDecisionWasMade(): Observable<boolean> {
    return this.getUserCentrics().pipe(
      switchMap((userCentrics) => userCentrics.getConsentDetails()),
      map((consentDetails) => {
        // Is true when consent is not required anymore and a decision was already made
        return !consentDetails.consent.required && consentDetails.consent.type === 'EXPLICIT'
      }))
  }


  public hideConsentWallIfShown(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  public showConsentWall(): void {
    this.log('showConsentWall')
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.modalRef = this.modalService.open(ConsentWallComponent, {
      size: 'xl',
      centered: true,
      animation: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  public acceptAll(): Observable<void> {
    return this.getUserCentrics().pipe(
      switchMap(userCentrics => fromPromise(userCentrics.acceptAllConsents())),
      switchMap(() => this.getUserCentrics()),
      switchMap((userCentrics) => fromPromise(userCentrics.saveConsents('EXPLICIT'))),
    )
  }

  public denyAllForSportdeutschlandPlus(): void {

    this.initialized$.pipe(
      filter(init => init),
      take(1),
      switchMap(() => this.getUserCentrics()),
      switchMap((userCentrics) => fromPromise(userCentrics.denyAllConsents())),
      switchMap(() => this.getUserCentrics()),
      switchMap((userCentrics) => fromPromise(userCentrics.saveConsents('EXPLICIT'))),
    ).subscribe(() => {
      this.log('all consents denied for sdtv plus');
    })


  }

  private showConsentWallIfNeeded(): void {
    this.initialized$.pipe(
      filter(init => init),
      take(1),
      switchMap(() => this.allConsentsGiven()),
      filter(allConsentsGiven => !allConsentsGiven),
      take(1),
      tap(() => this.log('showConsentWallIfNeeded: NOT ALL GIVEN')),
      switchMap(() => this.authenticationService.user$),
      tap(u => {
        this.log("showConsentWallIfNeeded: user has sdtv plus?", {'hasSdtvPlus': u?.profile.has_sportdeutschland_plus ?? false
      })
        if (u && u.profile.has_sportdeutschland_plus) {
          this.hideConsentWallIfShown();
          this.anyConsentDecisionWasMade().subscribe(
            (decisionMade) => {
              if (!decisionMade) {
                this.denyAllForSportdeutschlandPlus();

              }
            }
          )
        }
      }),
      filter((u) => !(u?.profile.has_sportdeutschland_plus ?? false)),
      take(1),
      tap(() => this.log('showConsentWallIfNeeded: no sdtv plus detected')),
      filter(() => {
        if (
          !this.router.url.startsWith('/sportdeutschland-tv-plus')
          && !this.router.url.startsWith('/kasse')
          && !this.router.url.startsWith('/datenschutz')
          && !this.router.url.startsWith('/p/datenschutz')
          && !this.router.url.startsWith('/agb')
          && !this.router.url.startsWith('/p/agb')
          && !this.router.url.startsWith('/impressum')
          && !this.router.url.startsWith('/p/impressum')
          && !this.router.url.startsWith('/widerrufsbelehrung')
          && !this.router.url.startsWith('/p/widerrufsbelehrung')
          && !this.router.url.startsWith('/cookiehinweise')
          && !this.router.url.startsWith('/p/cookiehinweise')
          && !this.router.url.includes('(m:')
        ) {
          return true;
        }
        this.log('showConsentWallIfNeeded: hide consent wall if shown')
        this.hideConsentWallIfShown();
        return false

      }),
      take(1),
    ).subscribe(
      () => {
        this.log('showConsentWallIfNeeded: show consent wall')
        this.showConsentWall()

      }
    )
  }

  private getUserCentrics(): Observable<UserCentrics> {
    return of(window.__ucCmp).pipe(
      retryWhen(errors => errors.pipe(
        tap(() => {
          this.log('Could not load CMP. Retrying in 0.05 seconds...');
        }),
        delay(50) // Wait for 0.05 seconds before retrying
      )),
      map(() => window.__ucCmp as UserCentrics)
    )

  }


  private log(message: string, data: unknown = {}, severity: 'log' | 'error' | 'warn' = 'log'): void {
    if (this.loggingEnabled) {
      if (data) {
        this.ngxLogger[severity](' [consent service]  ' + message, data);
      } else {
        this.ngxLogger[severity]('  [consent service]  ' + message);
      }
    }
  }

  private hideUserCentricsConsentBanner(): void {
    if (this.initialized$.getValue()) {
      this.getUserCentrics().subscribe(
        userCentrics => {
          userCentrics.closeCmp();
        }
      );
    } else {
      this.initialized$
        .pipe(
          filter(init => init),
          take(1),
          switchMap(() => this.getUserCentrics())
        )
        .subscribe((userCentrics) => {
          userCentrics.closeCmp();
        });
    }

  }

  public constructor(
    private ngxLogger: NGXLogger,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    @Optional()
    @SkipSelf()
      otherInstance: ConsentService,
  ) {
    if (otherInstance) throw 'ConsentService should have only one instance.';
    this.router.events.pipe(
      filter(e => e instanceof ActivationEnd)
    ).subscribe(
      () => {
        this.log('router event call showConsentWallIfNeeded')
        this.showConsentWallIfNeeded()

      }
    )
    /**
     * hide the UC consent banner always!
     */
    this.hideUserCentricsConsentBanner();


    window.addEventListener('UC_UI_INITIALIZED', () => {
      this.initialized$.next(true);
    });


    this.getUserCentrics().pipe(
      switchMap((userCentrics) => userCentrics.isInitialized())
    ).subscribe({
      next: initialized => {
        this.log('CMP loaded and initialized.', {initialized});
        this.initialized$.next(true)
      },
      error: err => {
        this.log('Could not load CMP. CMP is not defined in window.', err, 'error');
      }
    })


    window.addEventListener('consent_event', event => {
      this.consentsChanged$.next()

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const reason: string = event.detail.action;


      if (reason === 'onInitialPageLoad') {
        return;
      }
      this.log('consent_event: showConsentWallIfNeeded')
      this.showConsentWallIfNeeded();
    });

    window.addEventListener('UC_CONSENT', () => {
      this.consentsChanged$.next()
    });

    this.authenticationService.profile$.subscribe(
      p => {
        if (p?.has_sportdeutschland_plus) {
          this.denyAllForSportdeutschlandPlus()
        }
      }
    )
  }

}
