import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShareModalComponent} from "./shared/components/share-modal/share-modal.component";
import {EmbedModalComponent} from "./shared/components/embed-modal/embed-modal.component";
import {IsLoggedInGuard} from "./shared/guards/is-logged-in.guard";
import {LOGIN_REASON_CODES} from "../enums/LOGIN_REASON_CODES";
import {DelayedPreloadingStrategy} from "./DelayedPreloadingStrategy";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./page-modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'livestreams',
    loadChildren: () => import('./page-modules/live/live.module').then(m => m.LiveModule),
  },
  {
    path: 'videos/:tag',
    loadChildren: () => import('./page-modules/tag/tag-page.module').then(m => m.TagPageModule),
  },

  {
    path: 'kasse',
    loadChildren: () => import('./page-modules/checkout-page/checkout-pages.module').then(m => m.CheckoutPagesModule),
  },

  {
    path: 'login',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'anmelden',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'neues-produkt',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/product-create/product-create.module').then(m => m.ProductCreateModule),
  },
  {
    path: 'produkt-bearbeiten/:id',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/product-edit/product-edit.module').then(m => m.ProductEditModule),
  },
  {
    path: 'produkt-loeschen/:id',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/product-delete/product-delete.module').then(m => m.ProductDeleteModule),
  },
  {
    path: 'loeschen',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/profile-delete/profile-delete.module').then(m => m.ProfileDeleteModule),
  },
  {
    path: 'neue-rtmp-url',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/livestream-create/livestream-create.module').then(m => m.LivestreamCreateModule),
  },
  {
    path: 'beitrag-loeschen/:asset',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/asset-delete/asset-delete.module').then(m => m.AssetDeleteModule),
  },
  {
    path: 'video-erstellt/:asset',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/asset-type-video-created/asset-type-video-created.module').then(m => m.AssetTypeVideoCreatedModule),
  },
  {
    path: 'livestream-erstellt/:asset',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/asset-type-livestream-created/asset-type-livestream-created.module').then(m => m.AssetTypeLivestreamCreatedModule),
  },
  {
    path: 'beitrag-erstellt/:asset',
    outlet: 'm',
    loadChildren: () => import('./modal-pages-modules/asset-type-imagepost-created/asset-type-imagepost-created.module').then(m => m.AssetTypeImagepostCreatedModule),
  },
  {
    path: 'teilen',
    outlet: 'm',
    component: ShareModalComponent,
  },
  {
    path: 'embed',
    outlet: 'm',
    component: EmbedModalComponent,
  },
  {
    path: 'inhaberschaft-beantragen',
    outlet: 'm',
    loadChildren: () =>
      import('./modal-pages-modules/request-profile-ownership/request-profile-ownership.module').then(m => m.RequestProfileOwnershipModule),
  },

  {
    path: 'r',
    loadChildren: () => import('./page-modules/auth/auth-pages.module').then(m => m.AuthPagesModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./page-modules/faq-page/faq-page.module').then(m => m.FaqPageModule),
  },
  {
    path: 'widerrufsbelehrung',
    loadChildren: () => import('./page-modules/widerrufsbelehrung-page/widerrufsbelehrung-page.module').then(m => m.WiderrufsbelehrungPageModule),
  },
  {
    path: 'sportdeutschland-tv-plus',
    loadChildren: () =>
      import('./page-modules/sportdeutschland-plus-landing-page/sportdeutschland-plus-landing-page.module').then(
        m => m.SportdeutschlandPlusLandingPageModule,
      )
  },

  {
    path: 'profil',
    loadChildren: () => import('./page-modules/login-pages/login-pages.module').then(m => m.LoginPagesModule),
  },

  {
    path: 'payment-zusatzvereinbarung',
    loadChildren: () =>
      import('./page-modules/payment-zusatzvereinbarung-page/payment-zusatzvereinbarung-page.module').then(m => m.PaymentZusatzvereinbarungPageModule),
  },
  {
    path: 'vertrag-kuendigen',
    loadChildren: () => import('./page-modules/contract-cancellation-page/contract-cancellation-page.module').then(m => m.ContractCancellationPageModule),
  },

  {
    path: 'event-streamen',
    loadChildren: () => import('./page-modules/publisher-landing-page/publisher-landing-page.module').then(m => m.PublisherLandingPageModule),
  },

  {
    path: 'streamer',
    loadChildren: () => import('./page-modules/streamer-landing-page/streamer-landing-page.module').then(m => m.StreamerLandingPageModule),
  },

  {
    path: 'l/abmelden',
    loadChildren: () => import('./page-modules/unsubscribe-page/unsubscribe-page.module').then(m => m.UnsubscribePageModule),
  },

  {
    path: 'tv',
    loadChildren: () => import('./page-modules/smart-tv-login/smart-tv-login.module').then(m => m.SmartTvLoginModule),
  },
  {
    path: 'newsfeed', redirectTo: 'meine-inhalte'
  },
  {path: 'datenschutz', redirectTo: 'p/datenschutz'},
  {path: 'datenschutz-app', redirectTo: 'p/datenschutz-app'},
  {path: 'datenschutz-stv', redirectTo: 'p/datenschutz-stv'},
  {path: 'cookiehinweise', redirectTo: 'p/cookiehinweise'},
  {path: 'cookiehinweise-app', redirectTo: 'p/cookiehinweise-app'},
  {path: 'cookiehinweise-stv', redirectTo: 'p/cookiehinweise-stv'},
  {path: 'agb', redirectTo: 'p/agb'},
  {path: 'agb-app', redirectTo: 'p/agb-app'},
  {path: 'agb-stv', redirectTo: 'p/agb-stv'},
  {path: 'impressum', redirectTo: 'p/impressum'},
  {path: 'impressum-app', redirectTo: 'p/impressum-app'},
  {path: 'impressum-stv', redirectTo: 'p/impressum-stv'},
  {
    path: 'meine-inhalte',
    canActivate: [IsLoggedInGuard],
    loadChildren: () => import('./page-modules/newsfeed-page/newsfeed-page.module').then(m => m.NewsfeedPageModule),
    data: {
      loginReasonCode: LOGIN_REASON_CODES.SEE_ASSETS,
    },
  },
  {
    path: 'p',
    loadChildren: () => import('./page-modules/custom-page/custom-page.module').then(m => m.CustomPageModule),
  },
  {
    path: 'suche',
    loadChildren: () => import('./page-modules/search-page/search-page.module').then(m => m.SearchPageModule),
  },

  {
    path: ':profile/:asset',
    loadChildren: () => import('./page-modules/asset-page/asset-page.module').then(m => m.AssetPageModule),
  },
  {
    path: ':profile',
    loadChildren: () => import('./page-modules/profile-page/profile-page.module').then(m => m.ProfilePageModule),
  },

  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0], // You can customize the scroll offset if needed

      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: DelayedPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
  providers: [DelayedPreloadingStrategy],
})
export class AppRoutingModule {
}
