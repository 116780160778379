import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {distinctUntilChanged, filter, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ConsentService} from "./consent.service";

@Injectable({
  providedIn: 'root',
})
export class GoogleAdsConversionService {

  public trackCheckoutConversion(priceInCents?: number): void {
    this.hasConsent().subscribe(() => {
      let  value = undefined
      if (priceInCents) {
        value = priceInCents / 100;
      }
      gtag('event', 'conversion', {send_to: 'AW-984392417/UCGdCPWho_ICEOHFstUD', value: value?.toFixed(2),  currency: 'EUR'});
    });

  }

  public trackGoogleAdsConversion(conversionId: string, value?: string): void {
    this.hasConsent().subscribe(() => {
      gtag('event', 'conversion', {send_to: conversionId, value,  currency: 'EUR'});
    });
  }

  private hasConsent(): Observable<boolean> {
    return this.consentService.initialized$.pipe(
      distinctUntilChanged(),
      filter(initialized => initialized),
      switchMap(() => this.consentService.consentGiven(environment.consentIds.google_ad_services)),
      filter(hasConsent => hasConsent),
      take(1),
    );
  }

  public constructor(
    private consentService: ConsentService,
    private logger: NGXLogger,
  ) {
    this.hasConsent().subscribe(() => {
      gtag('config', environment.gtag.conversionTrackingId);
    });
  }
}
