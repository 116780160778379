import {Injectable} from '@angular/core';
import {ChunkLoaderService} from "../chunk-loader.service";
import {ProfileReducedResource} from "../../../../entities/Profile.entity";


@Injectable({
  providedIn: 'root',
})
export class TopProfilesService extends ChunkLoaderService<ProfileReducedResource> {
  public url = '/stateless/frontend/top-profiles';

  public override chunkSize = 6;
}
