import {Pipe, PipeTransform} from '@angular/core';
import {format, isToday, isTomorrow, isYesterday, parseISO} from 'date-fns';
import {TranslateService} from "@ngx-translate/core";
import {LocaleService} from "../../shared/services/locale.service";


@Pipe({name: 'assetDateAsync', pure: false})
export class AssetDatePipe implements PipeTransform {
  public transform(string: string | null): string {

    if (string === null) {
      return ''
    }
    const date = parseISO(string)

    let key: string;
    if (isTomorrow(date)) {
      key = 'asset_date.dfns_format_for_tomorrow';

    } else if (isToday(date)) {
      key = 'asset_date.dfns_format_for_today';

    } else if (isYesterday(date)) {
      // Gestern: Gestern, HH:mm Uhr
      key = 'asset_date.dfns_format_for_yesterday'
    } else {
      // Ältere Tage: TT.MM.JJ, HH:mm Uhr
      key = 'asset_date.dfns_format_for_default'
    }


    return format(date, this.translate.instant(key), {locale: this.localeService.dfnsLocale.getValue()});


  }

  public constructor(private translate: TranslateService, private localeService: LocaleService) {

  }
}
