import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {HttpClient} from '@angular/common/http';
import {AUTOCOMPLETE_RESULT_TYPE} from '../../../../enums/AUTOCOMPLETE_RESULT_TYPE';
import {Router} from '@angular/router';
import {catchError, take, timeout} from 'rxjs/operators';
import {AnalyticsService} from '../../services/analytics.service';
import {Location} from '@angular/common';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {EVENT_ACTION, EVENT_CATEGORY} from '../../../../types/gtm-options';
import {CurrentlyLiveService} from "../../services/currently-live.service";

export type AutoCompleteResult = {
  q: string;
  results: AutoCompleteResultItem[];
};
export type AutoCompleteResultItem = {
  keyword: string;
  image_url: string;
  type: AUTOCOMPLETE_RESULT_TYPE;
  slug: string;
  id: UUID,
  profile_slug?: string; // only set when asset
};

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent {
  public items: Subject<AutoCompleteResultItem[]> = new Subject<AutoCompleteResultItem[]>();
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() public q = '';
  @Input() public large  = true;
  private itemSelected = new EventEmitter<boolean>();

  public selectedResult(result: { item: AutoCompleteResultItem }): void {
    this.log.log('emit itemSelected');
    this.itemSelected.next(true);
    this.q = result.item.keyword.replace(/<\/?[^>]+(>|$)/g, '');

    if (result.item.type === 'TAG') {
      this.router.navigate(['/videos/' + result.item.slug]);
    }
    if (result.item.type === 'PROFILE') {
      this.router.navigate(['/' + result.item.slug]);
    }
    if (result.item.type === 'ASSET') {
      this.router.navigate(['/' + result.item.profile_slug + '/' + result.item.slug]);
    }

    this.cdr.markForCheck();
  }


  public blurredSearch(): void {
    this.cdr.markForCheck();
  }

  public onEnter(): void {
    // search submit fires enter, but also select item fires enter.
    // so we wait for 50ms if an item was selected, if not we wil fire search
    this.itemSelected
      .pipe(
        take(1),
        timeout(50),
        catchError(() => {
          // timeout while waiting for selectedResult =>  nothing selected, fire search
          return of(false);
        }),
      )
      .subscribe((itemSelected: boolean) => {
        if (!itemSelected) {
          this.search();
        }
      });
  }

  public search(): void {
    if (this.q === '') {
      return;
    }
    this.analyticsService.trackEvent(EVENT_CATEGORY.SEARCH, EVENT_ACTION.SEARCH_CLICK, this.q, 1);
    this.router.navigate(['/suche/' + encodeURIComponent(this.q)]);
  }

  public autocompleteClick(item: TypeaheadMatch): void {
    this.analyticsService.trackEvent(EVENT_CATEGORY.SEARCH, EVENT_ACTION.SEARCH_AUTOCOMPLETE_CLICK, item.item.slug, 1);
  }

  public loadingEmit(loading: boolean): void {
    this.loading.next(loading);
    this.cdr.markForCheck();
  }

  public load(q: string): void {
    if (q === '[object Object]') {
      return;
    }
    if (q.trim().length === 0) {
      return;
    }
    this.loading.next(true);
    this.http
      .get<AutoCompleteResult>(environment.search.url + '/autocomplete', {
        params: { q },
        withCredentials: true,
      })
      .subscribe(
        r => {
          this.items.next(r.results);
          this.loading.next(false);
          this.cdr.markForCheck();
        },
        () => {
          this.items.next([]);
          this.loading.next(false);
          this.loading.next(false);
        },
      );
  }

  public constructor(
    private log: NGXLogger,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private analyticsService: AnalyticsService,
    public location: Location,
    public currentlyLiveService: CurrentlyLiveService,
  ) {}
}
