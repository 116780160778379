<form (submit)="search()" class="form w-100">
  <div class="input-group">
    <input
      type="search"
      style="box-shadow: none !important;"
      class="form-control no-validate "
      [class.form-control-lg]="large"
      name="search"
      [placeholder]="'search.placeholder' | translate"
      [typeahead]="$any(items)"
      [typeaheadAsync]="true"
      [typeaheadOptionsLimit]="10"
      (typeaheadLoading)="loadingEmit($event)"
      (typeaheadOnSelect)="selectedResult($any($event))"
      (typeaheadOnBlur)="blurredSearch()"
      (typeaheadNoResults)="cdr.markForCheck()"
      [typeaheadMultipleSearch]="false"
      [typeaheadSelectFirstItem]="false"
      [minLength]="0"
      [typeaheadWaitMs]="50"
      [typeaheadHideResultsOnBlur]="true"
      (keydown.escape)="blurredSearch()"
      (keydown.enter)="onEnter()"
      [optionsListTemplate]="customListTemplate"
      typeaheadOptionField="q"
      autocomplete="off"
      [(ngModel)]="q"
      (ngModelChange)="load($event)"
      id="sporttype"
      (submit)="search()"
    />

    <ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
      <ul class="p-0 m-0">
        <li
          style="list-style: none"
          *ngFor="let item of matches"
          (click)="typeaheadTemplateMethods.selectMatch(item, $event)"
          (mouseenter)="typeaheadTemplateMethods.selectActive(item)"
        >
          <ng-container [ngSwitch]="item.item.type">
            <span
              *ngSwitchCase="'PROFILE'"
              [class.text-primary]="typeaheadTemplateMethods.isActive(item)"
              class="dropdown-item d-flex align-items-center pointer"
              (click)="autocompleteClick(item)"
            >
              <img
                loading="lazy"
                class="sdtv-profile-image-small sdtv-profile-image me-1"
                imageError="/assets/placeholders/profil.svg"
                [src]="item.item | profileImage"
              />
              <span class="text-truncate" [innerHTML]="'@' + item.item.keyword"></span>
              <sup><span class="badge bg-danger ms-1 sdtv-badge-small" *ngIf="currentlyLiveService.profileIsLive(item.item)| async">LIVE</span></sup>
            </span>

            <span
              *ngSwitchCase="'TAG'"
              class="dropdown-item d-flex align-items-center pointer me-1"
              [class.text-primary]="typeaheadTemplateMethods.isActive(item)"
              (click)="autocompleteClick(item)"
            >
              <div class="badge badge-primary me-1 sdtv-badge-icon">
                <i class="fa fa-hashtag"></i>
              </div>
              <span class="text-truncate" [innerHTML]="'#' + item.item.keyword"></span>
            </span>

            <span
              *ngSwitchCase="'ASSET'"
              class="dropdown-item d-flex align-items-center pointer"
              [class.text-primary]="typeaheadTemplateMethods.isActive(item)"
              (click)="autocompleteClick(item)"
            >
              <img
                loading="lazy"
                class="sdtv-profile-image-small sdtv-profile-image me-1"
                imageError="/assets/placeholders/profil.svg"
                [src]="item.item | profileImage"
              />
              <span class="text-truncate" [innerHTML]="item.item.keyword"></span>

              <sup><span class="badge bg-danger ms-1 sdtv-badge-small" *ngIf="currentlyLiveService.assetIsLive(item.item)| async">LIVE</span></sup>
            </span>
          </ng-container>
        </li>
      </ul>
    </ng-template>

    <span class="position-absolute pt-2 sdtv-search-loader" *ngIf="loading | async"><i class="fa text-light fa-spinner fa-spin"></i></span>


    <button class="input-group-text btn-black input-group-button sdtv-hover-text-primary" type="submit"><i class="fa  fa-search "></i></button>

  </div>
</form>
