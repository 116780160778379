<div #contentGrid>
  <div class="container-fluid p-0" *ngIf="items && itemsPerRow && items.getValue().length > 0">
    <app-infinite-scroll [enabled]="infinityLoading" (scrolled)="loadInfinite()">
      <div
        [class.px-2]="paddingXonXS"
        [class.px-sm-0]="paddingXonXS"
        [style.columns]="itemsPerRow"
        style="display: grid"
        [style.grid-gap.px]="gridGap"
        [style.grid-template-columns]="itemsPerRow != 1 ? 'repeat(' + itemsPerRow + ', minmax(0, 1fr)) !important' : '100%'"
      >
        <div class="d-block" *ngFor="let item of items | async; let index = index; let even = even; let odd = odd">
          <ng-container
            [ngTemplateOutletContext]="$any({
              $implicit: $any(item),
              index: index,
              even: even,
              odd: odd,
              col: (index % itemsPerRow) + 1,
              row: math.ceil((index + 1) / itemsPerRow)
            })"
            [ngTemplateOutlet]="itemTemplate"
          ></ng-container>
        </div>
      </div>
    </app-infinite-scroll>

    <div *ngIf="!infinityLoading && (showLoadMore | async)" class="w-100 text-center mt-2 mb-5">
      <button
        (click)="addRow()"
        [disabled]="loading | async"
        [attr.aria-label]="loadMoreLabel"
        class="btn"
        type="button"
        [class.btn-primary]="showLoadMoreAsButton"
        [class.btn-link]="!showLoadMoreAsButton"
        [class.fw-light]="!showLoadMoreAsButton"
      >
        {{ loadMoreLabel ?? ('content_grid.show_more' | translate)}}
        <i *ngIf="loading | async" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
    <div *ngIf="!infinityLoading && !(showLoadMore | async) && paddingBottom" class="w-100 pt-4"></div>

    <div *ngIf="infinityLoading" class="w-100 text-center my-5 py-5">
      <span class="my-5 py-5">
        <i *ngIf="(loading | async)" class="fa fa-2x my-5  fa-spinner fa-spin"></i>
      </span>

    </div>
  </div>
</div>
