import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProfileEditingResource} from "../../../entities/Profile.entity";
import {PROFILE_TYPE} from "../../../../enums/PROFILE_TYPE";
import {UserResource} from "../../../entities/User.entity";
import {EVENT_ACTION, EVENT_CATEGORY} from "../../../../types/gtm-options";
import {AnalyticsService} from "../../services/analytics.service";
import {AuthenticationService} from "../../services/authentication.service";
import {SIDEBAR_MENU} from "../sidebar/sidebar.component";
import {distinctUntilChanged} from "rxjs/operators";
import {NGXLogger} from "ngx-logger";
import {SidebarAndHeaderService} from "../../services/sidebar-and-header.service";
import {TagReducedResource} from "../../../entities/Tag.entity";
import {CurrentlyLiveService} from "../../services/currently-live.service";
import {TAG_CATEGORY_NAME} from "../../../../enums/TAG_CATEGORY_NAME";
import {environment} from "../../../../environments/environment";
import {HeaderTagsService} from "./header-tags.service";
import {LOCALE, LocaleService} from "../../services/locale.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  public profile?: ProfileEditingResource;
  public profileTypeEnum = PROFILE_TYPE;
  public user?: UserResource;
  public activeTag?: TagReducedResource
  public topSportTypes?: TagReducedResource[] = [];
  public notificationsCount?: number;

  public numberOfCurrentLivestreams?: number;
  public search = '';
  public isHandballNet = false;
  public availableLocale = LOCALE;
  protected readonly SIDEBAR_MENU = SIDEBAR_MENU;
  protected readonly environment = environment;

  public logout(): void {
    if (this.user) {
      this.analyticsService.trackEvent(EVENT_CATEGORY.FEATURE, EVENT_ACTION.LOGOUT, 'Header Menu Logout', 1);
    }
    this.authenticationService.logout();
  }

  public openSidebar(menu?: SIDEBAR_MENU): void {
    if (menu) {
      this.log.log('sidebar opening sidebar from header with menu', menu)
      this.sidebarAndHeaderService.configureSidebar(menu)
    }
    this.sidebarAndHeaderService.openSidebar()

  }
  public ngOnInit(): void {
    this.headerTagsService.load(20)
  }

  public constructor(
    public authenticationService: AuthenticationService,
    public sidebarAndHeaderService: SidebarAndHeaderService,
    public localeService: LocaleService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private log: NGXLogger,
    private currentlyLiveService: CurrentlyLiveService,
    private headerTagsService: HeaderTagsService
  ) {

    this.headerTagsService.items$.subscribe(
      {next: (d) =>    {
      this.topSportTypes = d;
      if(this.headerTagsService.hasMore$.getValue()) {
        this.headerTagsService.load(40)
      }
      this.cdr.markForCheck()
    }
  }
    )


    sidebarAndHeaderService.sidebarMenu$.subscribe(
      d => {

        if (d.tag && d.tag.category === TAG_CATEGORY_NAME.SPORT_TYPE && d.changeLogoAndTags) {
          this.activeTag = d.tag
        }

        this.cdr.markForCheck()
      }
    )

    this.sidebarAndHeaderService.isHandballNet.subscribe(
      i => {
        this.isHandballNet = i;
        this.cdr.markForCheck()
      }
    )

    authenticationService.user$.subscribe(user => {
      this.user = user;
      this.cdr.markForCheck();
    }, error => {
      this.log.error('auth service error in header', error);
    });
    authenticationService.loggedIn$.pipe(distinctUntilChanged()).subscribe(() => {
        this.cdr.markForCheck();
      },
      error => {
        this.log.error('auth service error in header', error);
      });
    authenticationService.profile$.subscribe(profile => {
        if (profile === null) {
          profile = undefined;
        }
        this.profile = profile;
        this.cdr.markForCheck();
      },
      error => {
        this.log.error('auth service error in header', error);
      });
    this.authenticationService.unreadNotificationsCount$.subscribe(count => {
        this.notificationsCount = (count ?? 0);
        this.cdr.markForCheck();
      },
      error => {
        this.log.error('auth service error in header', error);
      });

    this.currentlyLiveService.numberOfCurrentLivestreams.subscribe(
      (d) => {
        this.numberOfCurrentLivestreams = d
        this.cdr.markForCheck()
      }
    )

  }


}
