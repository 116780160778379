import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {HttpClient} from "@angular/common/http";
import {UserActivityService} from "./user-activity.service";
import {concat, interval, of, Subscription, timer} from "rxjs";
import {catchError, filter, map, switchMap} from "rxjs/operators";
import {AuthenticationService} from "./authentication.service";
import {TooManyDevicesEventService} from "./too-many-devices.event.service";
import {TooManyDevicesResponseError} from "../components/too-many-devices/too-many-devices.response";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TooManyDevicesComponent} from "../components/too-many-devices/too-many-devices.component";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class TooManyDevicesService {

  private modalRef: BsModalRef | null = null;
  private debug = false;
  private cancelled = false;
  private subscription: Subscription | null = null;


  public showTooManyDevicesDialog(response: TooManyDevicesResponseError): void {

    // If the modal is already open, do not open another one
    if (this.modalRef) {
      return;
    }
    this.cancelled = false;

    const isOtherModalVisible = window.location.pathname.includes('(m:')

    this.modalRef = this.modalService.show(TooManyDevicesComponent, {
      initialState: {
        tooManyDevicesResponse: response,
      },
      backdrop: true,
      show: true,
      animated: false,
      focus: false,
      keyboard: false,
      ignoreBackdropClick: true,
      class: isOtherModalVisible ? 'modal-fullscreen' : "modal-lg", // Change this according to your needs
    });

    this.modalRef.content?.close.subscribe(() => {
      this.modalRef?.hide();
    });

    this.modalRef.content?.abort.subscribe(() => {
      this.cancelled = true;
      this.modalRef?.hide();
    });

    this.modalRef.onHidden?.subscribe(() => {
      this.modalRef = null;
      if (this.cancelled) {
        this.tooManyDevicesEventService.tooManyDevicesResponseErrorCancelled.emit()
      } else {
        this.tooManyDevicesEventService.tooManyDevicesResponseErrorMitigated.emit()
      }


    });
  }

  public initSessionCountCheckerInterval(delayInMilliseconds = 0): void {
    if (this.subscription) {
      // If there's already a subscription, don't create a new one
      if (this.debug) {
        this.log.warn('[TooManyDevicesService] session count checker interval already exists');
      }

      return;
    }

    const intervalToCheckInMinutes = environment.envName === 'local' ? 0.1 : 5;

    // delay the first check by the given amount of milliseconds
    this.subscription = concat(
      timer(delayInMilliseconds), // Emits once after the initial delay
      interval(intervalToCheckInMinutes * 60 * 1000) // Then emits repeatedly after the interval
    ).pipe(
      filter(() => this.userActivityService.userIsActive.getValue() && !!this.auth.loggedIn$.getValue()),
      switchMap(() => this.http.get<void>('/web/personal/devices/check')),
      map(() => {
        // if responding with 200ish code all is fine
        return false;
      }),
      catchError(e => {
        if (e.status === 409) {
          if (this.debug) {
            this.log.warn('[TooManyDevicesService] too many devices');
          }
          return of(true);
        }
        if (this.debug) {
          this.log.error('[TooManyDevicesService] error checking device count', e);
        }

        return of(false);
      }),
    ).subscribe(
      (hasTooManyDevices: boolean) => {
        if (this.debug) {
          this.log.info('[TooManyDevicesService] hasTooManyDevices?', hasTooManyDevices);
        }
      }
    );
    this.subscription.add(() => {
      this.subscription = null;
      if (this.debug) {
        this.log.info('[TooManyDevicesService] session count checker interval unsubscribed');
      }

      // try again in two minutes (prevents ddos on the server)
      this.initSessionCountCheckerInterval(2 * 60 * 1000);
    });

  }

  public constructor(
    private http: HttpClient,
    private log: NGXLogger,
    private userActivityService: UserActivityService,
    private auth: AuthenticationService,
    private modalService: BsModalService,
    private tooManyDevicesEventService: TooManyDevicesEventService,
  ) {

    tooManyDevicesEventService.tooManyDevicesResponseErrorSubject.subscribe(
      (e) => {
        this.showTooManyDevicesDialog(e);
      }
    )
    this.initSessionCountCheckerInterval();
  }
}


