import {Injectable} from '@angular/core';
import {ChunkLoaderService} from "../../chunk-loader.service";
import {TagForMenuResource} from "../../../../../entities/Tag.entity";


@Injectable({
  providedIn: 'root',
})
export class AllSporttypeTagsService extends ChunkLoaderService<TagForMenuResource> {

  public url = '/stateless/frontend/menu/sport-types';

  public override chunkSize = 20;
}
