import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, interval, Observable, of, switchMap} from 'rxjs';
import {catchError, filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {UserActivityService} from "./user-activity.service";
import {BaseResource} from "../../entities/BaseResource.entity";

@Injectable({
  providedIn: "root"
})
export class CurrentlyLiveService {

  public $liveProfilesChanged: EventEmitter<void> = new EventEmitter<void>();

  public numberOfCurrentLivestreams: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined)

  private liveProfileIds?: string[];
  private liveAssetIds?: string[];

  private networkRequestForProfiles?: Observable<UUID[]>;
  private networkRequestForAssets?: Observable<UUID[]>;


  private debug = false;


  public assetIsLive(entity: BaseResource): Observable<boolean> {
    return this.getCurrentlyLiveAssetIds().pipe(
      map(r => {
        return r.includes(entity.id);
      }),
    );
  }

  public profileIsLive(entity: BaseResource): Observable<boolean> {
    return this.getCurrentlyLiveProfileIds().pipe(
      map(r => {
        return r.includes(entity.id);
      }),
    );
  }

  public init(): void {
    interval(60 * 5 * 1000).pipe(
      startWith(0), // Emit a value immediately
      filter(() => {
        return this.userActivityService.userIsActive.getValue()
      }),
      switchMap(() => this.http.get<UUID[]>('/stateless/frontend/live-ids/profiles')),
      tap(r => {
        if (this.debug) {
          this.log.info('[CurrentlyLive] updating Live Profiles')
        }
        this.$liveProfilesChanged.emit()
        this.liveProfileIds = r;
      }),
      switchMap(() => this.http.get<UUID[]>('/stateless/frontend/live-ids/assets')),
      tap(r => {
        if (this.debug) {
          this.log.info('[CurrentlyLive] updating Live Asset Count')
        }
        this.liveAssetIds = r
        this.numberOfCurrentLivestreams.next(r.length);
      }),
      catchError(e => {
        this.log.error('[CurrentlyLive] Could not load live profile ids', e.message);
        return of([]);
      }),
    ).subscribe(
      () => {
        //this.log.info('[CurrentlyLive] updated live profile ids')

      }
    )
  }


  public getCurrentlyLiveProfileIds(): Observable<string[]> {

    if (this.liveProfileIds) {
      return of(this.liveProfileIds);
    }

    if (!this.networkRequestForProfiles) {
      this.networkRequestForProfiles = this.getLiveProfilesFromServer();
    }

    return this.networkRequestForProfiles.pipe(
      map(r => {
        return r;
      }),
    );
  }


  public getCurrentlyLiveAssetIds(): Observable<string[]> {

    if (this.liveAssetIds) {
      return of(this.liveAssetIds);
    }

    if (!this.networkRequestForAssets) {
      this.networkRequestForAssets = this.getLiveAssetsFromServer();
    }

    return this.networkRequestForAssets.pipe(
      map(r => {
        return r;
      }),
    );
  }


  private getLiveProfilesFromServer(): Observable<string[]> {
    return this.http.get<UUID[]>('/stateless/frontend/live-ids/profiles').pipe(
      tap(r => {
        this.$liveProfilesChanged.emit()
        this.liveProfileIds = r;
      }),
      catchError(e => {
        this.log.error('[CurrentlyLive] Could not load live profile ids', e.message);
        return of([]);
      }),
      shareReplay(),
    );
  }

  private getLiveAssetsFromServer(): Observable<string[]> {
    return this.http.get<UUID[]>('/stateless/frontend/live-ids/assets').pipe(
      tap(r => {
        this.liveAssetIds = r;
        this.numberOfCurrentLivestreams.next(this.liveAssetIds.length);
      }),
      catchError(e => {
        this.log.error('[CurrentlyLive] Could not load live asset ids', e.message);
        return of([]);
      }),
      shareReplay(),
    );
  }

  public constructor(
    private log: NGXLogger,
    private http: HttpClient,
    private userActivityService: UserActivityService
  ) {
  }
}
