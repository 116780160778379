import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollComponent implements AfterViewInit, OnDestroy {
  @Input() public enabled = true;
  @Input() public options = {};
  @Output() public scrolled = new EventEmitter();
  @ViewChild('anchor') public anchor!: ElementRef<HTMLElement>;

  private observer!: IntersectionObserver;

  public ngAfterViewInit(): void {
    if (this.enabled) {
      const options = {
        root: this.isHostScrollable() ? this.host.nativeElement : null,
        ...this.options,
      };

      this.observer = new IntersectionObserver(([entry]) => {
        entry.isIntersecting && this.scrolled.emit();
      }, options);

      this.observer.observe(this.anchor.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    if (this.enabled) {
      this.observer.disconnect();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private element(): any {
    return this.host.nativeElement;
  }

  private isHostScrollable(): boolean {
    const style = window.getComputedStyle(this.element());

    return style.getPropertyValue('overflow') === 'auto' || style.getPropertyValue('overflow-y') === 'scroll';
  }

  public constructor(private host: ElementRef) {}
}
