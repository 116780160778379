import {Injectable, Optional, SkipSelf} from '@angular/core';
import {BehaviorSubject, fromEvent, interval, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {AdBlockerDetectionService} from './ad-blocker-detection.service';
import {
  SOM_DISPLAY_AD_SLOT_TYPES,
  SOM_VIDEO_AD_BLOCK_TYPES,
  SomtagAdResponse,
  SomtagConfig,
  SomtagDisplayAdResponse,
} from '../../../types/somtag';
import {environment} from '../../../environments/environment';
import {ConsentService} from './consent.service';
import {NGXLogger} from 'ngx-logger';
import {AssetFromSearchIndexResource, AssetReducedResource, AssetResource} from '../../entities/asset.entity';
import {ASSET_FLAG} from '../../../enums/ASSET_FLAG';
import {AuthenticationService} from './authentication.service';
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {document} from "ngx-bootstrap/utils";

/**
 * @see https://docs.seven.one/
 */
@Injectable({
  providedIn: 'root',
})
export class SomtagService {
  public displayAdShouldLoad: Subject<void> = new Subject();


  public MOBILE_BREAKPOINT = 991; // is 1 px less than the bootstrap grid
  public displayAdDeviceMode: BehaviorSubject<'mobile' | 'desktop'>;

  /// used to inform all display-ad components that som is about to insert something
  // die to the async nature, som might enter it in the wrong component
  public aboutToInsertAd: Subject<{ height: number, width: number, format: SOM_DISPLAY_AD_SLOT_TYPES, }> = new Subject<{
    height: number,
    width: number,
    format: SOM_DISPLAY_AD_SLOT_TYPES
  }>();


  private readonly loggingEnabled = false;
  private displayAdsAreInitializing = false;
  private displayAdsAreInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private windowResize$: Subject<void> = new Subject<void>();
  private ERROR_INITIALIZING = 'ERROR_ALREADY_INITIALIZING';
  private ERROR_ALREADY_INITIALIZED = 'ERROR_ALREADY_INITIALIZED';

  private currentlyLoadedSlots: SOM_DISPLAY_AD_SLOT_TYPES[] = []
  private readonly ppidFallBack: string

  public reloadDisplayAdsWithoutInit(): void {

    if (this.displayAdsAreInitialized.getValue()) {
      somtag.cmd("reloadDisplaySlots");
    }
  }

  public addScript(): Observable<void> {
    return this.hasConsent().pipe(
      map((hasConsent) => {
        this.log('has consent?', hasConsent);
        if (!hasConsent) {
          return;
        }
        const scriptId = 'somtag-loader';
        if (document.getElementById(scriptId) !== null) {
          return;
        }
        // Create and configure the script element
        const script: HTMLScriptElement = document.createElement('script');
        script.id = scriptId;
        script.src = '//ad.71i.de/somtag/loader/loader.js';

        document.head.appendChild(script);

      })

      // delay until window.somtag is defined
      , switchMap(() => {
          return new Observable<void>((subscriber) => {
            let count = 0;
            const interval = setInterval(() => {
              count++;
              this.log('check if somtag ist defined', count);

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if ((window as any).somtag) {
                this.log('somtag is defined', 'log')
                clearInterval(interval);
                subscriber.next();
                subscriber.complete();
              } else {
                this.log('waiting for somtag to be defined', 'log')
                if (count > 50) {
                  clearInterval(interval);
                  this.adBlockDetectionService.adBlockerDetected(true);
                  subscriber.error('AdBlocker detected');
                  subscriber.complete();
                }
              }
            }, 100);
          });
        }
      ));
  }

  public hasConsent(): Observable<boolean> {
    return this.consentService.initialized$.pipe(
      distinctUntilChanged(),
      filter(initialized => initialized),
      switchMap(() => {
        this.log('Check TCF consent')
        return this.consentService.tcfVendorConsentGiven(environment.tcfConsentIds.som)
      }),

      filter((consentGiven) => {
        this.log('Consent given for somtag', {consentGiven})
        if (!consentGiven) {
          this.ngxLogger.error('Missing TCF Consent for SOM', {tcfVendorId: environment.tcfConsentIds.som})
        }
        return consentGiven;
      }),

      filter(hasConsent => hasConsent),
      take(1),
    );
  }


  public adsDisabledForAsset(asset: AssetReducedResource | AssetFromSearchIndexResource): Observable<boolean> {
    return this.adsDisabled().pipe(
      map(adsDisabled => {
        if (adsDisabled) {
          return true;
        }
        if (!adsDisabled && asset.flags.includes(ASSET_FLAG.NO_ADS)) {
          this.log('ads disabled due to asset flag');
          return true;
        }
        return adsDisabled;
      }),
    );
  }


  public getBase64EncodedVMAPUrl(asset: AssetResource, block: SOM_VIDEO_AD_BLOCK_TYPES, blockIndex: number, duration: number | undefined): Observable<string> {

    return this.adsDisabled().pipe(
      map((adsDisabled: boolean) => {
        if (adsDisabled) {
          throw new Error('Ads disabled');
        }
      }),
      map(() => asset.video_ad_tags_somquery[block]),
      map((d: string) => d.replace('REPLACE_BLOCK_INDEX', blockIndex.toString())),
      map(d => d.replace('REPLACE_HAS_UNCERTIFIED_VENDORS', '0')),
      switchMap(d => this.consentService.getTCString().pipe(
        map((tcString: string) => d.replace('REPLACE_TC_STRING', tcString)))),
      map(d => d.replace('REPLACE_ADEX_ID', this.getCookie('axd') || '')),
      map(d => d.replace('REPLACE_PPID', this.authenticationService.user$.getValue()?.ppid || this.ppidFallBack)),
      map(d => d.replace('REPLACE_DEVICE', window.innerWidth > this.MOBILE_BREAKPOINT ? 'desktop' : 'mew')),
      map(d => d.replace('REPLACE_BLOCK_DURATION', duration ? duration.toString() : '150')),
      tap(d => this.log('VAST URL TO LOAD', d)),
      map(url => {
        let response = '<?xml version="1.0" encoding="UTF-8"?><vmap:VMAP xmlns:vmap="http://www.iab.net/videosuite/vmap" version="1.0">';

        let timeoffset = 'start';
        switch (block) {
          case SOM_VIDEO_AD_BLOCK_TYPES.PRE_ROLL:
            timeoffset = 'start';
            break;
          case SOM_VIDEO_AD_BLOCK_TYPES.MID_ROLL:
            timeoffset = '#1';
            break;
          case SOM_VIDEO_AD_BLOCK_TYPES.POST_ROLL:
            timeoffset = '#1';
            break;
        }

        response +=
          ' <vmap:AdBreak timeOffset="' +
          timeoffset +
          '" breakType="linear,nonlinear,display" breakId="' +
          block +
          0 +
          '">' +
          '<vmap:AdSource id="' +
          block +
          '-ad-' +
          0 +
          '" allowMultipleAds="true" followRedirects="true">' +
          '   <vmap:AdTagURI templateType="vast4"><![CDATA[' +
          url +
          ']]></vmap:AdTagURI>' +
          '  </vmap:AdSource>' +
          ' </vmap:AdBreak>';
        response += '</vmap:VMAP>';

        return response
      }),
      map(url => 'data:application/xml;base64,' + window.btoa(url) as string)
    ) as Observable<string>

  }

  public adsDisabled(): Observable<boolean> {
    if (!environment.ads.useAds) {
      this.log('ads disabled by environment file');
      return of(true);
    }

    return this.authenticationService.adsDisabledForUser().pipe(
      take(1),
      switchMap((disabled) => {
        if (disabled) {
          return of(true)
        }

        return this.consentService.anyConsentDecisionWasMade().pipe(
          switchMap((anyConsentDecisionWasMade) => {
            if (anyConsentDecisionWasMade) {
              return this.consentService.allConsentsGiven().pipe(map(d => !d));
            }
            // wait for the first consent decision
            return this.consentService.consentsChanged$.pipe(
              switchMap(() => this.consentService.anyConsentDecisionWasMade()),
              filter((anyConsentDecisionWasMade) => anyConsentDecisionWasMade),
              take(1),
              switchMap(() => this.consentService.allConsentsGiven().pipe(map(d => !d)))
            );
          }));
      })
    );
  }


  public loadDisplayAdForSlot(slotType: SOM_DISPLAY_AD_SLOT_TYPES, container: string): Observable<{
    height: number,
    width: number,
    format: SOM_DISPLAY_AD_SLOT_TYPES
  }> {
    this.log('loadDisplayAdForSlot', {slotType, container})
    return this.adsDisabled().pipe(
      map(adsDisabled => {
        if (adsDisabled) {
          this.log('ads disabled');
          throw new Error('SOMTAG Service: Ads disabled');
        }
        if (!environment.ads.somtag.displaySlots[slotType].enabled) {
          throw new Error('SOMTAG Service: Display Ad Slot "' + slotType + '" not enabled in environment');
        }
        if (!this.slotAllowedForCurrentDevice(slotType)) {
          throw new Error('Warning: slot "' + slotType + '" must not not be loaded with current device mode: ' + this.displayAdDeviceMode.getValue());
        }
        return adsDisabled;
      }),
      map((adsDisabled) => {
        if (adsDisabled) {
          throw new Error('Ads are disabled');
        }
        return adsDisabled
      }),
      switchMap(() => {
        if (this.isElementVisibleByCss(container)) {
          return of(true);
        }
        this.log('Container is not visible', container, 'error')

        // as soon as the element becomes visible => continue loading
        return this.windowResize$.asObservable().pipe(
          debounceTime(100),
          //tap(() => this.log('window resize')),
          map(() => {
            return this.isElementVisibleByCss(container)
          }),
          /// as soon as the device mode changes, we cannot load it anymore
          //takeUntil(this.displayAdDeviceMode),
          tap((visible) => this.log('window resize element is visible', {visible, slotType})),
          filter(visible => visible)
        )
      }),
      filter(d => d),
      map(() => {
        // this slot has previously been filled!
        // we must initDisplayAds again
        if (this.currentlyLoadedSlots.includes(slotType)) {
          this.log("slot " + slotType + " had been filled previously: new init", slotType, 'log')
          this.displayAdsAreInitialized.next(false)
        }
      }),
      switchMap(() => {
        return this.displayAdsAreInitialized
      }),
      switchMap((initialized: boolean) => {
        if (initialized) {
          return of(true)
        }
        this.log('Init  Display Ads for  "' + slotType + '"');
        if (this.displayAdsAreInitializing) {
          return this.displayAdsAreInitialized
        }
        this.log('Init Display Ads for  "' + slotType + '"');
        return this.initDisplayAds().pipe(switchMap(() => this.displayAdsAreInitialized))
      }),
      map((displayAdsAreInitialized) => {
        this.log('displayAdsAreInitialized for slot' + slotType, displayAdsAreInitialized)
        if (displayAdsAreInitialized) {
          this.log('displayAdsAreInitialized for slot' + slotType);
        }
        return displayAdsAreInitialized
      }),
      //take(1),
      switchMap((): Observable<SomtagDisplayAdResponse> => {
        this.log('Inserting  slot "' + slotType + '"');
        const subject = new Subject<SomtagDisplayAdResponse>();

        // mark the slot as filled
        this.currentlyLoadedSlots.push(slotType);
        this.log('inserting ad', {slotType, container})
        somtag.cmd('insertAd', slotType, {container: container}, (error: Error | null, result: SomtagDisplayAdResponse) => {
          if (error) {
            subject.error(error);
          } else {
            subject.next({
              ...result,
              error,
            });
          }
        });
        return subject.asObservable();
      }),
      filter((res: SomtagDisplayAdResponse) => {
        return res.type === 'adResponse';
      }),
      map(r => {
        return r as SomtagAdResponse;
      }),
      map(r => {
        this.log('should load display ad in ' + slotType, r)
        return r;
      }),
      map(r => {
        if (r.error) {
          throw new Error('SOM ERROR', r.error);
        }
        if (r.data.adConfig.modifier === 'fallback') {
          throw new Error('SOM Warning got fallback ad');
        }
        if (+r.data.adConfig.width === 0 || +r.data.adConfig.height === 0) {
          throw new Error('SOM Error got invisible ad from SOM');
        }
        return {
          width: +r.data.adConfig.width,
          height: +r.data.adConfig.height,
          format: r.data.slotName,
        };
      }),
      tap((r: { height: number, width: number, format: SOM_DISPLAY_AD_SLOT_TYPES }) => {
        this.aboutToInsertAd.next(r)

        return r;
      }),
    ) as Observable<{
      height: number,
      width: number,
      format: SOM_DISPLAY_AD_SLOT_TYPES
    }
    >;
  }


  private getCookie(name: string): string | null {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop()?.split(";").shift() || null;
    }
    return null;
  }


  private isElementVisibleByCss(querySelector: string): boolean {
    let el = document.querySelector(querySelector)?.parentElement;
    if (!el) {
      this.log('element missing', {querySelector}, 'error')
      return false
    }
    while (el) {
      if (getComputedStyle(el).display === 'none') {
        return false;
      }
      el = el.parentElement;
    }
    return true;
  }

  private initDisplayAds(): Observable<unknown> {

    return this.adsDisabled().pipe(
      tap(l => {
          if (l) {
            this.log('initDisplayAds ads disabled', l, 'warn')
          }
        }
      ),
      filter((adsDisabled: boolean) => !adsDisabled),
      take(1),
      switchMap(() => this.consentService.allConsentsGiven()),
      filter((allConsentsGiven) => allConsentsGiven),
      switchMap((allConsentsGiven) => this.addScript().pipe(map(() => allConsentsGiven))),
      map((allConsentsGiven) => {
        const config: SomtagConfig = {
          enabled: true,
          taxonomy: {
            channels: [
              'de', // country
              'sportdeutschland', //brand
              'sportdeutschland',
              this.displayAdDeviceMode.getValue(),
              '',
              '',
            ],
            inventory: {
              country: 'de',
              site: 'sportdeutschland',
              brand: 'sportdeutschland',
              device: this.displayAdDeviceMode.getValue(),
              format: '',
              categories: [],
            },
          },
          config: {
            id: environment.ads.somtag.publisherId,
            integrationId: 'c8f6002d-8f2e-47fe-ad12-6194b2b48428',
            consent: {
              tcfVersion: 2,
              optOutUncertifiedVendors: !allConsentsGiven,
            },
          },
          display: {
            slots: environment.ads.somtag.displaySlots,
          },
          device: {
            mode: this.displayAdDeviceMode.getValue(),
            breakpoint: this.MOBILE_BREAKPOINT,
          },
        };
        return config;
      }),
      filter(() => {
        const isInitializing = this.displayAdsAreInitializing;

        if (isInitializing) {
          this.log('Is already initializing?', isInitializing, 'error');
          throw new Error(this.ERROR_INITIALIZING)
        }
        if (this.displayAdsAreInitialized.getValue()) {
          throw new Error(this.ERROR_ALREADY_INITIALIZED)
        }
        return !isInitializing
      }),
      tap(() => this.displayAdsAreInitializing = true),
      switchMap(config => {
        this.log('Init display ads with config', config);

        const promise: Promise<unknown> = somtag.cmd('init', config);
        if (!promise) {


          throw Error('Somtag Init Promise not defined. Maybe Adblocker is used');
        }
        return fromPromise(promise);
      }),
      catchError(e => {
        this.log('Error initializing display ads', e, 'error');
        this.displayAdsAreInitializing = false
        throw e
      }),
      tap(() => {
        this.displayAdsAreInitializing = false
        this.currentlyLoadedSlots = []
        this.displayAdsAreInitialized.next(true)
      }),
      map(d => d)
    );
  }

  private slotAllowedForCurrentDevice(slot: SOM_DISPLAY_AD_SLOT_TYPES): boolean {
    if (this.displayAdDeviceMode.getValue() === 'mobile') {
      // mobile
      switch (slot) {
        case SOM_DISPLAY_AD_SLOT_TYPES.FULLBANNER:
        case SOM_DISPLAY_AD_SLOT_TYPES.SKYSCRAPER:
        case SOM_DISPLAY_AD_SLOT_TYPES.RECTANGLE: // todo re-enable as soon as som activates it on mobile
          return false;
      }
    } else {
      // desktop
      switch (slot) {
        case SOM_DISPLAY_AD_SLOT_TYPES.MOBILE_BANNER_1:
        case SOM_DISPLAY_AD_SLOT_TYPES.MOBILE_BANNER_2:
          return false;
      }
    }

    return true;
  }

  private log(message: string, data: unknown = {}, severity: 'log' | 'error' | 'warn' = 'log'): void {
    if (this.loggingEnabled) {

      if (data) {
        this.ngxLogger[severity](message, data);
      } else {
        this.ngxLogger[severity](message);
      }
    }
  }

  private getValueForDisplayAdDeviceMode(): 'mobile' | 'desktop' {
    return window.innerWidth <= this.MOBILE_BREAKPOINT ? 'mobile' : 'desktop'
  }

  private generateRandomString(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }
    return result;
  }


  public constructor(
    private ngxLogger: NGXLogger,
    private router: Router,
    private adBlockDetectionService: AdBlockerDetectionService,
    private consentService: ConsentService,
    private authenticationService: AuthenticationService,
    @Optional()
    @SkipSelf()
      otherInstance: SomtagService,
  ) {
    if (otherInstance) throw 'SomtagService should have only one instance.';

    this.displayAdDeviceMode = new BehaviorSubject<'mobile' | 'desktop'>(this.getValueForDisplayAdDeviceMode());

    this.ppidFallBack = this.generateRandomString(36)
    this.displayAdDeviceMode.pipe(
      distinctUntilChanged(),
      filter(() => !this.displayAdsAreInitializing),
      map(() => {
        this.displayAdsAreInitialized.next(false)
      }),
    ).subscribe(
      (v) => {
        this.log('Display Ad Device Mode change', v, 'log')
        this.displayAdShouldLoad.next()

      }
    )

    /**
     * Needed to load ads which have initially not been visible
     */
    fromEvent(window, 'resize').pipe(debounceTime(1000)).subscribe(() => {
      this.displayAdDeviceMode.next(this.getValueForDisplayAdDeviceMode())
      this.windowResize$.next();
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.displayAdsAreInitializing = false
      this.displayAdsAreInitialized.next(false)
    })

    // todo reset the counter with each router event
    interval(4 * 60 * 1000).subscribe(
      () => {
        this.reloadDisplayAdsWithoutInit()
      }
    )
  }
}
