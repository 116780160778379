import {Pipe, PipeTransform} from '@angular/core';
import {
  HomeGuestTeamResource,
  ProfileFromSearchIndexResource,
  ProfilePageResource,
  ProfileReducedResource
} from '../../entities/Profile.entity';
import {ImageCdnService} from '../../shared/services/image-cdn.service';
import {PROFILE_IMAGE_SIZE} from "../../../enums/PROFILE_IMAGE_SIZE";

@Pipe({
  name: 'profileImage',
})
export class ProfileImageSizePipe implements PipeTransform {
  public transform(
    value: ProfileReducedResource | HomeGuestTeamResource |  ProfileFromSearchIndexResource | ProfilePageResource | undefined,
    args: { size: PROFILE_IMAGE_SIZE; imageError?: string | undefined } = {
      size: PROFILE_IMAGE_SIZE.M_64_64,
      imageError: '/assets/placeholders/profil.svg',
    },
  ): string {

    if (!value) {
      return args.imageError ?? '/assets/placeholders/profil.svg';
    }

    let imageId: string | undefined;

    if ("image_url" in value) {
      imageId = value?.image_url as string | undefined;
    }
    if ("image_id" in value && !imageId) {
      imageId = value?.image_id as string | undefined;
    }

    if (!imageId || imageId.startsWith('http')) {
      return args.imageError ?? '/assets/placeholders/profil.svg';
    }

    return this.imageCdnService.getImageFqdn(imageId, args.size);
  }

  public constructor(private imageCdnService: ImageCdnService) {}
}
