import {Injectable} from '@angular/core';
import {ConsentService} from './consent.service';
import {distinctUntilChanged, filter, switchMap, take} from 'rxjs/operators';
import {document} from 'ngx-bootstrap/utils';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  public addHubspotScript(): void {
    this.hasConsent().subscribe(() => {
      const scriptId = 'hs-script-loader';
      if (document.getElementById(scriptId) !== null) {
        return;
      }

      const script: HTMLScriptElement = document.createElement('script');

      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.src = environment.hubspot.script_src;
      document.body.append(script);
    });
  }

  private hasConsent(): Observable<boolean> {
    return this.consentService.initialized$.pipe(
      distinctUntilChanged(),
      filter(initialized => initialized),
      switchMap(() => this.consentService.consentGiven(environment.consentIds.hubspot)),
      filter((consentGiven) => {
        return consentGiven;
      }),

      filter(hasConsent => hasConsent),
      take(1),
    );
  }

  public constructor(private consentService: ConsentService) {
  }
}
