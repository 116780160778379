import {ChangeDetectorRef, Injectable, Injector, Optional, SkipSelf} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {de, enUS} from "date-fns/locale";
import {DateFnsConfigurationService} from "ngx-date-fns";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {registerLocaleData} from "@angular/common";
import localeDE from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import {BehaviorSubject, distinctUntilChanged, Subject} from "rxjs";
import {LocalStorageService} from "ngx-webstorage";

export enum LOCALE {
  DE = "de",
  EN = "en"
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  public localeChanged = new Subject<LOCALE>();
  public currentLocale = this.translate.currentLang as LOCALE;

  public dfnsLocale: BehaviorSubject<Locale> = new BehaviorSubject(de)
  private initialized = false;
  private cdr?: ChangeDetectorRef;

  public initLocale(cdr: ChangeDetectorRef): void {
    if (this.initialized) return;

    this.cdr = cdr;
    this.setLocale(this.storageService.retrieve('locale') ?? LOCALE.DE)
    this.initialized = true;
  }


  public setLocale(localeId: LOCALE): void {

    if (!Object.values(LOCALE).includes(localeId as LOCALE)) {
      return;
    }
    this.storageService.store('locale', localeId);
  }


  public constructor(
    private translate: TranslateService,
    private dfnsConfig: DateFnsConfigurationService,
    private injector: Injector,
    private storageService: LocalStorageService,
    private readonly bsLocaleService: BsLocaleService,
    @Optional()
    @SkipSelf()
      otherInstance: LocaleService,
  ) {
    if (otherInstance) throw 'LocaleService should have only one instance.';

    registerLocaleData(localeDE, 'de');
    registerLocaleData(localeEn, 'en');
    this.storageService.observe('locale').pipe(distinctUntilChanged()).subscribe(
      (locale) => {
        this.translate.use(locale);
        this.bsLocaleService.use(locale === 'en' ? 'en-gb' : locale)
        if (locale == 'de') {
          this.dfnsConfig.setLocale(de)
          this.dfnsLocale.next(de)
        } else {
          this.dfnsConfig.setLocale(enUS)
          this.dfnsLocale.next(enUS)
        }

        this.currentLocale = locale;
        this.localeChanged.next(locale);
        this.cdr?.markForCheck()
      }
    )
    this.setLocale(this.storageService.retrieve('locale'))

  }
}
