import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SOM_DISPLAY_AD_SLOT_TYPES} from "../types/somtag";
import {VersionCheckService} from "./shared/services/version-check.service";
import {ConsentService} from "./shared/services/consent.service";
import localeEn from '@angular/common/locales/en'
import localeDe from '@angular/common/locales/de'
import {registerLocaleData} from "@angular/common";
import {defineLocale, deLocale, enGbLocale} from 'ngx-bootstrap/chronos'
import {CurrentlyLiveService} from "./shared/services/currently-live.service";
import {AuthenticationService} from "./shared/services/authentication.service";
import {DisplayAdComponent} from "./shared/components/display-ad/display-ad.component";
import {HubspotService} from "./shared/services/hubspot.service";
import {FireplaceAdService} from "./shared/services/fireplace-ad.service";
import {AnalyticsService} from "./shared/services/analytics.service";
import {EVENT_ACTION, EVENT_CATEGORY} from "../types/gtm-options";
import {ImaService} from "./shared/services/ima.service";
import {SomtagService} from "./shared/services/somtag.service";
import {TooManyDevicesService} from "./shared/services/too-many-devices.service";
import {LocaleService} from "./shared/services/locale.service";
import {NGXLogger} from "ngx-logger";
import {MicrosoftAdsService} from "./shared/services/microsoft.ads.service";


// ngx-bootstrap locale
defineLocale('de', deLocale)
defineLocale('en', enGbLocale)

// angular locale
registerLocaleData(localeEn, 'en')
registerLocaleData(localeDe, 'de')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  public has_sportdeutschland_plus?: boolean;

  @ViewChild('skyscraper') public skyscraper?: DisplayAdComponent;

  protected readonly SOM_DISPLAY_AD_SLOT_TYPES = SOM_DISPLAY_AD_SLOT_TYPES;

  public loadSkyscraper(): void {
    if (this.has_sportdeutschland_plus !== true) {
      this.skyscraper?.load();
    }
  }

  public showCMP(): void {
    this.consentService.showCMP();
  }

  public trackGothaerFireplaceAdImpression(): void {
    this.analyticsService.trackEvent(
      EVENT_CATEGORY.IMPRESSION,
      EVENT_ACTION.GOTHAER_FIREPLACE_CLICK_IMPRESSION,
      'Gothaer Fireplace Interaction on Home',
      1,
      true
    );
  }

  public constructor(
    translate: TranslateService,
    private consentService: ConsentService,
    private versionCheckService: VersionCheckService,
    private currentlyLiveService: CurrentlyLiveService,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    public readonly fireplaceAdService: FireplaceAdService,
    auth: AuthenticationService,
    hubspotService: HubspotService,
    imaService: ImaService,
    somtagService: SomtagService,
    injector: Injector,
    localeService: LocaleService,
    msAdsService: MicrosoftAdsService,
    private log: NGXLogger,

  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');

    localeService.initLocale(cdr)

    this.currentlyLiveService.init()
    this.versionCheckService.initVersionCheck()

    auth.profile$.subscribe(
      p => {
        if (p && p.has_sportdeutschland_plus) {
          this.has_sportdeutschland_plus = true;
        } else {
          this.has_sportdeutschland_plus = false;
        }

        this.cdr.markForCheck()
      }
    )
    hubspotService.addHubspotScript();
    imaService.addScript()
    somtagService.addScript()
    msAdsService.addMSAdsScript();

    const tooManyDevicesService = injector.get(TooManyDevicesService)
    this.log.info('[AppComponent] init too many devices service')
    tooManyDevicesService.initSessionCountCheckerInterval()
  }

}
