import {Injectable} from '@angular/core';
import {ChunkLoaderService} from "../../services/api/chunk-loader.service";
import {TagReducedResource} from "../../../entities/Tag.entity";


@Injectable({
  providedIn: 'root',
})
export class HeaderTagsService extends ChunkLoaderService<TagReducedResource> {
  public url = '/stateless/frontend/header-tags';
  // note this endpoint only allows 20 as chunkSize! update worker if you want to change this value
  public override chunkSize = 20;
}
