import {Component, Input} from '@angular/core';
import {LOCALE, LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html'
})
export class LanguageSwitcherComponent {
  public readonly object = Object;
  public readonly AVAILABLE_LOCALE = LOCALE;

  @Input('idAffix') public idAffix?: string;

  public availableLocale = LOCALE;
  public constructor(public localeService: LocaleService) {
  }


}
