import {Injectable} from '@angular/core';
import {BehaviorSubject, delayWhen, EMPTY, forkJoin, Observable, of, retry, Subject, timer} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {PaginatedItems} from '../../../entities/PaginatedItems.type';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {LoadMoreType} from "../../../entities/Lists/PageWithElements";

@Injectable()
export abstract class ChunkLoaderService<T> {
  public abstract url: string;
  public additionalQueryParams: Record<string, string> = {};
  public total$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  public lastPage$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  public items$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public country$: BehaviorSubject<string | undefined> = new BehaviorSubject<string| undefined>(undefined);
  public notFound$: Subject<void> = new Subject<void>();
  public withCredentials = false;
  protected allItems$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected chunkSize = 6;
  private debug = false;
  private isFetching = false;
  private nextPagePreload?: number;


  public setUrl(url: string): void {
    this.url = url;
    this.reset();
  }





  public enableDebug(): void {
    this.debug = true;
  }

  public reset(): void {
    this.total$.next(undefined);
    this.items$.next([]);
    this.allItems$.next([]);
    this.loading$.next(false);
    this.hasMore$.next(false);
  }


  public initializeForLoadMore(loadMore: LoadMoreType | null, items: T[]): void {

    if (loadMore) {
      this.chunkSize = items.length;
    }
    this.initialize(loadMore?.link ?? '', items, !!loadMore );
  }

  public initialize(url: string, items: T[] = [], hasMore: boolean | undefined = undefined): void {
    this.reset();

    this.setUrl(url);

    if (this.debug) {
      this.log.log('initialize with ', {items, hasMore});
    }
    this.allItems$.next(items);

    if (typeof hasMore === 'boolean') {
      this.hasMore$.next(hasMore);
    }

    if (hasMore === false) {
      this.total$.next(items.length);
    }
  }

  public load(amount: number): void {
    if (this.debug) {
      this.log.info('loading items:' + amount);
      this.log.info('total items:' + this.total$.getValue());
    }


    this.loading$.next(true);


    const total = this.total$.getValue();
    if (total != undefined && amount > total) {
      if (this.debug) {
        this.log.log('tried to load items - but there are none on the server', {
          requested: amount,
          total: total,
        });
      }

      this.emitItems(this.allItems$.getValue());
      return;
    }

    const currentlyLoaded = this.allItems$.getValue().length;
    const highestPageLoaded = Math.ceil(currentlyLoaded / this.chunkSize);
    const pageToLoad = highestPageLoaded + 1;

    if (currentlyLoaded >= amount) {
      this.emitItems(this.allItems$.getValue().slice(0, amount));
      return;
    }
    // items length must be too small

    if (pageToLoad * this.chunkSize < amount) {
      //this.log.error('Can not load so many items in one batch! You can only chunk load ' + this.chunkSize + ' items. requested ' + amount);

      //this.emitItems(this.allItems$.getValue().slice(0, amount));
      //return;
    }

    const lastPage = this.lastPage$.getValue()
    if (lastPage != undefined && pageToLoad > lastPage) {
      if (this.debug) {
        this.log.log('tried to load a page which is not available', {
          requested: amount,
          total: total,
        });
      }

      this.emitItems(this.allItems$.getValue());
      return;
    }
    // wir laden immer in vielfachen von this.chunkSize. Dadurch sind die Requests besser cachebar

    // Calculates the amount of needed pages via the amount of items needed divided by the current chunk size
    // We always round up because loading more items than needed is always better
    const pagesNeeded = Math.ceil((amount - currentlyLoaded) / this.chunkSize);

    const observables: Observable<PaginatedItems<T>>[] = [];

    for(let i = 1; i <= pagesNeeded; i++) {
      observables.push(
        this.loadPage(highestPageLoaded + i)
      )
    }

    forkJoin(observables).pipe(
      map(responses => {
        const [first] = responses
        this.total$.next(first.meta.total)
        this.lastPage$.next(first.meta.last_page);

        const currentItems = this.allItems$.getValue();
        const newItems: T[] = [];
        responses.forEach(res => {
          newItems.push(...res.data);
        })

        currentItems.push(...newItems);
        this.allItems$.next(currentItems)
      })
    ).subscribe({
      next: () => {
        this.emitItems(this.allItems$.getValue().slice(0, amount));

        clearTimeout(this.nextPagePreload);
        this.nextPagePreload = setTimeout(() => {
          const currentlyLoaded = this.allItems$.getValue().length;
          const currentHighestPageLoaded = Math.ceil(currentlyLoaded / this.chunkSize);

          if(this.lastPage$.value !== currentHighestPageLoaded) {
            this.loadPage(currentHighestPageLoaded + 1).pipe(
              take(1)
            ).subscribe({
              next: res => {
                this.total$.next(res.meta.total)
                this.lastPage$.next(res.meta.last_page);

                const currentItems = this.allItems$.getValue();
                currentItems.push(...res.data);
                this.allItems$.next(currentItems);
              }
            });
          }
        }, 6000) as unknown as number;
      },
      error: e => {
        if(this.debug) this.log.error(e);
        // error is not logged
        if (e.status === 404 && pageToLoad === 1) {
          this.notFound$.next();
        }
      }
    })
  }

  protected getAdditionalQueryParams(): Record<string, string> {
    return this.additionalQueryParams;
  }

  protected loadPage(page: number): Observable<PaginatedItems<T>> {
    if (this.debug) {
      this.log.log('Starting loadPage ', page)
    }

    if (this.isFetching) {
      return EMPTY; // Don't start a new request if one is already in progress
    }

    return of(page).pipe(
      tap((page) => {
        if (this.debug) {
          this.log.log('Starting loadPage', page)
        }
      }),
      map(() => this.isFetching),
      tap(isFetching => {
        if (this.debug) {
          this.log.log('isFetching status:', isFetching)
        }
      }),
      switchMap(isFetching => {
        if (isFetching) {
          throw new Error('Still fetching...'); // Throw an error if still fetching
        }
        return of(1); // Continue the pipeline if not fetching
      }),
      delayWhen(() => {
        return timer(300); // Introduce a delay of 100ms before retrying
      }),
      retry(Infinity), // Retry indefinitely
      tap(() => {
        this.isFetching = true; // Set the lock
      }),
      switchMap(() => this.http
        .get<PaginatedItems<T>>(this.url, {
          params: {
            page: page.toString(),
            per_page: '' + this.chunkSize,
            ...this.getAdditionalQueryParams(),
          },
          withCredentials: this.withCredentials,
          observe: 'response',
        })),
      tap((response: HttpResponse<PaginatedItems<T>>) => {
        if (response.headers.has('x-country')) {
          this.setCountry(response.headers.get('x-country') as string);
        }
        this.isFetching = false; // Release the lock on success
      }),
      map(response => response.body as PaginatedItems<T>),
      catchError(err => {
        throw err;
      })
    ) as Observable<PaginatedItems<T>>;
  }

  private setCountry(country: string): void {
    this.country$.next(country);
  }

  private emitItems(items: T[]): void {
    this.items$.next(items);
    const total = this.total$.getValue();
    if (total) {
      this.total$.next(total);
      this.hasMore$.next(total > this.allItems$.getValue().length);
    }
    this.loading$.next(false);

    if (!this.initialized$.getValue()) {
      this.initialized$.next(true);
    }
  }

  public constructor(protected http: HttpClient, protected log: NGXLogger) {
  }
}
