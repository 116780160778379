import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';

export class DelayedPreloadingStrategy implements PreloadingStrategy {
  public preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {


    return of(true).pipe(delay(1000), switchMap(() => load()));
  }
}
