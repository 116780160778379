import {Environment} from './environment-type';
import {MATOMO_SITE_ID} from '../types/gtm-options';

export const environment: Environment = {
  production: false,
  envName: 'stage',
  serviceworker: false,
  gtag: {
    measurement_id: 'G-YSYNNSM2FT',
    conversionTrackingId: 'AW-984392417',
  },
  facebook_pixel_id: "1403283143052059",
  consentIds: {
    google_ad_services: 'rJ99c4oOo-X',
    hubspot: 'ry0QcNodoWQ',
    matomo: 'zqWojrT0P',
    google_analytics: "HkocEodjb7",
    google_tag_manager: "BJ59EidsWQ",
    revive_ad_server: "Wq0D2klq",
    ima_sdk: 'S1Lgx59AX',
    wsc: '-017etFBy4mR4I',// for blaze sdk
  },
  tcfConsentIds: {
    som: 857,
    microsoft_ads: 1126
  },
  home: {
    top_playlist: '974de689-8833-40c9-8fa1-445395b0987e',
  },
  hubspot: {
    script_src: '//js-eu1.hs-scripts.com/26635161.js',
  },
  matomo: {
    siteId: MATOMO_SITE_ID.STAGE,
    cookieDomain: '*.teraone-preview.de',
    processEventsWithCloudflareQueue: false,
    domains: ['*.teraone-preview.de', '*.sdtv-prod.pages.dev', '*.localhost'],
  },
  api: {
    url: 'https://api.sdtv-stage.teraone-preview.de/api',
    cms_url: 'https://admin.sdtv-stage.teraone-preview.de',
    websocket: 'wss://api.sdtv-stage.teraone-preview.de/api/frontend/websocket',
    session_domain: '.sdtv-stage.teraone-preview.de',
    // url: 'https://api.sportdeutschland.tv/api',
    // url: 'https://sdtv-backend.test/api',
    handball_net_profile_id: '95109fd7-c8f2-44a9-aebe-9137da7be197',
    sdtv_plus_product_id: '99c1a006-91a1-48a1-b024-8ac462e1f761',
    streamer_product_ids: {
      pro_yearly: '95c36e0f-1363-4020-8d2a-8bb6457356a4',
      pro_monthly: '95c36ddf-172a-42dc-a210-d951f3218e89',
      classic_yearly: '95c36e67-629d-4a8a-9907-f672d0f1b789',
      classic_monthly: '95c36e3f-74e8-406d-9bf1-1125bd36ca62',
    },
  },
  image_cdn: {
    url: 'https://img-cdn.sdtv-stage.teraone-preview.de',
  },
  search: {
    //url: 'https://sdtv-search-staging.dosbnewmedia.de/api/v1',
    url: 'https://search.sdtv-stage.teraone-preview.de/api/v1',
  },
  ads: {
    useAds: true,
    somtag: {
      publisherId: 'sportdeutschlandtv', // demo_site,
      defaultChannels: [],
      displaySlots: {
        fullbanner2: { enabled: true },
        skyscraper1: { enabled: true },
        rectangle1: { enabled: true },
        mbanner1: { enabled: true },
        performanceFullbanner1: { enabled: true },
      },
      blocks: {
        pre: { enabled: true },
        post: { enabled: true },
        mid: { enabled: true },
        overlay: { enabled: false },
        pause: { enabled: false },
      },
      secondsBeforeFirstMidrollBlock: 600,
      secondsBetweenMidrollBlocks: 1020,
      numberOfVideosBeforePreRollAgainInPlaylist: 5, // 0 for just one preRoll
    },
    revive: {
      showFullscreenOverlayAfterWatchedSeconds: [20*60]
    }
  },
  player: {
    showShareButton: true,
    conferenceMode: true,
    useRevolverPlay: true,
    embedded: {
      showTitle: true,
    },
  },
  stripe: {
    key: 'pk_test_51Ho8btE2LSRVN7Y069GBjWq5bgLYmKLAxIWrnmi2KTPXok87IYZoic92MY59guAzlTiGF0OWpdnjefcLa9m2TbGf00kHjRUUI3',
  },
  paypal: {
    client_id: 'AcFub7tbvlICJUxrzNGO-PMMOnmr6UA-Cy1toD_1yQKSkdx29XFfDXA1FVjgePu6-1GqjlFezowg5gws',
  },
  min_password_length: 8,
  gothaer_fireplace_ad_enabled: new Date() < new Date('2024-08-25T23:00:00+02:00'),
};
